import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Empresa } from '../../cadastro/model/empresa.model';
import { Participante } from '../../cadastro/model/participante.model';
import { Item } from '../../cadastro/model/item.model';
import { Pedido } from '../../financeiro/model/pedido.model';
import { PedidoItem } from '../../financeiro/model/pedido-item.model';
import { PedidoParcela } from '../../financeiro/model/pedido-parcela.model';
import { PedidoTipo } from '../../financeiro/model/pedido-tipo.model';
import { TipoDesconto } from '../../util/model/tipo-desconto.model';
import { PedidoStatus } from '../../financeiro/model/pedido-status.model';
import { CrudService } from 'app/shared/services/crud.service';
import { HttpService } from 'app/shared/services/http.service';
import { SessionService } from 'app/shared/services/session.service';
import { DblinkedSessionService } from 'app/shared/services/dblinked-session.service';
import { Message, MessageService, SelectItem } from 'primeng/api';
import { ConfigService } from 'app/shared/services/config.service';
import { ToolbarService } from 'app/shared/services/toolbar.service';
import { UtilService } from 'app/shared/services/util.service';
import { FormaPagamento } from '../../util/model/forma-pagamento.model';
import { ModNf } from '../../util/model/mod-nf.model';
import { CstPiscofins } from '../../util/model/cst-piscofins.model';
import { CstIpi } from '../../util/model/cst-ipi.model';
import { EscrituracaoNfEntrada } from '../../fiscal/model/escrituracao-nf-entrada.model';
import { Moeda } from '../../util/model/moeda.model';
import { TipoIncoterm } from '../../util/model/tipo-incoterm.model';
import { OperacaoRegra } from '../../cadastro/model/operacao-regra.model';
import { EscrituracaoEntrada } from '../../contabil/model/escrituracao-entrada.model';
import { PlanoContas } from '../../contabil/model/plano-contas.model';
import { CadCcus } from '../../contabil/model/cad-ccus.model';
import { CadProj } from '../../contabil/model/cad-proj.model';
import { CodNaturezaConta } from '../../util/model/cod-natureza-conta.model';
import { LancamentoPartida } from '../../contabil/model/lancamento-partida.model';
import { CstIcmsTabelaA } from '../../util/model/cst-icms-tabela-a.model';
import { CstIcmsTabelaB } from '../../util/model/cst-icms-tabela-b.model';
import { NfeFin } from '../../financeiro/model/nfe-fin.model';
import { TitleService } from '../../shared/services/title.service';
import { ToolbarButton } from '../../shared/model/toolbar-button.model';
import { Util } from 'app/shared/common/util';
import { isNullOrUndefined } from 'util';
import { trigger, state, style, animate, transition } from '@angular/animations';
declare var $: any;
import { MAT_DIALOG_CONFIG, ParticipanteDialogComponent } from '../../cadastro/participante-dialog/participante-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { EmpresaConta } from 'app/cadastro/model/empresa-conta.model';
import { PedidoService } from 'app/shared/services/pedido.service';
import { PedidoItemRateio } from 'app/financeiro/model/pedido-item-rateio.model';
import { TipoBaixa } from 'app/util/model/tipo-baixa.model';
import { OperacaoRegraContabil } from 'app/cadastro/model/operacao-regra-contabil.model';
import { OperacaoRegraFiscal } from 'app/cadastro/model/operacao-regra-fiscal.model';
import { TipoRegraContabil } from 'app/util/model/tipo-regra-contabil.model';
import { EmpresaAlmoxarifado } from 'app/cadastro/model/empresa-almoxarifado.model';
import exportExcel from 'app/util/processing/exportExcel';
import { Toolbar } from 'app/shared/model/toolbar.model'
import { SkeletonModel } from 'app/shared/components/skeleton/models/skeletonModel'
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import deepCopy from 'app/util/processing/deepcopy';
import idv4 from 'app/util/processing/idv4';
import { PysocketService } from 'app/shared/services/socket/pysocket.service';
import { Service } from 'app/shared/services/socket/protocol/Service';
import { Action } from 'app/shared/services/socket/protocol/Action';
import { Method } from 'app/shared/services/socket/protocol/Method';
import LogManagement from 'app/shared/common/log-management/log-control';
import { LogAction, LogModule, LogScreen } from 'app/shared/common/log-management/log.types';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-notas-pendentes',
  templateUrl: './notas-pendentes.component.html',
  styleUrls: ['./notas-pendentes.component.scss'],
  providers: [CrudService],
  animations: [
    trigger('changeDivSize', [
      state('initial', style({

      })),
      state('final', style({
        opacity: '0',
        height: '0',
        padding: '0px 15px',

      })),
      transition('initial=>final', animate('200ms')),
      transition('final=>initial', animate('200ms'))
    ]),
  ]
})
export class NotasPendentesComponent implements OnInit, OnDestroy {

  currentStateNotasFiscais = 'initial';
  changeStateNotasFiscais() {
    this.currentStateNotasFiscais = this.currentStateNotasFiscais === 'initial' ? 'final' : 'initial';
  }
  currentStateInfoFiscais = 'initial';
  changeStateInfoFiscais() {
    this.currentStateInfoFiscais = this.currentStateInfoFiscais === 'initial' ? 'final' : 'initial';
  }

  private sessionSubscription: Subscription;
  private messagesSubscription: Subscription

  xmlPendente: Array<any> = new Array();
  listaPedidosPendentes: Array<any> = new Array();
  xmlEscriturado: Array<any> = new Array();
  xmlCancelado: Array<any> = new Array();
  xml: Array<any> = new Array();
  nf: any;
  nfEscriturada: any;
  nfGradeContabil: any;
  pedido: Pedido;
  itensXml: any;
  contadorPendente: number;
  contadorEscriturado: number;
  contadorCancelado: number;

  exibirNf: boolean;
  exibirPedidos: boolean;
  permiteAlterarRateio: boolean;
  existeLog: boolean;
  modalLog: boolean;
  pedidoValidado: boolean;


  modalPeriodo: boolean;
  validacaoError: boolean;
  dataIniSearch: any;
  dataFinSearch: any;
  dataIni: Date;
  dataFin: Date;

  opcoesPedido: SelectItem[];
  msgs: Message[];
  logAdvertencia: Message[] = null;

  escrituracaoFiscal: Array<any> = new Array();
  escrituracaoContabil: Array<any> = new Array();
  gradeFiscal: Array<any> = new Array();
  itensForn: Array<any> = new Array();
  gradeContabil: Array<any> = new Array();
  infosGerais: Array<any> = new Array();
  pedidoParcela = new PedidoParcela;
  pedidoItensRateioEdicao: Array<any> = new Array();

  nfSemPedido: boolean;
  title: String;
  subtitle: String;
  idNfesBaixa: Array<any> = new Array();
  idPedidosView: Array<any> = new Array();
  abaSelecionada: any;
  todasSelecionadas: boolean;
  valorTotalPendete: number;
  valorTotalEscriturado: number;
  valorAtual: number;
  valorFiltro: number;
  valor: number;

  visualizarEscrituracao: boolean;
  editarEscrituracao: boolean;
  editarEscrituracaoStatus: boolean;
  editarEscrituracaoContabil: boolean;
  editarLctoContabil: boolean;
  linhaEditada: any;
  linhaEditadaContabil: any;
  dtEntrada: Date;
  pedidoItemEditar = new PedidoItem();
  exibirLinha: boolean;
  pedidoId: number;

  opcoesItens: SelectItem[];
  opcoesCstIcms: SelectItem[];
  opcoesCstIpi: SelectItem[];
  opcoesCstPis: SelectItem[];
  opcoesCstCofins: SelectItem[];
  opcoesStatusPedido: SelectItem[];
  escrituracaoList: Array<EscrituracaoNfEntrada> = new Array();
  escrituracao = new EscrituracaoNfEntrada();
  itemEscrituracaoContabil = new EscrituracaoEntrada();
  itemEscrituracaoContabilList: Array<EscrituracaoNfEntrada> = new Array();
  nfeFinList: Array<NfeFin> = new Array();
  opcoesOperacao: SelectItem[];
  opcoesModBcIcms: SelectItem[];
  contaSelecionada: String;
  vinculadoOpt: SelectItem[] = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ]

  vlBcIcms: any;
  vlIcms: any;
  vlBcIcmsSt: any;
  vlIcmsSt: any;
  vlImpostoImportacao: any;
  vlIcmsUfRemetente: any;
  vlFcp: any;
  vlPis: any;
  vlProd: any;
  vlFrete: any;
  vlSeguro: any;
  vlDesc: any;
  vlOutrasDesp: any;
  vlIpi: any;
  vlIcmsUfDestino: any;
  vlTributos: any;
  vlCofins: any;
  vlTotal: any;
  opcoesPlanoContas: SelectItem[];
  opcoesCentroCusto: SelectItem[];
  opcoesCadProj: SelectItem[];
  opcoesDebitoCredito: SelectItem[];

  exibirDetalhes: boolean;

  partidaLancamentoEditar = new LancamentoPartida();
  opcoesFormaPagamento: SelectItem[];
  opcoesContas: SelectItem[];
  valorTotalParcelas: any;
  valorTotalFormasPagtoTela: any;

  vencimentoParcela: Date;
  editarVencimentoParcela: boolean;
  parcelaId: number;
  tab: string;


  aba1: boolean = true;
  aba2: boolean = false;
  aba3: boolean = false;
  aba4: boolean = false;
  aba5: boolean = false;
  /*Variaveis Card*/
  statusPendente: boolean = true;
  statusEscriturada: boolean = false;
  statusCancelada: boolean = false;

  escritNf: boolean = false;
  infoParticipante: any;
  opcoesModelo: SelectItem[];
  opcoesTermo: SelectItem[];
  somaRateio: number;
  visualizacaoFiscal: any;
  subTotalPedido: any;
  valorDescontoItens: any;
  valorImpostosRetidos: any;
  valorTotalPedido: any;
  opcoesTipoPagamento: SelectItem[];
  primeroVencimento = new Date();
  // valorTotalParcelas: any;
  calculaParcelasPgto: boolean;
  pedidoItemRateio: PedidoItemRateio;
  qtdParcelas: number;
  modalFormaPgto: boolean;
  // pedidoParcela = new PedidoParcela;
  msgsModal: Message[] = [];
  // valorTotalFormasPagtoTela: any;
  valorTotalFormasPagto: any;
  adtosRelacionadosPedido: Array<any> = new Array();
  pedidoExterior: boolean;
  pedidoItem: PedidoItem;
  exibirRateio: boolean;
  modalCentroCusto: boolean;
  rateioCentroCusto: number;
  centroCustoId: number;
  opcoesProjeto: SelectItem[];
  botaoEditar: boolean;
  pedidoVinculado: boolean;
  exibirItemPedido: boolean;
  contador: number;
  contadorRateio: number;
  operacaoRegraId: number;
  defaultDate: Date;
  adtosCompensar: Array<any> = new Array();
  idItemFaturamento: number;
  escriturarPedidoSalvo: boolean;
  maxParcelas: number;
  somaParcelas: number;
  valorSaldo: number;
  pedidoSalvo: boolean;
  isModeloNFS: boolean;
  idPedidosServico: Array<any> = new Array();
  idNotasEscrituradas: Array<any> = new Array();
  tiposBaixas: SelectItem[];
  dadosAdicionais: string;
  telaNotaEscriturada: boolean;
  temXmlVinculado: boolean;

  xmlSelecionados: Array<any> = new Array()
  vincularItens: boolean = false
  itensNfsParticipantes: Array<any> = new Array()
  httpOptions: Object = new Object()
  itensXmlSelecionados: Array<any> = new Array()
  opcoesOperacaoXml: SelectItem[] = []
  opcoesItensXml: SelectItem[] = []
  modalPagRateio: boolean = false
  relacaoItemOperNfe: any = {}
  informacoesPagamento: any = {}
  rateioObrigatorio: boolean = false
  informacoesRateio: Array<any> = new Array()
  aplicarRateioTodosItens: boolean = false
  informacoesAdicionaisXml: any = {}

  isReadOnly: boolean = false;
  codigoMoeda: any;

  msgCancelamentoNfe: String;
  modalCancelarNfe: boolean;
  modalCartaCorrecao: boolean;
  msgCartaCorrecaoNfe: String;

  //filter variables
  //table pending
  public filterValueData: string;
  public filterValueNome: string;
  public filterValueCnpj: string;
  public filterValueNumeroDoc: string;
  public filterValueModelo: string;
  public filterValueValor: string;
  public filterValueStatus: string;

  //table carrying
  public filterValueEmissao: string;
  public filterValueEntrada: string;
  public filterValueNome2: string;
  public filterValueCnpj2: string;
  public filterValueCodigoControle: string;
  public filterValuePedido: string;
  public filterValueNumeroNf: string;
  public filterValueModelo2: string;
  public filterValueValor2: string;
  public filterValueStatus2: string;

  //table canceled
  public filterValueData3: string;
  public filterValueNome3: string;
  public filterValueCnpj3: string;
  public filterValueNumeroNf3: string;
  public filterValueValor3: string;
  public filterValueStatus3: string;

  public termoPesquisa: string;
  public buscaNfe: string;

  parcelasExatas: boolean = false
  permiteAlterarConta: boolean = true
  pagamentoComissao: number = 0;
  valorComissao: number = 0;

  colPendentes = ['emissao', 'emitente', 'cnpj', 'numNf', 'modelo', 'valor', 'status']
  colEscrituradas = ['emissao', 'entrada', 'emitente', 'cnpj', 'codigoControle', 'codigoPedido', 'numNf', 'modelo', 'valor', 'status']
  colCanceladas = ['emissao', 'emitente', 'cnpj', 'numNf', 'valor', 'status']

  toolbarXmlIni = [
    { key: "save", icon: 'save', text: 'Salvar', tooltip: 'Salvar vinculação', visible: true, disabled: false, color: 'green', onClick: () => this.salvarVinculacaoXml() },
  ]

  toolbarIni = [
    { key: "detalhes", icon: 'loupe', text: 'Detalhes', tooltip: 'Detalhes', visible: false, disabled: false, color: 'green', onClick: () => this.verifCriacaoPedidoLote() },
    { key: "vincular", icon: 'link', text: 'Vincular itens', tooltip: 'Vincular itens e operações', visible: false, disabled: false, color: 'default', onClick: () => this.vincularItensXml() },
    { icon: 'upload_file', text: 'Exportar', tooltip: 'Exportar', visible: true, disabled: false, color: 'default', onClick: () => this.exportarListagem() },
    // { key: "criar", icon: 'add', text: 'Escriturar NF-e', tooltip: 'Escriturar NF-e', visible: false, disabled: false, color: 'green', onClick: () => this.criarPedidoLote() }
  ]

  public toolbarMain = new Toolbar()
  public toolbarXml = new Toolbar()

  skeletonConfigItens = new SkeletonModel({
    columns: [
      { name: 'ITEM', size: '20%', textAlign: 'left' },
      { name: 'ITEM RELACIONADO', size: '20%', textAlign: 'left' },
      { name: 'OPERAÇÃO', size: '20%', textAlign: 'left' },
      { name: 'VL UNIT', size: '10%', textAlign: 'right' },
      { name: 'QUANTIDADE', size: '5%', textAlign: 'center' },
      { name: 'VL TOTAL', size: '10%', textAlign: 'right' },
      { name: 'RELACIONADO', size: '5%', textAlign: 'center' }
    ],
    hasFilter: true,
    hasSort: true,
    hasSelection: true
  })

  loadingProgress: number = 0
  isLoadingInProgress: boolean = false
  tokenProgressoSocket: string = null

  private logManagement: LogManagement = new LogManagement(this.httpClient, this.dblinkedSessionService, this.configService, this.sessionService)

  modalXml: boolean  = false;
  chavesBuscar: string = '';

  constructor(private configService: ConfigService,
    private httpService: HttpService,
    private titleService: TitleService,
    private sessionService: SessionService,
    private dblinkedSessionService: DblinkedSessionService,
    private utilService: UtilService,
    private toolbarService: ToolbarService,
    private matDialog: MatDialog,
    private pedidoService: PedidoService,
    private messageService: MessageService,
    private httpClient: HttpClient,
    private socketService: PysocketService) { }

  ngOnInit() {
    this.title = 'ESCRITURAÇÃO DOCUMENTO DE ENTRADA';
    this.subtitle = 'ESCRITURAÇÃO DOCUMENTO DE ENTRADA';
    this.titleService.title = 'Escrituração de documento';
    this.toolbarService.clear();
    this.dblinkedSessionService.showPeriodsWithNextMonths(true, true, 24, 1, 2);
    this.defaultDate = new Date();
    this.toolbarRefresh();
    this.toolbarMain.setAll(this.toolbarIni)
    this.toolbarXml.setAll(this.toolbarXmlIni)
    this.termoPesquisa = 'chave';
    this.buscaNfe = '';
    this.modalXml = false;
    this.chavesBuscar = '';

    this.dataIniSearch = null;
    this.dataFinSearch = null;
    this.dataIni = null;
    this.dataFin = null;
    this.modalPeriodo = false;

    this.messagesSubscription = this.socketService.socketData.subscribe((response) => {
      if (response.service === Service.NOTIFICATION && response.action === Action.LOADING && response.method === Method.GET) {
        this.loadingManagement(response)
      }
    })

    this.sessionSubscription = this.sessionService.initSubscribe(this.dblinkedSessionService.sessionChanged,
      () => { this.inicializar(); });



    /*HIDE SIDEBAR FULLSCREEN (DO NOT REMOVE)*/
    $(document).ready(() => {
      $(".btn-full").click(
        function () {
          $(".mat-drawer-side").toggleClass('d-none');
        }
      );
    });

  }

  ngOnDestroy() {

    this.sessionService.destroySubscribe(this.sessionSubscription);
  }

  empresaChanged() {

    if (this.dblinkedSessionService.hasEmpresaSelected &&
      this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
      && this.dblinkedSessionService.periodo.year) {


      // this.alterarPeriodo();

      this.dataIni = null;
      this.dataFin = null;
      this.modalPeriodo = false;

      this.opcoesDebitoCredito = [];
      this.opcoesDebitoCredito.push({ label: 'D/C', value: null });
      this.opcoesDebitoCredito.push({ label: 'D', value: 'D' });
      this.opcoesDebitoCredito.push({ label: 'C', value: 'C' });

      this.xmlPendente = new Array();
      this.xmlEscriturado = new Array();
      this.xmlCancelado = new Array();
      this.idPedidosView = new Array();
      this.exibirNf = false;
      this.nfSemPedido = false;
      this.todasSelecionadas = false;
      this.valorTotalPendete = 0.00;
      this.valorTotalEscriturado = 0.00;
      this.valorAtual = 0.00;
      this.pedidoVinculado = false;
      this.pedidoSalvo = false;
      this.xmlSelecionados = new Array()

      this.situacaoTributariaIcms();

      this.situacaoTributariaIpi();

      this.situacaoTributariaCofins();

      if (!this.validacaoError) {
        let payload = {
          empresaId: this.dblinkedSessionService.empresa.id,
          dataInicial: this.dataIniSearch,
          dataFinal: this.dataFinSearch,
          filiais: this.dblinkedSessionService.filiaisSelecionadas
        }
        this.httpService.wait()
        this.httpService.post('/custom/dbl/buscar-xml', payload)
          .subscribe(ref => this.loadXml(ref),
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done())
      }
    }
  }

  loadXml(value: any) {
    this.xmlPendente = new Array();
    this.xmlEscriturado = new Array();
    this.xmlCancelado = new Array();
    this.valorTotalPendete = 0.00;
    this.valorTotalEscriturado = 0.00;
    this.valorAtual = 0.00;
    this.valor = 0.00;
    this.toolbarRefresh();
    this.contadorPendente = 0;
    this.contadorEscriturado = 0;
    this.contadorCancelado = 0;
    value.forEach(element => {
      if (element.status === 'Pendente') {
        this.contadorPendente = this.contadorPendente + 1;
        this.xmlPendente.push(element);
        this.valor = parseFloat((element.valor).replace('.', '').replace(',', '.'));
        this.valorTotalPendete = this.valorTotalPendete + this.valor;
      } else if (element.status === 'Escriturada' || element.status === 'Uso autorizado') {
        this.contadorEscriturado = this.contadorEscriturado + 1;
        this.xmlEscriturado.push(element);
        this.valor = parseFloat((element.valor).replace('.', '').replace(',', '.'));
        this.valorTotalEscriturado = this.valorTotalEscriturado + this.valor;
      } else {
        this.contadorCancelado = this.contadorCancelado + 1;
        this.xmlCancelado.push(element);
      }
    });
    this.valorAtual = this.valorTotalPendete;

    //Aqui vamos verificar se a vinculação já foi feita nessa sessão
    this.xmlPendente.forEach(xml => {
      if (!xml.vinculado && this.relacaoItemOperNfe.hasOwnProperty(xml.nfeId.toString())) xml.vinculado = true
      if (this.informacoesAdicionaisXml.hasOwnProperty(xml.nfeId.toString())) {
        xml.informacoesAdicionais = deepCopy(this.informacoesAdicionaisXml[xml.nfeId.toString()])
        xml.pronto = true
      }
    })

    //Aqui vamos verificar se a NF está pronta para escrituração (forma page rateio para todos os itens)
    // this.verificarXmlPronto()

  }

  loadPedido(value: Array<Pedido>, event: any) {
    this.opcoesPedido = [];
    this.opcoesPedido.push({ label: 'Nenhum', value: null });
    value.forEach(element => {
      if (element.participante.cnpj === Util.removeCaracteresCnpj(event) || element.participante.cpf === event) {
        this.opcoesPedido.push({ label: element.codigo, value: element.id });
      }
    });
  }


  carregarPedido(event: any) {
    if (confirm('Deseja realmente vincular este pedido?')) {
      if (!this.escritNf) {

        this.exibirLinha = false;
        this.exibirPedidos = false;
        this.exibirNf = true;

        if (event.pedidoId) {
          this.existeLog = false;


          this.logAdvertencia = new Array();
          if (this.pedido) {
            this.pedidoId = event.pedidoId
            this.httpService.wait();
            this.httpService.get('/pedido?$select=id,codigo,pedidoTipo,participante/nome,participante/cnpj,participante/fantasia,'
              + 'participante/id,empresaConta/id,pedidoStatus,pedidoStatus/description,pedidoStatus/id,formaPagamento/id,faturaAutomatico,dtFaturamentoAutomatico,'
              + 'metodoEnvio,dadosAdicionais,dtEntrega,dtFinalContrato,contrato,operacaoRegra/id,modNf/id,modNf,serie,numNf,dtEmissao,'
              + 'codigoOrcamento,valorFrete,valorSeguro,valorDesconto,valorOutrasDespesas,valorImpostoImportacao,valorIpi,valorIcmsSt,valorTotal,'
              + 'codigoControle,enderecoCobranca,enderecoEntrega,valorInss,valorIr,valorCsll,valorPis,valorCofins,valorIss,valorOutrasRetencoes,'
              + 'participante/nome,valorBaseCalculoIcms,valorIcms,valorIcmsSubst,valorIcmsUfRemetente,valorFcp,valorIcmsUfDestino,'
              + 'valorTotalTributos,txMoeda,moeda,tipoIncoterm,tipoIncoterm/id,tipoIncoterm/codigo,consumidorFinal,presencial/id,valorIssRetido,'
              + 'finalidade/id,transportador/id,ufPlaca/id,tipoFrete/id,codigoAntt,placaVeiculo,qVol,especieVol,marcaVol,numeracaoVol,pesoL,pesoB'
              + ',tipoPagamento&$filter=id eq ' + event.pedidoId)
              .subscribe(ref => this.loadPedidoDetalhe(ref.value),
                error => this.httpService.handleError(error, () => this.carregarPedido(event)),
                () => this.httpService.done());

          }
        } else {
          this.escrituracaoFiscal = new Array();
        }
        this.editarVencimentoParcela = false;
      } else {

        this.pedidoVinculado = true;
        this.toolbarEscriturarNfs();


        this.loadItemPedidoEscrit(event.pedidoId);
      }
    }
  }

  loadPedidoDetalhe(value: Pedido) {

    this.pedido = new Pedido();
    this.pedido.operacaoRegra = new OperacaoRegra();
    this.pedido.pedidoAprovacao = new Array();
    this.pedido.pedidoTipo = new PedidoTipo();
    this.pedido.pedidoItem = new Array();
    this.pedido.modNf = new ModNf();
    this.pedido.tipoIncoterm = new TipoIncoterm();
    this.pedido.moeda = new Moeda();
    this.pedido.pedidoStatus = new PedidoStatus();
    this.pedido.pedidoStatus.id = 12;

    this.pedido.dtEmissao = new Date(value[0].dtEmissao);


    this.opcoesContas.forEach(conta => {
      if (conta.value == value[0].empresaConta?.id) {
        this.contaSelecionada = conta.label;
      }
    });
    this.pedido = value[0];

    this.pedido.valorIss = value[0].valorIss + value[0].valorIssRetido;

    if (this.pedido.modNf.id !== 22) {
      this.tab = 'PED';
      this.dtEntrada = this.utilService.dateWithoutTimeZone(value[0].dtFaturamentoAutomatico);
    } else {
      this.tab = 'XML';
    }

    this.httpService.wait();
    this.httpService.get('/pedidostatus?$select=id,description'
      + '&$orderby=id')
      .subscribe(ref => this.loadPedidoStatus(ref.value),
        error => this.httpService.handleError(error, () => this.loadPedidoDetalhe(value)),
        () => this.httpService.done());

    this.httpService.wait();
    this.httpService.get('/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraFiscal/id'
      + '&$filter=tipoEntradaSaida/id eq 1 and empresa/id eq '
      + this.dblinkedSessionService.empresa.id)
      .subscribe(ref => this.loadOperacao(ref.value),
        error => this.httpService.handleError(error, () => this.loadPedidoDetalhe(value)),
        () => this.httpService.done());



    this.httpService.wait();
    this.httpService.get('/pedidoitem?$select=id,item/id,item/codigo,item/descricao,quantidade,valorUnit,'
      + 'tipoDesconto/id,tipoDesconto/cod,valorDesconto,valorTotal,'
      + 'operacaoRegra/id,operacaoRegra/cod,operacaoRegra/descricao,cfop,itemFornecedor,cstIcmsTabelaB/id,cstIcmsTabelaA/id,'
      + 'cest,ncm,valorFrete,valorSeguro,valorOutro,cstIpi/id,classeIpi,codigoIpi,valorBcIpi,aliquotaIpi,valorIpi,valorTotalProduto'
      + ',modBcIcms,percRedBcIcms,valorBcIcms,aliquotaIcms,valorIcms,valorBcIcmsSt,aliquotaIcmsSt,valorIcmsSt'
      + ',cstPis/id,valorBcPis,vlrAliquotaPis,perAliquotaPis,qtdVendaPis,valorPis,cstCofins/id,valorBcCofins,vlrAliquotaCofins'
      + ',perAliquotaCofins,qtdVendaCofins,valorCofins,valorBcImport,valorDespImport,valorIofImport,valorImpostoImport,dadosAdicionais'
      + '&$filter=pedido/id eq ' + this.pedido.id + '&$orderby=id')
      .subscribe(ref => this.loadItemPedido(ref.value),
        error => this.httpService.handleError(error, () => this.loadPedidoDetalhe(value)),
        () => this.httpService.done());

    console.log(value);

    if (!this.nf.modelo.toString().startsWith('NFS')) {

      if (!this.visualizarEscrituracao) {
        this.httpService.wait();
        this.httpService.get('/pedidoparcela?$select=id,parcela,valor,dtVencimento,empresaConta/id,empresaConta/nome,tipoBaixa/id,tipoBaixa/descricao'
          + '&$filter=pedido/id eq ' + this.pedido.id + '&$orderby=parcela')
          .subscribe(ref => this.loadParcelaPedido(ref.value),
            error => this.httpService.handleError(error, () => this.loadPedidoDetalhe(value)),
            () => this.httpService.done());
      } else {
        this.httpService.wait();
        this.httpService.get('/nfefin?$select=id,parcela,valor,dtVencimento,empresaConta/id,empresaConta/nome'
          + '&$filter=nfe/id eq ' + this.nf.nfeId + '&$orderby=parcela')
          .subscribe(ref => this.loadParcelaPedido(ref.value),
            error => this.httpService.handleError(error, () => this.loadPedidoDetalhe(value)),
            () => this.httpService.done());
      }

    } else {
      this.httpService.wait();
      this.httpService.get('/nfefin?$select=id,parcela,valor,dtVencimento,empresaConta/id,empresaConta/nome'
        + '&$filter=nfe/id eq ' + this.nf.nfeId + '&$orderby=parcela')
        .subscribe(ref => this.loadParcelaPedido(ref.value),
          error => this.httpService.handleError(error, () => this.loadPedidoDetalhe(value)),
          () => this.httpService.done());
    }


  }

  loadItemPedido(value: Array<PedidoItem>) {

    this.pedido.pedidoItem = new Array();

    this.valorTotalPedido = 0.00;
    this.vlDesc = this.utilService.parseMoneyString(0.00);

    value.forEach(element => {
      let pedidoItem = new PedidoItem();
      pedidoItem.item = new Item();
      pedidoItem.cstIpi = new CstIpi();
      pedidoItem = element;
      this.valorTotalPedido = this.valorTotalPedido + element.valorTotal;
      this.pedido.pedidoItem.push(pedidoItem);
    });

    if (this.nf.valor > this.valorTotalPedido) {
      this.toolbarLog();
      this.existeLog = true;

      this.logAdvertencia.push({
        severity: 'warn', key: 'messageLinnks', summary: 'Advertência:', detail: 'O Valor Total da NF ' +
          this.utilService.parseDecimal(this.nf.valor) + ' não bate com o Valor do Pedido de Compra ' +
          this.utilService.parseDecimal(this.valorTotalPedido)
      });
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Existem inconsistências entre o Pedido de Compra'
          + ' e a NF, verifique o log'
      });



    } else {


      if (!this.visualizarEscrituracao) {

        this.toolbarEscriturar();

        this.httpService.wait();
        this.httpService.get('/custom/pedido/validacao-pedido-nf(' + this.nf.nfeId + ',' + this.pedido.id + ',' + this.dblinkedSessionService.empresa.id + ')')
          .subscribe(ref => { this.resultadoValidacao(ref); },
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());
      } else {
        this.atualizarSubtotal();
        this.toolbarVisualizaEscrituracao();
      }
    }
  }

  loadEscrituracao(value: Array<EscrituracaoNfEntrada>) {

    this.escrituracaoList = new Array();

    value.forEach(element => {
    });

  }

  resultadoValidacao(event: any) {

    this.existeLog = false;

    event.forEach(element => {
      if (element.error) {
        this.toolbarLog();
        this.logAdvertencia.push({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência:', detail: element.message });
        this.existeLog = true;
        this.pedidoValidado = false;
      } else {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso:', detail: element.message });
        this.pedidoValidado = true;
        this.toolbarEscriturar();
        this.temXmlVinculado = true;

        let booleanParcial = false;
        if (this.vlTotal < this.pedido.valorTotal) {
          booleanParcial = true;
        }

        this.httpService.wait();
        this.httpService.get('/custom/pedido/validacao-cfop(' + this.nf.nfeId + ',' + this.pedido.id + ',' + booleanParcial + ')')
          .subscribe(ref => this.resultadoValidacaoCfop(ref, 'select'),
            error => this.httpService.handleError(error, () => this.empresaChanged()),
            () => this.httpService.done());

      }
    });

    if (!this.visualizarEscrituracao) {
      if (this.logAdvertencia.length > 0) {
        this.toolbarLog();
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: 'Existem inconsistências entre o Pedido de Compra'
            + ' e a NF, verifique o log'
        });
      }
    }
  }

  resultadoValidacaoCfop(event: any, status: string) {

    this.escrituracaoContabil = new Array();
    this.escrituracaoFiscal = new Array();
    this.infosGerais = new Array();

    this.vlBcIcms = 0.00;
    this.vlIcms = 0.00;
    this.vlBcIcmsSt = 0.00;
    this.vlIcmsSt = 0.00;
    this.vlImpostoImportacao = 0.00;
    this.vlIcmsUfRemetente = 0.00;
    this.vlIcmsUfDestino = 0.00;
    this.vlTributos = 0.00;
    this.vlFcp = 0.00;
    this.vlProd = 0.00;
    this.vlFrete = 0.00;
    this.vlSeguro = 0.00;
    this.vlDesc = 0.00;
    this.vlOutrasDesp = 0.00;
    this.vlIpi = 0.00;
    this.vlPis = 0.00;
    this.vlCofins = 0.00;
    this.vlTotal = 0.00;

    if (!this.visualizarEscrituracao) {
      event.forEach(element => {
        if (element.tipo === 'fiscal') {
          this.escrituracaoFiscal.push(element);
        } else if (element.tipo === 'cont') {
          this.escrituracaoContabil.push(element);
        } else if (element.tipo === 'infos') {
          this.infosGerais.push(element);
        }
      });
      setTimeout(() => {
        this.atualizarSubtotal();
      }, 1000);
    } else {

      if (event.length > 0) {

        if (status != 'update') {
          this.pedido.id = event[0].pedidoId;
        }


        this.httpService.wait();
        this.httpService.get('/pedido?$select=id,codigo,pedidoTipo,pedidoTipo/id,participante/nome,participante/cnpj,'
          + 'participante/fantasia,participante/id,empresaConta/id,pedidoStatus,pedidoStatus/id,formaPagamento/id,faturaAutomatico,'
          + 'dtFaturamentoAutomatico,metodoEnvio,dadosAdicionais,dtEntrega,dtFinalContrato,contrato,operacaoRegra/id,modNf/id,modNf,'
          + 'serie,numNf,dtEmissao,codigoOrcamento,valorFrete,valorSeguro,valorDesconto,valorOutrasDespesas,valorImpostoImportacao,'
          + 'valorIpi,valorIcmsSt,valorTotal,codigoControle,enderecoCobranca,enderecoEntrega,valorInss,valorIr,valorCsll,valorPis,'
          + 'valorCofins,valorIss,valorOutrasRetencoes,participante/nome,valorBaseCalculoIcms,valorIcms,valorIcmsSubst,'
          + 'valorIcmsUfRemetente,valorFcp,valorIcmsUfDestino,valorTotalTributos,txMoeda,moeda,tipoIncoterm,tipoIncoterm/id,'
          + 'tipoIncoterm/codigo,consumidorFinal,presencial/id,valorIssRetido,finalidade/id,transportador/id,ufPlaca/id,tipoFrete/id,'
          + 'codigoAntt,placaVeiculo,qVol,especieVol,marcaVol,numeracaoVol,pesoL,pesoB&$filter=id eq ' + this.pedido.id)
          .subscribe(ref => this.loadPedidoDetalhe(ref.value),
            error => this.httpService.handleError(error, () => this.onRowSelectEdicao(event)),
            () => this.httpService.done());

        event.forEach(element => {
          this.tab = element.tab;
          this.escrituracaoFiscal.push(element);
          this.infosGerais.push(element);
        });

        this.httpService.wait();
        this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + this.nf.nfeId + ',' +
          this.dblinkedSessionService.empresa.id + ')')
          .subscribe(ref => {
            ref.forEach(element => {
              this.escrituracaoContabil.push(element);
            });
          },
            error => this.httpService.handleError(error, () => this.resultadoValidacaoCfop(event, 'select')),
            () => this.httpService.done());
      }

    }

    if (!this.visualizarEscrituracao) {
      this.pagamentoForma(true);
    }

  }

  hideMessage() {

  }


  abreModalLog() {

    this.modalLog = true;

  }

  loadParcelaPedido(value: any) {
    this.pedido.pedidoParcela = new Array<PedidoParcela>();

    value.forEach(element => {

      const pedParc = new PedidoParcela();
      pedParc.parcela = element.parcela;
      pedParc.id = element.id;
      pedParc.dtVencimento = this.utilService.dateWithoutTimeZone(element.dtVencimento);
      pedParc.valor = this.utilService.parseDecimal(element.valor);
      pedParc.empresaConta = new EmpresaConta();
      if (element.empresaConta) {
        pedParc.empresaConta.id = element.empresaConta.id;
        pedParc.empresaConta.nome = element.empresaConta.nome;
      }
      if (element.tipoBaixa) {
        pedParc.tipoBaixa = new TipoBaixa();
        pedParc.tipoBaixa.id = element.tipoBaixa.id;
        pedParc.tipoBaixa.descricao = element.tipoBaixa.descricao;
      }



      this.pedido.pedidoParcela.push(pedParc);


    });



  }

  loadNfeFin(value: any) {

    this.pedido.pedidoParcela = new Array<PedidoParcela>();
    value.forEach(element => {
      const pedParc = new PedidoParcela();
      pedParc.parcela = element.parcela;
      pedParc.id = element.id;
      pedParc.dtVencimento = this.utilService.timestampToDate(element.dtVencimento);
      pedParc.valor = this.utilService.parseDecimal(element.valor);

      pedParc.empresaConta = new EmpresaConta();
      pedParc.empresaConta.id = element.empresaConta.id;
      pedParc.empresaConta.nome = element.empresaConta.nome;

      if (element.tipoBaixa) {
        pedParc.tipoBaixa = new TipoBaixa();
        pedParc.tipoBaixa.id = element.tipoBaixa.id;
        pedParc.tipoBaixa.descricao = element.tipoBaixa.descricao;
      }


      this.pedido.pedidoParcela.push(pedParc);


    });

  }

  visualizarPedidos(event: any) {

    this.nf = event;

    if (event.pedido) {
      this.escriturarPedidoSalvo = true;
      this.permiteAlterarRateio = true;
      this.httpService.wait();
      this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
        event.partId + ',' +
        (4) + ')')
        .subscribe(info => {
          this.toolbarEscriturarNfs();
          /*if (this.tipo == 0) {
           this.buscarAprovadores();
         }*/

          this.infoParticipante = info;
          this.escritNf = true;
          this.pedidoSalvo = true;
          this.loadItemPedidoEscrit(event.pedidoStatusId);


        },
          error => this.httpService.handleError(error, () => this.novoPedido()),
          () => this.httpService.done());



    } else {
      if (this.nf.status.toString() === 'Pendente') {
        this.exibirPedidos = true;
        this.exibirNf = false;

        this.listaPedidosPendentes = new Array();

        this.httpService.wait();
        this.httpService.get('/custom/pedido/buscar-pedido-pendente-nfe-id(' + event.nfeId + ','
          + this.dblinkedSessionService.empresa.id + ')')
          .subscribe(result => {
            result.forEach(element => {
              this.listaPedidosPendentes.push(element);

            });

          }, error => this.httpService.handleError(error, () => this.visualizarPedidos(event)),
            () => this.httpService.done());
      }
    }

  }

  escriturarNota(event: any) {
    this.telaNotaEscriturada = true;

    this.nf = event;
    this.verificaModelo();

    console.log(event);

    if (event.status.toString() === 'Pendente') {

      this.exibirNf = true;

      if (this.nf) {
        this.toolbarEscriturar();
        this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
      } else {
        this.toolbarRefresh();
      }
    } else {
      this.nf = event;
      this.dtEntrada = event.entrada;
      this.verificarDetalhe();
      this.nfEscriturada = '';
      this.nfGradeContabil = '';
      this.exibirNf = true;

      this.visualizarEscrituracao = true;

      this.verificaModelo();
      this.toolbarVisualizaEscrituracao();
      this.comboModBcIcms();


      this.httpService.wait();
      this.httpService.get('/nfefin?$select=parcela,dtVencimento,valor,empresaConta/id,empresaConta/nome'
        + '&$filter=nfe/id eq ' + event.nfeId + '&$orderby=parcela')
        .subscribe(ref => this.loadNfeFin(ref.value),
          error => this.httpService.handleError(error, () => this.escriturarNota(event)),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/notaFiscal/visualizar-escrituracao(' + event.nfeId + ',' +
        this.dblinkedSessionService.empresa.id + ')')
        .subscribe(ref => this.resultadoValidacaoCfop(ref, 'select'),
          error => this.httpService.handleError(error, () => this.escriturarNota(event)),
          () => this.httpService.done());

      this.httpService.wait();
      this.httpService.get('/custom/pedido/visualizar-dados-adicionais-pedido(' + event.nfeId + ')')
        .subscribe(ref => this.dadosAdicionais = ref.dadosAdicionais,
          error => this.httpService.handleError(error, () => this.escriturarNota(event)),
          () => this.httpService.done());
    }
  }

  escriturarNf() {

    //VALIDAÇÃO DA DATA DE ENTRADA E EMISSÃO
        if ((this.pedido.dtFaturamentoAutomatico != null && this.pedido.dtEmissao != null) && (this.pedido.dtFaturamentoAutomatico < this.pedido.dtEmissao)){
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de entrada não pode ser inferior à data de emissão.'})
          return
        }
    // FIM DA VALIDAÇÃO DE DATA DE ENTREGA E EMISSÃO

    let booleanParcial = false;
        if (this.vlTotal < this.pedido.valorTotal) {
          booleanParcial = true;
        }

    if (confirm('Deseja realmente escriturar esta NF?')) {
      const listasEnviar = {
        nfeId: this.nf.nfeId,
        pedidoId: this.pedido.id,
        dtEntrada: this.dtEntrada,
        usuario: this.sessionService.loggedUser.id,
        pedidoParcela: this.pedido.pedidoParcela,
        parcial: booleanParcial
      };


      this.httpService.wait();
      this.httpService.post('/custom/pedido/escrituracao-nf-entrada', listasEnviar)
        .subscribe(ref => this.resultadoEscrituracao(ref),
          error => this.httpService.handleError(error, () => this.escriturarNf()),
          () => this.httpService.done());
    }
  }

  resultadoEscrituracao(event: any) {

    this.existeLog = false;
    event.forEach(element => {
      if (element.error) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: element.message });
      } else {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso:', detail: element.message });
        this.empresaChanged();
      }
    });

  }

  cancelarEscrituracao() {
    if (confirm('Todas as alterações serão perdidas! Deseja continuar?')) {
      this.empresaChanged();
    }
  }



  get rowsPerPage() {
    return this.configService.applicationConfig.rowsPerPage;
  }
  get rows() {
    return this.configService.applicationConfig.rows;
  }
  get pageLinks() {
    return this.configService.applicationConfig.pageLinks;
  }

  selectCheckbox(id: any, event: any) {
    const pedidoTipo = ((event.numNf.startsWith('P') > 0 || event.numNf.startsWith('A') > 0) ? true : false);
    const idioma = ((event.numNf.indexOf('I') > 0) ? '/en' : '/pt');
    const index = this.idNfesBaixa.findIndex(c => c === id);
    const indexPedido = this.idPedidosView.findIndex(c => c === id + idioma);
    const indexPedidoPendente = this.idPedidosServico.findIndex(c => c === id);
    const indexNotaEscriturada = this.idNotasEscrituradas.findIndex(c => c === id);


    if (!event.selected) {
      if (event.modelo.toString().startsWith('55') && !pedidoTipo) {
        this.idNfesBaixa.splice(index, 1);

      } else if (pedidoTipo) { // pedidos view
        this.idPedidosView.splice(indexPedido, 1);

      } else if (event.modelo.toString().startsWith('NFS') && this.statusPendente) {
        this.idPedidosServico.splice(indexPedidoPendente, 1);

      } else if (this.statusEscriturada) {
        this.idNotasEscrituradas.splice(indexNotaEscriturada, 1);

      }
    } else {
      if (event.modelo.toString().startsWith('55') && !pedidoTipo) {
        this.idNfesBaixa.push(id);

      } else if (pedidoTipo) { // pedidos view
        this.idPedidosView.push(id + idioma);

      } else if (event.modelo.toString().startsWith('NFS') && this.statusPendente) {
        this.idPedidosServico.push(event.pedidoStatusId);

      } else if (this.statusEscriturada) {
        this.idNotasEscrituradas.push(event.nfeId);

      }
    }
    this.toolbarRefreshSelectedRows();
    this.changeToolbar()
  }

  private toolbarRefreshSelectedRows() {

    if (this.idPedidosView.length === 1 && this.idNfesBaixa.length === 0 && this.idPedidosServico.length === 0) {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar NFe', () => null);
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
      this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
      this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
      this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
      this.toolbarService.alterarStatus('print', false, 'Visualizar Pedido', () => this.imprimirPedido());
      this.toolbarService.alterarStatus('remove', false, 'Excluir XML', () => this.excluirXml());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.toolbarRefresh());
    } else if (this.idNfesBaixa.length > 0 && this.idPedidosView.length === 0 && this.idPedidosServico.length === 0) {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar NFe', () => null);
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
      this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
      this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
      this.toolbarService.alterarStatus('export', false, 'Exportar', () => this.export());
      this.toolbarService.alterarStatus('print', false, 'Visualizar Danfe', () => this.visualizarDanfe());
      this.toolbarService.alterarStatus('remove', false, 'Excluir XML', () => this.excluirXml());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.toolbarRefresh());
      this.toolbarService.replace('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF');

    } else if (this.idPedidosServico.length > 0 && this.idNfesBaixa.length === 0 && this.idPedidosView.length === 0) {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar NFe', () => null);
      this.toolbarService.alterarStatus('edit', true, 'Carta Correção', () => null);
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
      this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
      this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
      this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
      this.toolbarService.alterarStatus('print', true, 'Visualizar Danfe', () => this.visualizarDanfe());
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.excluirPedido());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.toolbarRefresh());
    } else if (this.idNotasEscrituradas.length === 1) {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar NFe', () => null);
      this.toolbarService.alterarStatus('edit', true, 'Carta Correção', () => null);
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
      this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
      this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
      this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
      this.toolbarService.alterarStatus('print', true, 'Visualizar Danfe', () => this.visualizarDanfe());
      this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.desfazerEscrituracao());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.toolbarRefresh());
    } else {
      this.toolbarRefresh();
    }
  }

  imprimirPedido() {
    // caso for IDEA, PI-TEC ou BRP
    if (this.dblinkedSessionService.empresa.id === 158 ||
      this.dblinkedSessionService.empresa.id === 152 ||
      this.dblinkedSessionService.empresa.id === 234) {

      this.idPedidosView.forEach(pedidoId => {
        window.open('/pedido-view/' + pedidoId, '_blank');
      });
      this.empresaChanged();

    } else {
      // Demais empresas
      this.idPedidosView.forEach(pedidoId => {
        window.open(this.httpService.url('/custom/file/' + pedidoId.split('/')[0] + '/pedidoView'), '_blank');
      });
      this.empresaChanged();
    }

  }

  escriturarCancelada() {
    if (confirm('Esta NF será escriturada como Cancelada, deseja continuar?')) {

      const listasEnviar = {
        ids: this.idNfesBaixa,
        usuario: this.sessionService.loggedUser.id,
      };

      this.httpService.wait();
      this.httpService.post('/custom/notaFiscal/escriturar-nf-cancelada', listasEnviar)
        .subscribe(result => this.empresaChanged(),
          error => this.httpService.handleError(error, () => this.escriturarCancelada()),
          () => this.httpService.done());

    }
  }

  selectTodos(event: any) {
    this.idPedidosServico = new Array();
    this.idNfesBaixa = new Array();
    this.idPedidosView = new Array();
    this.idNotasEscrituradas = new Array();

    if (this.statusPendente) {
      this.xml = this.xmlPendente;
    } else if (this.statusEscriturada) {
      this.xml = this.xmlEscriturado;
    } else if (this.statusCancelada) {
      this.xml = this.xmlCancelado;
    }

    if (event) {
      this.todasSelecionadas = true;
      this.xml.forEach(form => {
        const pedidoTipo = ((form.numNf.startsWith('P') > 0 || form.numNf.startsWith('A') > 0) ? true : false);
        const idioma = ((form.numNf.indexOf('I') > 0) ? '/en' : '/pt');
        if (form.modelo.toString().startsWith('55') && !pedidoTipo) {
          this.selectCheckbox(form.nfeId, { ...form, selected: true })
        } else if (pedidoTipo) {
          this.idPedidosView.push(form.nfeId + idioma);
        } else if (form.modelo.toString().startsWith('NFS') && this.statusPendente) {
          this.idPedidosServico.push(form.pedidoStatusId);
        } else if (this.statusEscriturada) {
          this.idNotasEscrituradas.push(form.nfeId);
        }
      });
    } else {
      this.todasSelecionadas = false;
      this.xml.forEach(form => {
        this.idPedidosServico = new Array();
        this.idNfesBaixa = new Array();
        this.idPedidosView = new Array();
        this.idNotasEscrituradas = new Array();
      });
    }

    this.toolbarRefreshSelectedRows();
    this.changeToolbar()
  }

  visualizarDanfe() {
    console.log(this.idNfesBaixa)
    this.idNfesBaixa.forEach(nfeId => {
      this.httpService.wait();
      this.httpService.get('/custom/nfe/gerar-danfe(' + nfeId + ')')
        .subscribe(result => this.exportedDanfe(result),
          error => this.httpService.handleError(error, () => this.visualizarDanfe()),
          () => this.httpService.done());
    });
  }

  visualizarDanfeId(nfeId: number) {
    this.httpService.wait();
    this.httpService.get('/custom/nfe/gerar-danfe(' + nfeId + ')')
      .subscribe(result => this.exportedDanfe(result),
        error => this.httpService.handleError(error, () => this.visualizarDanfe()),
        () => this.httpService.done());
  }

  exportedDanfe(result?: any) {

    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/danfe'), '_blank');
      this.idNfesBaixa = new Array();
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  export() {
    this.httpService.wait();
    this.httpService.post('/custom/xml/export',
      {
        type: 'nfe',
        operationId: 1, // TODO: Review (ver emissão própria e outros)
        cnpj: this.dblinkedSessionService.empresa.cnpj.toString(),
        ids: this.idNfesBaixa
      })
      .subscribe(result => this.exported(result),
        error => this.httpService.handleError(error,
          () => this.export(),
          (message: any) => this.displayErrorMessage(message)),
        () => this.httpService.done());
  }

  exported(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file), '_blank');
    } else {
      alert(result.message); // TODO: Use Material dialog
    }
    this.empresaChanged();
  }

  displayErrorMessage(error: String | any) {
    alert(typeof error === 'string' ? error : error.message);
  }

  cancelarEdicao() {
    //this.carregarPedido();
    this.editarEscrituracao = false;
  }

  onRowSelectContabil(event: any) {

    if (!this.visualizarEscrituracao) {
      this.httpService.wait();
      this.httpService.get('/escrituracaoentrada?$select=id,pedido/id,nfe/id,dtLcto,planoContas/id,planoContas/codNaturezaConta/id,'
        + 'codCta,descrCta,indDc,vlTotal,cadCcus/id,codCcus,descrCcus,vlCc,cadProj/id,codProj,descrProj,vlCp,statusEscrituracao'
        + '&$filter=id eq ' + event.data.id + '&$orderby=id')
        .subscribe(ref => {

          this.editarEscrituracaoContabil = true;
          this.itemEscrituracaoContabil = ref.value[0];
          if (this.itemEscrituracaoContabil.cadCcus === null) {
            this.itemEscrituracaoContabil.cadCcus = new CadCcus();
          }
          if (this.itemEscrituracaoContabil.cadProj === null) {
            this.itemEscrituracaoContabil.cadProj = new CadProj();
          }
          if (this.itemEscrituracaoContabil.planoContas === null) {
            this.itemEscrituracaoContabil.planoContas = new PlanoContas();
            this.itemEscrituracaoContabil.planoContas.codNaturezaConta = new CodNaturezaConta();
          }
        },
          error => this.httpService.handleError(error, () => this.onRowSelectContabil(event)),
          () => this.httpService.done());
    } else {

      this.httpService.wait();
      this.httpService.get('/lancamentopartida?$select=id,indDc,valor,planoContas/id,planoContas/codNaturezaConta/id,cadCcus/id,'
        + 'cadCcus/descrCcus,cadCcus/codCcus,cadProj/id,cadProj/codProj,cadProj/descrProj,histCompl&$filter=id eq '
        + event.data.id + '&$orderby=id')
        .subscribe(ref => {
          this.editarEscrituracaoContabil = true;
          this.itemEscrituracaoContabil = ref.value[0];

          if (this.itemEscrituracaoContabil.cadCcus === null) {
            this.itemEscrituracaoContabil.cadCcus = new CadCcus();
          }
          if (this.itemEscrituracaoContabil.cadProj === null) {
            this.itemEscrituracaoContabil.cadProj = new CadProj();
          }
          if (this.itemEscrituracaoContabil.planoContas === null) {
            this.itemEscrituracaoContabil.planoContas = new PlanoContas();
            this.itemEscrituracaoContabil.planoContas.codNaturezaConta = new CodNaturezaConta();
          }

          this.itemEscrituracaoContabil.vlCc = ref.value[0].valor;

        },
          error => this.httpService.handleError(error, () => this.onRowSelectContabil(event)),
          () => this.httpService.done());
    }


  }

  loadProj(array: Array<CadProj>) {
    this.opcoesCadProj = [];

    this.opcoesCadProj.push({ label: 'Nenhum', value: null });

    array.forEach(element => {
      this.opcoesCadProj.push({ label: element.codProj + ' - ' + element.descrProj, value: element.id });
    });
  }

  loadCcus(array: Array<CadCcus>) {
    this.opcoesCentroCusto = [];

    this.opcoesCentroCusto.push({ label: 'Nenhum', value: null });

    array.forEach(element => {
      this.opcoesCentroCusto.push({ label: element.codCcus + ' - ' + element.descrCcus, value: element.id });
    });
  }

  loadPlanoContas(array: Array<PlanoContas>) {
    this.opcoesPlanoContas = [];
    array.forEach(element => {
      this.opcoesPlanoContas.push({ label: element.codCta + ' - ' + element.descrCta, value: element.id });
    });
  }

  onRowSelect(event: any) {

    this.editarEscrituracao = true;

    this.httpService.wait();
    this.httpService.get('/escrituracaonfentrada?$select=id,numPed,numDoc,codMod,codPart,codItem,dtDoc,dtEs,ncm,movEstoque,codOper,'
      + 'cfop,qtde,vlUnit,vlDesc,vlProd,vlFrete,vlSeguro,vlOutro,cstIcms,vlRedBcIcms,'
      + 'vlBcIcms,aliqIcms,vlIcms,cstIpi,vlBcIpi,aliqIpi,vlIpi,vlBcIcmsSt,aliqIcmsSt,'
      + 'vlIcmsSt,cstPis,vlBcPis,aliqPis,vlPis,vlBcPisRet,vlPisRet,cstCofins,vlBcCofins,'
      + 'aliqCofins,vlCofins,vlBcCofinsRet,vlCofinsRet,vlDespAduImport,vlIof,vlBcIi,aliqIi,'
      + 'vlIi,vlInssRet,vlIrrf,vlCsllRet,vlBcIss,aliqIss,vlIss,vlIssRet,vlOutrasRet,vlFcp,'
      + 'vlTot,dadosAdicionais,chvNfe,statusEscrituracao,modBcIcms'
      + '&$filter=id eq ' + event.data.id + '&$orderby=codItem')
      .subscribe(ref => {
        ref.value.forEach(element => {
          element.aliqPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqPis)));
          element.aliqCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqCofins)));
          element.aliqIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIcmsSt)));
          element.aliqIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIi)));
          element.aliqIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIpi)));
          element.aliqIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIss)));
          element.qtde = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.qtde)));
          element.vlBcCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofins)));
          element.vlBcCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofinsRet)));
          element.vlBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcms)));
          element.vlBcIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcmsSt)));
          element.vlBcIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIi)));
          element.vlBcIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIpi)));
          element.vlBcIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIss)));
          element.vlBcPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPis)));
          element.vlBcPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPisRet)));
          element.vlCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofins)));
          element.vlCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofinsRet)));
          element.vlCsllRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCsllRet)));
          element.vlDesc = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDesc)));
          element.vlDespAduImport = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDespAduImport)));
          element.vlFcp = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFcp)));
          element.vlFrete = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFrete)));
          element.vlIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcms)));
          element.vlIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcmsSt)));
          element.vlIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIi)));
          element.vlInssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlInssRet)));
          element.vlIof = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIof)));
          element.vlIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIpi)));
          element.vlIrrf = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIrrf)));
          element.vlIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIss)));
          element.vlIssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIssRet)));
          element.vlOutrasRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutrasRet)));
          element.vlOutro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutro)));
          element.vlPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPis)));
          element.vlPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPisRet)));
          element.vlProd = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlProd)));
          element.vlRedBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlRedBcIcms)));
          element.vlSeguro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlSeguro)));
          element.vlTot = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlTot)));
          element.vlUnit = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlUnit)));
          this.linhaEditada = element;
        });
      },
        error => this.httpService.handleError(error, () => this.onRowSelect(event)),
        () => this.httpService.done());

  }

  onRowSelectEdicao(event: any) {

    this.editarEscrituracao = true;
    this.editarEscrituracaoStatus = true;

    if (event.data.tab === 'XML') {

      this.tab = 'XML';

      this.httpService.wait();
      this.httpService.get('/escrituracaonfentrada?$select=id,numPed,numDoc,codMod,codPart,codItem,dtDoc,dtEs,ncm,movEstoque,codOper,'
        + 'cfop,qtde,vlUnit,vlDesc,vlProd,vlFrete,vlSeguro,vlOutro,cstIcms,vlRedBcIcms,'
        + 'vlBcIcms,aliqIcms,vlIcms,cstIpi,vlBcIpi,aliqIpi,vlIpi,vlBcIcmsSt,aliqIcmsSt,'
        + 'vlIcmsSt,cstPis,vlBcPis,aliqPis,vlPis,vlBcPisRet,vlPisRet,cstCofins,vlBcCofins,'
        + 'aliqCofins,vlCofins,vlBcCofinsRet,vlCofinsRet,vlDespAduImport,vlIof,vlBcIi,aliqIi,'
        + 'vlIi,vlInssRet,vlIrrf,vlCsllRet,vlBcIss,aliqIss,vlIss,vlIssRet,vlOutrasRet,vlFcp,'
        + 'vlTot,dadosAdicionais,chvNfe,statusEscrituracao,modBcIcms'
        + '&$filter=id eq ' + event.data.id + '&$orderby=codItem')
        .subscribe(ref => {
          ref.value.forEach(element => {
            element.aliqPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqPis)));
            element.aliqCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqCofins)));
            element.aliqIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIcmsSt)));
            element.aliqIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIi)));
            element.aliqIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIpi)));
            element.aliqIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIss)));
            element.qtde = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.qtde)));
            element.vlBcCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofins)));
            element.vlBcCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofinsRet)));
            element.vlBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcms)));
            element.vlBcIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcmsSt)));
            element.vlBcIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIi)));
            element.vlBcIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIpi)));
            element.vlBcIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIss)));
            element.vlBcPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPis)));
            element.vlBcPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPisRet)));
            element.vlCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofins)));
            element.vlCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofinsRet)));
            element.vlCsllRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCsllRet)));
            element.vlDesc = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDesc)));
            element.vlDespAduImport = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDespAduImport)));
            element.vlFcp = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFcp)));
            element.vlFrete = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFrete)));
            element.vlIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcms)));
            element.vlIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcmsSt)));
            element.vlIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIi)));
            element.vlInssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlInssRet)));
            element.vlIof = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIof)));
            element.vlIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIpi)));
            element.vlIrrf = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIrrf)));
            element.vlIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIss)));
            element.vlIssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIssRet)));
            element.vlOutrasRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutrasRet)));
            element.vlOutro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutro)));
            element.vlPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPis)));
            element.vlPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPisRet)));
            element.vlProd = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlProd)));
            element.vlRedBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlRedBcIcms)));
            element.vlSeguro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlSeguro)));
            element.vlTot = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlTot)));
            element.vlUnit = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlUnit)));
            this.linhaEditada = element;
          });
        },
          error => this.httpService.handleError(error, () => this.onRowSelectEdicao(event)),
          () => this.httpService.done());

    } else {

      this.tab = 'PED';

      this.httpService.wait();
      this.httpService.get('/custom/notaFiscal/get-escrituracao(' + event.data.id + ')')
        .subscribe(ref => {
          ref.forEach(element => {
            element.aliqPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqPis)));
            element.aliqCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqCofins)));
            element.aliqIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIcmsSt)));
            element.aliqIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIi)));
            element.aliqIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIpi)));
            element.aliqIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIss)));
            element.qtde = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.qtde)));
            element.vlBcCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofins)));
            element.vlBcCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofinsRet)));
            element.vlBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcms)));
            element.vlBcIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcmsSt)));
            element.vlBcIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIi)));
            element.vlBcIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIpi)));
            element.vlBcIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIss)));
            element.vlBcPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPis)));
            element.vlBcPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPisRet)));
            element.vlCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofins)));
            element.vlCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofinsRet)));
            element.vlCsllRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCsllRet)));
            element.vlDesc = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDesc)));
            element.vlDespAduImport = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDespAduImport)));
            element.vlFcp = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFcp)));
            element.vlFrete = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFrete)));
            element.vlIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcms)));
            element.vlIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcmsSt)));
            element.vlIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIi)));
            element.vlInssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlInssRet)));
            element.vlIof = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIof)));
            element.vlIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIpi)));
            element.vlIrrf = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIrrf)));
            element.vlIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIss)));
            element.vlIssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIssRet)));
            element.vlOutrasRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutrasRet)));
            element.vlOutro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutro)));
            element.vlPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPis)));
            element.vlPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPisRet)));
            element.vlProd = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlProd)));
            element.vlRedBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlRedBcIcms)));
            element.vlSeguro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlSeguro)));
            element.vlTot = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlTot)));
            element.vlUnit = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlUnit)));
            this.linhaEditada = element;
          });
        },
          error => this.httpService.handleError(error, () => this.onRowSelectEdicao(event)),
          () => this.httpService.done());

    }
  }

  onRowSelectEscrituracao(event: any) {

    this.editarEscrituracao = true;
    this.editarEscrituracaoStatus = true;

    this.httpService.wait();
    this.httpService.get('/escrituracaonfentrada?$select=id,numPed,numDoc,codMod,codPart,codItem,dtDoc,dtEs,ncm,movEstoque,codOper,'
      + 'cfop,qtde,vlUnit,vlDesc,vlProd,vlFrete,vlSeguro,vlOutro,cstIcms,vlRedBcIcms,'
      + 'vlBcIcms,aliqIcms,vlIcms,cstIpi,vlBcIpi,aliqIpi,vlIpi,vlBcIcmsSt,aliqIcmsSt,'
      + 'vlIcmsSt,cstPis,vlBcPis,aliqPis,vlPis,vlBcPisRet,vlPisRet,cstCofins,vlBcCofins,'
      + 'aliqCofins,vlCofins,vlBcCofinsRet,vlCofinsRet,vlDespAduImport,vlIof,vlBcIi,aliqIi,'
      + 'vlIi,vlInssRet,vlIrrf,vlCsllRet,vlBcIss,aliqIss,vlIss,vlIssRet,vlOutrasRet,vlFcp,'
      + 'vlTot,dadosAdicionais,chvNfe,statusEscrituracao,modBcIcms'
      + '&$filter=id eq ' + event.data.id + '&$orderby=codItem')
      .subscribe(ref => {
        ref.value.forEach(element => {
          element.aliqPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqPis)));
          element.aliqCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqCofins)));
          element.aliqIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIcmsSt)));
          element.aliqIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIi)));
          element.aliqIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIpi)));
          element.aliqIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.aliqIss)));
          element.qtde = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.qtde)));
          element.vlBcCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofins)));
          element.vlBcCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcCofinsRet)));
          element.vlBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcms)));
          element.vlBcIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIcmsSt)));
          element.vlBcIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIi)));
          element.vlBcIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIpi)));
          element.vlBcIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcIss)));
          element.vlBcPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPis)));
          element.vlBcPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlBcPisRet)));
          element.vlCofins = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofins)));
          element.vlCofinsRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCofinsRet)));
          element.vlCsllRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlCsllRet)));
          element.vlDesc = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDesc)));
          element.vlDespAduImport = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlDespAduImport)));
          element.vlFcp = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFcp)));
          element.vlFrete = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlFrete)));
          element.vlIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcms)));
          element.vlIcmsSt = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIcmsSt)));
          element.vlIi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIi)));
          element.vlInssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlInssRet)));
          element.vlIof = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIof)));
          element.vlIpi = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIpi)));
          element.vlIrrf = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIrrf)));
          element.vlIss = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIss)));
          element.vlIssRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlIssRet)));
          element.vlOutrasRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutrasRet)));
          element.vlOutro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlOutro)));
          element.vlPis = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPis)));
          element.vlPisRet = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlPisRet)));
          element.vlProd = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlProd)));
          element.vlRedBcIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlRedBcIcms)));
          element.vlSeguro = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlSeguro)));
          element.vlTot = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlTot)));
          element.vlUnit = this.utilService.parseMoneyString((this.utilService.parseDecimal(element.vlUnit)));
          this.linhaEditada = element;
        });
      },
        error => this.httpService.handleError(error, () => this.onRowSelectEscrituracao(event)),
        () => this.httpService.done());
  }

  excluirLinha() {
    if (confirm('Deseja excluir esta linha?')) {

      this.httpService.wait();
      this.httpService.delete('/nfeitemescritentrada', this.linhaEditada.id.toString())
        .subscribe(() => this.cancelarEdicao(),
          error => this.httpService.handleError(error, () => this.excluirLinha()),
          () => this.httpService.done());

    }

  }

  salvarEdicaoLinha() {
    if (confirm('Caso esta alteração seja efetivada a linha ficará diferente do que a regra determina, deseja continuar?')) {

      const listasEnviar = {
        id: this.linhaEditada.id,
        usuario: this.sessionService.loggedUser.id,
        cfop: this.linhaEditada.cfopEntrada,
        cstIcms: this.linhaEditada.cstIcms,
        cstPis: this.linhaEditada.cstPis,
        cstCofins: this.linhaEditada.cstCofins
      };

      this.httpService.wait();
      this.httpService.post('/custom/notaFiscal/atualizar-linha-escrituracao', listasEnviar)
        .subscribe(result => this.cancelarEdicao(),
          error => this.httpService.handleError(error, () => this.salvarEdicaoLinha()),
          () => this.httpService.done());

    }
  }

  get buttons(): Array<ToolbarButton> {
    return this.toolbarService.buttons;
  }
  private toolbarRefresh() {
    const todos = this.buttons.slice();


    this.toolbarService.ocultar('save');
    this.toolbarService.ocultar('alterar');

    if (isNullOrUndefined(this.toolbarService.get('msg'))) {
      this.toolbarService.add('msg', true, 'class-other-button', 'warning', 'Visualizar');
    }

    if (todos.length === 0) {
      this.toolbarService.replace('cancel', true, 'class-other-button class-botao-margin', 'block', 'Cancelar',);
      this.toolbarService.replace('voltar', true, 'class-first-button', 'undo', 'Voltar',);
      this.toolbarService.replace('atualizar', false, 'class-first-button', 'refresh', 'Atualizar relatório', () => this.empresaChanged());
      this.toolbarService.replace('download', true, 'class-other-button', 'cloud_download', 'Download',);
      this.toolbarService.replace('msg', true, 'class-other-button', 'warning', 'Visualizar',);
      this.toolbarService.replace('pesquisa', true, 'class-first-button', 'search', 'Pesquisar',);
      this.toolbarService.replace('export', true, 'class-other-button', 'description', 'Exportar',);
      this.toolbarService.replace('print', true, 'class-first-button', 'print', 'Imprimir',);
      this.toolbarService.replace('remove', true, 'class-remove-button', 'delete', 'Excluir',);
      this.toolbarService.replace('edit', true, 'class-edit-button', 'edit', 'Editar',);
      this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Ações');
      this.toolbarService.replace('check', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
      this.toolbarService.replace('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF');

    } else {
      this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
      this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
      this.toolbarService.alterarStatus('pesquisa', true, 'Pesquisar', () => this.verificarDetalhe());
      this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
      this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarDanfe());
      this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.desfazerEscrituracao());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.toolbarRefresh());
      this.toolbarService.replace('cancel', true, 'class-other-button class-botao-margin', 'block', 'Cancelar',);
      this.toolbarService.replace('more_vert', false, 'class-edit-button', 'more_vert', 'Ações');
      this.toolbarService.replace('check', false, 'class-button-gray', 'Alterar Período', 'Alterar Período', () => this.openModalPeriodo());
      this.toolbarService.replace('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF');
    }


    this.toolbarService.replace(
      'add',
      false,
      'class-new-button',
      'NOVO',
      'Nova Nota Fiscal',
      () => this.buscarParticipante()
    );

    this.loadAcoes();

  }

  private toolbarEscriturar() {



    if (this.nf.emitidoSistema) {

      this.toolbarService.alterarStatus('edit', false, 'Carta de Correção', () => this.modalCartaCorrecaoNfe());
      this.toolbarService.alterarStatus('cancel', false, 'Cancelar NF', () => this.modalCancelarNfeOpen());
    } else {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar', () => this.voltar());
    }

    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar relatório', () => this.empresaChanged());
    this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
    this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarDanfe());
    this.toolbarService.alterarStatus('remove', (!this.visualizarEscrituracao), 'Excluir', () => this.desfazerEscrituracao());
    this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.visualizarDanfe());
    this.toolbarService.replace('add', false, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF', () => this.escriturarNf());

  }


  private toolbarEscriturarNfs() {

    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar relatório', () => this.empresaChanged());
    this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
    this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarDanfe());
    this.toolbarService.add('search', false, 'class-remove-button', 'search', 'Verificar Detalhes', () => this.verificarDetalhe());
    this.toolbarService.alterarStatus('remove', (!this.visualizarEscrituracao), 'Excluir', () => this.desfazerEscrituracao());
    if (!this.nf.emitidoSistema) {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar', () => this.voltar());
      this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.visualizarDanfe());
    }
    this.toolbarService.replace('save', false, 'class-new-button', 'SALVAR', 'Salvar', () => this.salvarPedido(false));
    this.toolbarService.replace('add', false, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF', () => this.escriturarNfPedido());

  }

  voltar() {
    this.visualizarEscrituracao = false;
    this.toolbarRefresh();
    this.exibirNf = false;
    this.escritNf = false;
    this.idNfesBaixa = new Array();
    this.idPedidosView = new Array();
  }

  private toolbarVisualizaEscrituracao() {
    //
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar relatório', () => this.empresaChanged());
    this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
    this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarDanfe());
    this.toolbarService.alterarStatus('remove', false, 'Excluir', () => this.desfazerEscrituracao());
    if (this.nf.emitidoSistema) {
      this.toolbarService.alterarStatus('edit', false, 'Carta de Correção', () => this.modalCartaCorrecaoNfe());
      this.toolbarService.alterarStatus('cancel', false, 'Cancelar NF', () => this.modalCancelarNfeOpen());
    } else {
      this.toolbarService.alterarStatus('cancel', true, 'Cancelar', () => this.voltar());
      this.toolbarService.alterarStatus('edit', true, 'Carta de Correção', () => this.modalCartaCorrecaoNfe());
    }
    this.toolbarService.replace('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF', () => this.escriturarNf());


  }

  private toolbarLog() {
    this.toolbarService.alterarStatus('cancel', true, 'Cancelar', () => this.voltar());
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('atualizar', false, 'Atualizar relatório', () => this.empresaChanged());
    this.toolbarService.alterarStatus('msg', false, 'Visualizar Log', () => this.abreModalLog());
    this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarDanfe());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.desfazerEscrituracao());
    this.toolbarService.alterarStatus('edit', true, 'Editar', () => this.toolbarRefresh());
    this.toolbarService.replace('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF', () => this.escriturarNf());

  }

  private toolbarEditarPedido() {
    this.toolbarService.alterarStatus('cancel', true, 'Cancelar', () => this.voltar());
    this.toolbarService.alterarStatus('voltar', false, 'Voltar', () => this.voltar());
    this.toolbarService.alterarStatus('atualizar', true, 'Atualizar relatório', () => this.empresaChanged());
    this.toolbarService.alterarStatus('msg', true, 'Visualizar Log', () => this.abreModalLog());
    this.toolbarService.alterarStatus('pesquisa', true, 'Verificar Detalhes', () => this.verificarDetalhe());
    this.toolbarService.alterarStatus('export', true, 'Exportar', () => this.export());
    this.toolbarService.alterarStatus('print', true, 'Imprimir', () => this.visualizarDanfe());
    this.toolbarService.alterarStatus('remove', true, 'Excluir', () => this.desfazerEscrituracao());
    this.toolbarService.alterarStatus('edit', false, 'Editar', () => this.editarPedido());
    this.toolbarService.replace('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF', () => this.escriturarNf());

    /* this.toolbarService.clear();
      this.toolbarService.add('back', false, 'class-back-button', 'undo', 'Voltar', () => this.ngOnInit());
      this.toolbarService.add('export', true, 'class-export-button', 'description', 'Exportar');
      this.toolbarService.add('print', true, 'class-print-button', 'print', 'Imprimir');
      this.toolbarService.add('remove', true, 'class-remove-button', 'delete', 'Excluir');
      this.toolbarService.add('edit', false, 'class-edit-button', 'edit', 'Editar', () => this.editarPedido());
      this.toolbarService.add('add', true, 'class-new-button', 'ESCRITURAR NF', 'Escriturar NF');*/
  }

  desfazerEscrituracao() {
    if (confirm('Todos os lançamentos contábeis, fiscais e financeiros serão desfeitos, deseja continuar?')) {

      if (this.tab === 'XML') {

        const listasEnviar = {
          nfe: this.nf.nfeId.toString(),
          empresa: this.dblinkedSessionService.empresa.id,
        };

        this.httpService.wait();
        this.httpService.post('/custom/notaFiscal/desfazer-escrituracao', listasEnviar)
          .subscribe(result => {


            if (result[0].error === true) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
              this.empresaChanged();
            }
          },
            error => this.httpService.handleError(error, () => this.desfazerEscrituracao()),
            () => this.httpService.done());
      } else {

        let listasEnviar;
        if (this.telaNotaEscriturada) {
          listasEnviar = {
            nfe: this.nf.nfeId.toString(),
            numNf: this.nf.numNf.toString(),
            valorTotal: this.utilService.parseDecimal(this.nf.valor)
          };
        } else {
          const index = this.xmlEscriturado.findIndex(nf => nf.nfeId === this.idNotasEscrituradas[0]);
          listasEnviar = {
            nfe: this.xmlEscriturado[index].nfeId.toString(),
            numNf: this.xmlEscriturado[index].numNf.toString(),
            valorTotal: this.utilService.parseDecimal(this.xmlEscriturado[index].valor)
          };
        }

        this.httpService.wait();
        this.httpService.post('/custom/notaFiscal/desfazer-escrituracao-ped', listasEnviar)
          .subscribe(result => {


            if (result[0].error === true) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
              this.empresaChanged();
            }
          },
            error => this.httpService.handleError(error, () => this.desfazerEscrituracao()),
            () => this.httpService.done());
      }
    }
  }

  verificarDetalhe() {
    if (!this.escritNf) {
      this.httpService.wait();
      this.httpService.get('/custom/pedido/visualizar-grade-fiscal(' + this.nf.nfeId + ',' + this.dblinkedSessionService.empresa.id + ')')
        .subscribe(ref => this.resultadoGradeFiscal(ref),
          error => this.httpService.handleError(error, () => this.verificarDetalhe()),
          () => this.httpService.done());
    } else {
      this.exibirDetalhes = true;
    }

  }

  resultadoGradeFiscal(event: any) {
    this.gradeFiscal = new Array();
    this.itensForn = new Array();
    event.forEach(element => {
      if (element.tipo === 'fiscal') {
        this.gradeFiscal.push(element);
      } else if (element.tipo === 'itens') {
        this.itensForn.push(element);
      }
    });
  }

  onFilter(event: any) {

    this.valorAtual = 0.00;
    this.valorFiltro = 0.00;
    this.idNfesBaixa = new Array();
    this.idPedidosView = new Array();
    this.contadorPendente = 0;
    this.contadorEscriturado = 0;
    this.contadorCancelado = 0;

    let contador = 0;
    event.filteredValue.forEach(element => {
      this.valor = parseFloat((element.valor).replace('.', '').replace(',', '.'));
      this.valorFiltro = this.valorFiltro + this.valor;
      // this.idNfesBaixa.push(element.nfeId, 1);
      element.selected = true;
      contador = contador + 1;
      this.contadorPendente = this.contadorPendente + 1;
      this.contadorEscriturado = this.contadorEscriturado + 1;
      this.contadorCancelado = this.contadorCancelado + 1;
    });


    if (contador === 0) {
      this.idNfesBaixa = new Array();
      this.xml.forEach(form => {
        form.selected = false;
      });

    }

    if (this.idNfesBaixa.length > 0) {
      this.toolbarService.alterarStatus('print', false, 'Visualizar Danfe', () => this.visualizarDanfe());
    }

  }

  tabChange(event: any) {
    this.valorAtual = 0.00;
    this.valorFiltro = 0.00;
    this.abaSelecionada = (event.index);
    this.idNfesBaixa = new Array();
    this.toolbarRefresh();
    this.todasSelecionadas = false;
    if (event.index === 0) {
      this.valorAtual = this.valorTotalPendete;
    } else if (event.index === 1) {
      this.valorAtual = this.valorTotalEscriturado;
    } else {
      this.valorAtual = -1.00;
    }
  }

  /*MODAL ALTERAR PERÍODO */
  openModalPeriodo() {
    this.modalPeriodo = true;
    this.dataFin = this.utilService.dateWithoutTimeZone(this.dataFinSearch);
    this.dataIni = this.utilService.dateWithoutTimeZone(this.dataIniSearch);
  }

  closeModalPeriodo() {
    this.modalPeriodo = false;
    this.dataIni = null;
    this.dataFin = null;
  }

  selectItem(event) {
    this.toolbarEditarPedido();

    this.pedidoItemEditar = event.data;

    if (this.pedidoItemEditar.cstIpi === null) {
      this.pedidoItemEditar.cstIpi = new CstIpi();
    }

    if (this.pedidoItemEditar.cstIcmsTabelaA === null) {
      this.pedidoItemEditar.cstIcmsTabelaA = new CstIcmsTabelaA();
    }
    if (this.pedidoItemEditar.cstIcmsTabelaB === null) {
      this.pedidoItemEditar.cstIcmsTabelaB = new CstIcmsTabelaB();
    }

    if (this.pedidoItemEditar.cstPis === null) {
      this.pedidoItemEditar.cstPis = new CstPiscofins();
    }

    if (this.pedidoItemEditar.cstCofins === null) {
      this.pedidoItemEditar.cstCofins = new CstPiscofins();
    }

    if (this.pedidoItemEditar.item === null) {
      this.pedidoItemEditar.item = new Item();
    }

    this.exibirLinha = true;
    //this.opcoesItens = [];
    //this.opcoesItens.push({ label: this.pedidoItemEditar.item.codigo, value: null });

  }

  editarPedido() {
    this.exibirLinha = true;

  }

  /*loadItens(value: Array<Item>) {
    this.opcoesItens = [];
    value.forEach(element => {
      this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
    });
  }*/

  situacaoTributariaIcms() {
    this.opcoesCstIcms = [];
    this.httpService.wait();
    this.httpService.get('/csticms?$select=id,cst,descricao')
      .subscribe(cstIcms => {
        cstIcms.value.forEach(icms => {
          this.opcoesCstIcms.push({ label: icms.cst + ' - ' + icms.descricao, value: icms.id });
        });
      },
        error => this.httpService.handleError(error, () => this.situacaoTributariaIcms()),
        () => this.httpService.done());
  }

  situacaoTributariaIpi() {
    this.opcoesCstIpi = [];
    this.httpService.wait();
    this.httpService.get('/cstipi?$select=id,cst,descricao')
      .subscribe(cstIpi => {
        cstIpi.value.forEach(ipi => {
          this.opcoesCstIpi.push({ label: ipi.cst + ' - ' + ipi.descricao, value: ipi.id });
        });
      },
        error => this.httpService.handleError(error, () => this.situacaoTributariaIpi()),
        () => this.httpService.done());
  }

  situacaoTributariaCofins() {
    this.opcoesCstCofins = [];
    this.httpService.wait();
    this.httpService.get('/cstpiscofins?$select=id,cst,descricao')
      .subscribe(cstcofins => {
        this.situacaoTributariaPis(cstcofins.value);
        cstcofins.value.forEach(cofins => {
          this.opcoesCstCofins.push({ label: cofins.cst + ' - ' + cofins.descricao, value: cofins.id });
        });
      },
        error => this.httpService.handleError(error, () => this.situacaoTributariaCofins()),
        () => this.httpService.done());
  }

  situacaoTributariaPis(value) {
    this.opcoesCstPis = [];
    value.forEach(pis => {
      this.opcoesCstPis.push({ label: pis.cst + ' - ' + pis.descricao, value: pis.id });
    });

  }

  atualizarLinhaEscrituracao() {

    if (this.editarEscrituracaoStatus) {

      if (this.linhaEditada.id) {

        let status;
        if (this.tab === 'PED') {
          status = 2;
        } else {
          status = this.linhaEditada.statusEscrituracao.id;
        }

        const listasEnviar = {
          id: this.linhaEditada.id,
          aliqCofins: this.utilService.parseDecimal(this.linhaEditada.aliqCofins),
          aliqIcms: this.utilService.parseDecimal(this.linhaEditada.aliqIcms),
          aliqIcmsSt: this.utilService.parseDecimal(this.linhaEditada.aliqIcmsSt),
          aliqIi: this.utilService.parseDecimal(this.linhaEditada.aliqIi),
          aliqIpi: this.utilService.parseDecimal(this.linhaEditada.aliqIpi),
          aliqIss: this.utilService.parseDecimal(this.linhaEditada.aliqIss),
          aliqPis: this.utilService.parseDecimal(this.linhaEditada.aliqPis),
          cfop: this.linhaEditada.cfop,
          chvNfe: this.linhaEditada.chvNfe,
          codItem: this.linhaEditada.codItem,
          codMod: this.linhaEditada.codMod,
          codOper: this.linhaEditada.codOper,
          codPart: this.linhaEditada.codPart,
          cstCofins: this.linhaEditada.cstCofins,
          cstIcms: this.linhaEditada.cstIcms,
          cstIpi: this.linhaEditada.cstIpi,
          cstPis: this.linhaEditada.cstPis,
          dadosAdicionais: this.linhaEditada.dadosAdicionais,
          dtDoc: this.nf.emissao,
          dtEs: this.dtEntrada,
          movEstoque: this.linhaEditada.movEstoque,
          ncm: this.linhaEditada.ncm,
          numDoc: this.linhaEditada.numDoc,
          numPed: this.linhaEditada.numPed,
          qtde: this.utilService.parseDecimal(this.linhaEditada.qtde),
          statusEscrituracao: status,
          vlBcCofins: this.utilService.parseDecimal(this.linhaEditada.vlBcCofins),
          vlBcCofinsRet: this.utilService.parseDecimal(this.linhaEditada.vlBcCofinsRet),
          vlBcIcms: this.utilService.parseDecimal(this.linhaEditada.vlBcIcms),
          vlBcIcmsSt: this.utilService.parseDecimal(this.linhaEditada.vlBcIcmsSt),
          vlBcIi: this.utilService.parseDecimal(this.linhaEditada.vlBcIi),
          vlBcIpi: this.utilService.parseDecimal(this.linhaEditada.vlBcIpi),
          vlBcIss: this.utilService.parseDecimal(this.linhaEditada.vlBcIss),
          vlBcPis: this.utilService.parseDecimal(this.linhaEditada.vlBcPis),
          vlBcPisRet: this.utilService.parseDecimal(this.linhaEditada.vlBcPisRet),
          vlCofins: this.utilService.parseDecimal(this.linhaEditada.vlCofins),
          vlCofinsRet: this.utilService.parseDecimal(this.linhaEditada.vlCofinsRet),
          vlCsllRet: this.utilService.parseDecimal(this.linhaEditada.vlCsllRet),
          vlDesc: this.utilService.parseDecimal(this.linhaEditada.vlDesc),
          vlDespAduImport: this.utilService.parseDecimal(this.linhaEditada.vlDespAduImport),
          vlFcp: this.utilService.parseDecimal(this.linhaEditada.vlFcp),
          vlFrete: this.utilService.parseDecimal(this.linhaEditada.vlFrete),
          vlIcms: this.utilService.parseDecimal(this.linhaEditada.vlIcms),
          vlIcmsSt: this.utilService.parseDecimal(this.linhaEditada.vlIcmsSt),
          vlIi: this.utilService.parseDecimal(this.linhaEditada.vlIi),
          vlInssRet: this.utilService.parseDecimal(this.linhaEditada.vlInssRet),
          vlIof: this.utilService.parseDecimal(this.linhaEditada.vlIof),
          vlIpi: this.utilService.parseDecimal(this.linhaEditada.vlIpi),
          vlIrrf: this.utilService.parseDecimal(this.linhaEditada.vlIrrf),
          vlIss: this.utilService.parseDecimal(this.linhaEditada.vlIss),
          vlIssRet: this.utilService.parseDecimal(this.linhaEditada.vlIssRet),
          vlOutro: this.utilService.parseDecimal(this.linhaEditada.vlOutro),
          vlPis: this.utilService.parseDecimal(this.linhaEditada.vlPis),
          vlPisRet: this.utilService.parseDecimal(this.linhaEditada.vlPisRet),
          vlProd: this.utilService.parseDecimal(this.linhaEditada.vlProd),
          vlRedBcIcms: this.utilService.parseDecimal(this.linhaEditada.vlRedBcIcms),
          vlSeguro: this.utilService.parseDecimal(this.linhaEditada.vlSeguro),
          vlTot: this.utilService.parseDecimal(this.linhaEditada.vlTot),
          vlUnit: this.utilService.parseDecimal(this.linhaEditada.vlUnit),
          usuario: this.sessionService.loggedUser.id,
          status: this.visualizarEscrituracao ? '2' : 1,
          tipo: this.tab,
          modBcIcms: this.linhaEditada.modBcIcms,
          vlTotal: this.utilService.parseDecimal(this.vlTotal)
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/atualizar-item-escrituracao', listasEnviar)
          .subscribe(result => {

            if (this.visualizarEscrituracao) {
              this.httpService.wait();
              this.httpService.get('/custom/notaFiscal/notaFiscal/visualizar-escrituracao(' + this.nf.nfeId + ',' +
                this.dblinkedSessionService.empresa.id + ')')
                .subscribe(ref => this.resultadoValidacaoCfop(ref, 'update'),
                  error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracao()),
                  () => this.httpService.done());


            } else {
              if (!this.visualizarEscrituracao) {
                this.httpService.wait();
                this.httpService.get('/pedidoparcela?$select=id,parcela,valor,dtVencimento,empresaConta/id,empresaConta/nome'
                  + '&$filter=pedido/id eq ' + this.pedido.id + '&$orderby=parcela')
                  .subscribe(ref => this.loadParcelaPedido(ref.value),
                    error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracao()),
                    () => this.httpService.done());
              }



            }


            if (result[0].error === true) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
              this.editarEscrituracao = false;
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
              this.editarEscrituracao = false;
            }

            this.escrituracaoContabil = new Array();
            this.httpService.wait();
            this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + this.nf.nfeId + ',' +
              this.dblinkedSessionService.empresa.id + ')')
              .subscribe(ref => {
                ref.forEach(element => {
                  this.escrituracaoContabil.push(element);
                });
              },
                error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracao()),
                () => this.httpService.done());



          },
            error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracao()),
            () => this.httpService.done());
      }
    } else {
      if (this.linhaEditada.id) {
        const listasEnviar = {
          id: this.linhaEditada.id,
          aliqCofins: this.utilService.parseDecimal(this.linhaEditada.aliqCofins),
          aliqIcms: this.utilService.parseDecimal(this.linhaEditada.aliqIcms),
          aliqIcmsSt: this.utilService.parseDecimal(this.linhaEditada.aliqIcmsSt),
          aliqIi: this.utilService.parseDecimal(this.linhaEditada.aliqIi),
          aliqIpi: this.utilService.parseDecimal(this.linhaEditada.aliqIpi),
          aliqIss: this.utilService.parseDecimal(this.linhaEditada.aliqIss),
          aliqPis: this.utilService.parseDecimal(this.linhaEditada.aliqPis),
          cfop: this.linhaEditada.cfop,
          chvNfe: this.linhaEditada.chvNfe,
          codItem: this.linhaEditada.codItem,
          codMod: this.linhaEditada.codMod,
          codOper: this.linhaEditada.codOper,
          codPart: this.linhaEditada.codPart,
          cstCofins: this.linhaEditada.cstCofins,
          cstIcms: this.linhaEditada.cstIcms,
          cstIpi: this.linhaEditada.cstIpi,
          cstPis: this.linhaEditada.cstPis,
          dadosAdicionais: this.linhaEditada.dadosAdicionais,
          dtDoc: this.nf.emissao,
          dtEs: this.dtEntrada,
          movEstoque: this.linhaEditada.movEstoque,
          ncm: this.linhaEditada.ncm,
          numDoc: this.linhaEditada.numDoc,
          numPed: this.linhaEditada.numPed,
          qtde: this.utilService.parseDecimal(this.linhaEditada.qtde),
          statusEscrituracao: this.linhaEditada.statusEscrituracao.id,
          vlBcCofins: this.utilService.parseDecimal(this.linhaEditada.vlBcCofins),
          vlBcCofinsRet: this.utilService.parseDecimal(this.linhaEditada.vlBcCofinsRet),
          vlBcIcms: this.utilService.parseDecimal(this.linhaEditada.vlBcIcms),
          vlBcIcmsSt: this.utilService.parseDecimal(this.linhaEditada.vlBcIcmsSt),
          vlBcIi: this.utilService.parseDecimal(this.linhaEditada.vlBcIi),
          vlBcIpi: this.utilService.parseDecimal(this.linhaEditada.vlBcIpi),
          vlBcIss: this.utilService.parseDecimal(this.linhaEditada.vlBcIss),
          vlBcPis: this.utilService.parseDecimal(this.linhaEditada.vlBcPis),
          vlBcPisRet: this.utilService.parseDecimal(this.linhaEditada.vlBcPisRet),
          vlCofins: this.utilService.parseDecimal(this.linhaEditada.vlCofins),
          vlCofinsRet: this.utilService.parseDecimal(this.linhaEditada.vlCofinsRet),
          vlCsllRet: this.utilService.parseDecimal(this.linhaEditada.vlCsllRet),
          vlDesc: this.utilService.parseDecimal(this.linhaEditada.vlDesc),
          vlDespAduImport: this.utilService.parseDecimal(this.linhaEditada.vlDespAduImport),
          vlFcp: this.utilService.parseDecimal(this.linhaEditada.vlFcp),
          vlFrete: this.utilService.parseDecimal(this.linhaEditada.vlFrete),
          vlIcms: this.utilService.parseDecimal(this.linhaEditada.vlIcms),
          vlIcmsSt: this.utilService.parseDecimal(this.linhaEditada.vlIcmsSt),
          vlIi: this.utilService.parseDecimal(this.linhaEditada.vlIi),
          vlInssRet: this.utilService.parseDecimal(this.linhaEditada.vlInssRet),
          vlIof: this.utilService.parseDecimal(this.linhaEditada.vlIof),
          vlIpi: this.utilService.parseDecimal(this.linhaEditada.vlIpi),
          vlIrrf: this.utilService.parseDecimal(this.linhaEditada.vlIrrf),
          vlIss: this.utilService.parseDecimal(this.linhaEditada.vlIss),
          vlIssRet: this.utilService.parseDecimal(this.linhaEditada.vlIssRet),
          vlOutro: this.utilService.parseDecimal(this.linhaEditada.vlOutro),
          vlPis: this.utilService.parseDecimal(this.linhaEditada.vlPis),
          vlPisRet: this.utilService.parseDecimal(this.linhaEditada.vlPisRet),
          vlProd: this.utilService.parseDecimal(this.linhaEditada.vlProd),
          vlRedBcIcms: this.utilService.parseDecimal(this.linhaEditada.vlRedBcIcms),
          vlSeguro: this.utilService.parseDecimal(this.linhaEditada.vlSeguro),
          vlTot: this.utilService.parseDecimal(this.linhaEditada.vlTot),
          vlUnit: this.utilService.parseDecimal(this.linhaEditada.vlUnit),
          usuario: this.sessionService.loggedUser.id,
          status: this.visualizarEscrituracao ? '2' : 1,
          tipo: this.tab,
          vlTotal: this.utilService.parseDecimal(this.vlTotal)
        };

        this.httpService.wait();
        this.httpService.post('/custom/pedido/atualizar-item-escrituracao', listasEnviar)
          .subscribe(result => this.sucessoAtualizacaoPedido(),
            error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracao()),
            () => this.httpService.done());
      }
    }
  }

  sucessoAtualizacaoNfEscriturada() {
    this.editarEscrituracao = false;
    this.editarEscrituracaoStatus = false;

    this.httpService.wait();
    this.httpService.get('/custom/notaFiscal/notaFiscal/visualizar-escrituracao(' + this.nf.nfeId + ',' +
      this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => { this.nfEscriturada = ref; },
        error => this.httpService.handleError(error, () => this.sucessoAtualizacaoNfEscriturada()),
        () => this.httpService.done());

  }

  onRowSelectEscrituracaoContabil(event: any) {



    this.httpService.wait();
    this.httpService.get('/lancamentopartida?$select=id,indDc,valor,planoContas/id,planoContas/codNaturezaConta/id,cadCcus/id,'
      + 'cadProj/id,histCompl&$filter=id eq ' + event.data.id + '&$orderby=id')
      .subscribe(ref => {

        this.partidaLancamentoEditar = ref.value[0];
        if (this.partidaLancamentoEditar.cadCcus === null) {
          this.partidaLancamentoEditar.cadCcus = new CadCcus();
        }
        if (this.partidaLancamentoEditar.cadProj === null) {
          this.partidaLancamentoEditar.cadProj = new CadProj();
        }
        if (this.partidaLancamentoEditar.planoContas === null) {
          this.partidaLancamentoEditar.planoContas = new PlanoContas();

          this.partidaLancamentoEditar.planoContas.codNaturezaConta = new CodNaturezaConta();
          /*if (this.partidaLancamentoEditar.planoContas.codNaturezaConta === null) {
          this.partidaLancamentoEditar.planoContas.codNaturezaConta = new CodNaturezaConta();
          }*/

        }

        this.editarLctoContabil = true;

      }
        ,
        error => this.httpService.handleError(error, () => this.onRowSelectEscrituracaoContabil(event)),
        () => this.httpService.done());


  }

  excluirPartidaLctoContabil() {
    if (confirm('Deseja realmente remover esta partida?')) {
      this.httpService.wait();
      this.httpService.post('/custom/pedido/excluir-partida-contabil(' + this.partidaLancamentoEditar.id.toString() + ')')
        .subscribe(result => {


          this.editarEscrituracao = false;

          if (result[0].error === true) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
          }

          this.httpService.wait();
          this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + this.nf.nfeId + ',' +
            this.dblinkedSessionService.empresa.id + ')')
            .subscribe(ref => { this.nfGradeContabil = ref; this.editarLctoContabil = false; },
              error => this.httpService.handleError(error, () => this.excluirPartidaLctoContabil()),
              () => this.httpService.done());
        },
          error => this.httpService.handleError(error, () => this.excluirPartidaLctoContabil()),
          () => this.httpService.done());
    }
  }

  cancelarEdicaoPartidaLctoContabil() {
    if (confirm('Deseja realmente cancelar esta edição?')) {
      this.editarLctoContabil = false;
    }

  }

  atualizarPartidaContabil() {

    const listasEnviar = {
      id: this.partidaLancamentoEditar.id,
      planocontas: this.partidaLancamentoEditar.planoContas.id,
      indDc: this.partidaLancamentoEditar.indDc,
      cc: this.partidaLancamentoEditar.cadCcus.id,
      cadProj: this.partidaLancamentoEditar.cadProj.id,
      histCompl: this.partidaLancamentoEditar.histCompl,
      valor: this.utilService.parseDecimal(this.partidaLancamentoEditar.valor),
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/pedido/atualizar-partida-contabil', listasEnviar)
      .subscribe(result => {


        if (result[0].error === true) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
        }

        this.httpService.wait();
        this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + this.nf.nfeId + ',' +
          this.dblinkedSessionService.empresa.id + ')')
          .subscribe(ref => { this.nfGradeContabil = ref; this.editarLctoContabil = false; },
            error => this.httpService.handleError(error, () => this.atualizarPartidaContabil()),
            () => this.httpService.done());
      },
        error => this.httpService.handleError(error, () => this.atualizarPartidaContabil()),
        () => this.httpService.done());

  }

  sucessoAtualizacaoPedido() {
    this.editarEscrituracao = false;
    this.editarEscrituracaoContabil = false;

    this.httpService.wait();
    this.httpService.get('/custom/pedido/visualizar-escrituracao(' + this.nf.nfeId + ',' + this.pedido.id + ')')
      .subscribe(ref => this.resultadoValidacaoCfop(ref, 'update'),
        error => this.httpService.handleError(error, () => this.sucessoAtualizacaoPedido()),
        () => this.httpService.done());




  }

  calculaValorIpi() {
    this.linhaEditada.vlIpi = (this.utilService.parseDecimal(this.linhaEditada.vlBcIpi) *
      this.utilService.parseDecimal(this.linhaEditada.aliqIpi)) / 100;
  }

  calculaValorIcms() {
    this.linhaEditada.vlIcms = this.utilService.parseMoneyString((this.utilService.parseDecimal(this.linhaEditada.vlBcIcms) *
      this.utilService.parseDecimal(this.linhaEditada.aliqIcms)) / 100);
  }

  calculaValorIcmsSt() {
    this.linhaEditada.vlIcmsSt = this.utilService.parseMoneyString(
      (this.utilService.parseDecimal(this.linhaEditada.vlBcIcmsSt) *
        this.utilService.parseDecimal(this.linhaEditada.aliqIcmsSt)) / 100);
  }

  calculaValorPis() {

    this.linhaEditada.vlPis = this.utilService.parseMoneyString(
      (this.utilService.parseDecimal(this.linhaEditada.vlBcPis) *
        this.utilService.parseDecimal(this.linhaEditada.aliqPis)) / 100);
  }

  calculaValorCofins() {
    this.linhaEditada.vlCofins = this.utilService.parseMoneyString(
      (this.utilService.parseDecimal(this.linhaEditada.vlBcCofins) *
        this.utilService.parseDecimal(this.linhaEditada.aliqCofins)) / 100);
  }

  loadPedidoStatus(value: Array<any>) {
    this.opcoesStatusPedido = [];

    /* 1 - Venda Produto
       2 - Venda Serviço
       3 - Aquisição Produto
       4 - Aquisição Serviço
       */

    /* 1 - Em negociação
    2 - Aprovado
    3 - Recusado
    4 - Venda
    5 - Finalizado
    6 - Excluído
    7 - Compra
    10 - Compra Direta
    11 - Venda Direta*/


    value.forEach(element => {

      if ((element.id === this.pedido.pedidoStatus.id)) {
        this.opcoesStatusPedido.push({ label: element.description, value: element.id });
        this.pedido.pedidoStatus.description = element.description;
      }
    });
  }

  loadOperacao(value: Array<OperacaoRegra>) {

    this.opcoesOperacao = [];
    value.forEach(element => {
      if (this.pedido.operacaoRegra) {
        if (element.operacaoRegraContabil !== null && element.id === this.pedido.operacaoRegra.id) {
          this.opcoesOperacao.push({ label: element.cod + ' - ' + element.descricao, value: element.id });
        }
      }

    });
  }

  addLinhaLcto() {

    const listasEnviar = {
      nfe: this.nf.nfeId,
      pedido: this.pedido.id,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/pedido/adicionar-linha-contabil', listasEnviar)
      .subscribe(result => this.sucessoAtualizacaoPedido(),
        error => this.httpService.handleError(error, () => this.addLinhaLcto()),
        () => this.httpService.done());

  }

  addLinhaLctoContabil() {
    const listasEnviar = {
      nfe: this.nf.nfeId,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/pedido/adicionar-linha-contabil-escriturado', listasEnviar)
      .subscribe(result => {

        if (result[0].error === true) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
        } else {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
          this.sucessoAddLinha(this.nf.nfeId);
        }

      },
        error => this.httpService.handleError(error, () => this.addLinhaLctoContabil()),
        () => this.httpService.done());

  }

  sucessoAddLinha(nfeId: any) {
    this.httpService.wait();
    this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + nfeId + ',' +
      this.dblinkedSessionService.empresa.id + ')')
      .subscribe(ref => { this.nfGradeContabil = ref; },
        error => this.httpService.handleError(error, () => this.sucessoAddLinha(nfeId)),
        () => this.httpService.done());
  }

  addLinhaPartidaLcto() {

    const listasEnviar = {
      nfe: this.nf.nfeId,
      pedido: this.pedido.id,
      usuario: this.sessionService.loggedUser.id
    };

    this.httpService.wait();
    this.httpService.post('/custom/pedido/adicionar-linha-partida-contabil', listasEnviar)
      .subscribe(result => this.sucessoAtualizacaoPedido(),
        error => this.httpService.handleError(error, () => this.addLinhaPartidaLcto()),
        () => this.httpService.done());
  }




  cancelarEdicaoLctoContabil() {
    this.itemEscrituracaoContabil = new EscrituracaoEntrada();
    this.itemEscrituracaoContabil.cadCcus = new CadCcus();
    this.itemEscrituracaoContabil.cadProj = new CadProj();
    this.itemEscrituracaoContabil.planoContas = new PlanoContas();
    this.itemEscrituracaoContabil.planoContas.codNaturezaConta = new CodNaturezaConta();
    this.editarEscrituracaoContabil = false;
  }

  atualizarLinhaEscrituracaoContabil() {

    if (!this.visualizarEscrituracao) {
      const listasEnviar = {
        id: this.itemEscrituracaoContabil.id,
        cc: this.itemEscrituracaoContabil.cadCcus.id,
        proj: this.itemEscrituracaoContabil.cadProj.id,
        planoContas: this.itemEscrituracaoContabil.planoContas.id,
        indDc: this.itemEscrituracaoContabil.indDc,
        valor: this.utilService.parseDecimal(this.itemEscrituracaoContabil.vlCc),
        usuario: this.sessionService.loggedUser.id
      };
      this.httpService.wait();
      this.httpService.post('/custom/pedido/atualizar-linha-contabil', listasEnviar)
        .subscribe(result => {
          this.editarEscrituracaoContabil = false;
        },
          error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracaoContabil()),
          () => this.httpService.done());
    } else {
      const listasEnviar = {
        id: this.itemEscrituracaoContabil.id,
        planocontas: this.itemEscrituracaoContabil.planoContas.id,
        indDc: this.itemEscrituracaoContabil.indDc,
        cc: this.itemEscrituracaoContabil.cadCcus.id,
        cadProj: this.itemEscrituracaoContabil.cadProj.id,
        histCompl: this.itemEscrituracaoContabil.histCompl,
        valor: this.utilService.parseDecimal(this.itemEscrituracaoContabil.vlCc),
        usuario: this.sessionService.loggedUser.id
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/atualizar-partida-contabil', listasEnviar)
        .subscribe(result => {


          if (result[0].error === true) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
          }

          this.editarEscrituracaoContabil = false;

          this.escrituracaoContabil = new Array();
          this.httpService.wait();
          this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + this.nf.nfeId + ',' +
            this.dblinkedSessionService.empresa.id + ')')
            .subscribe(ref => {
              ref.forEach(element => {
                this.escrituracaoContabil.push(element);
              });
            },
              error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracaoContabil()),
              () => this.httpService.done());
        },
          error => this.httpService.handleError(error, () => this.atualizarLinhaEscrituracaoContabil()),
          () => this.httpService.done());
    }

  }

  excluirLctoContabil() {
    if (confirm('Deseja realmente excluir este lançamento?')) {

      if (!this.visualizarEscrituracao) {
        this.httpService.wait();
        this.httpService.post('/custom/pedido/excluir-linha-contabil(' + this.itemEscrituracaoContabil.id.toString() + ')')
          .subscribe(result => this.sucessoAtualizacaoPedido(),
            error => this.httpService.handleError(error, () => this.addLinhaLcto()),
            () => this.httpService.done());
      } else {

        this.httpService.wait();
        this.httpService.post('/custom/pedido/excluir-partida-contabil(' + this.itemEscrituracaoContabil.id.toString() + ')')
          .subscribe(result => {


            if (result[0].error === true) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result[0].message });
            } else {
              this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result[0].message });
            }
            this.editarEscrituracaoContabil = false;
            this.editarEscrituracao = false;
            this.exibirDetalhes = false;

            this.escrituracaoContabil = new Array();
            this.httpService.wait();
            this.httpService.get('/custom/notaFiscal/visualizar-contabilizacao(' + this.nf.nfeId + ',' +
              this.dblinkedSessionService.empresa.id + ')')
              .subscribe(ref => {
                ref.forEach(element => {
                  this.escrituracaoContabil.push(element);
                });
              },
                error => this.httpService.handleError(error, () => this.excluirLctoContabil()),
                () => this.httpService.done());

          },
            error => this.httpService.handleError(error, () => this.excluirLctoContabil()),
            () => this.httpService.done());
      }

    }
  }

  /*atualizaFormaPagamento() {

    this.pagamentoForma();


    this.httpService.wait();
    this.httpService.post('/custom/pedido/atualizar-forma-pagamento', listasEnviar)
      .subscribe(result => this.carregarPedido(),
        error => this.httpService.handleError(error, () => this.atualizaFormaPagamento()),
        () => this.httpService.done());

  }*/


  /*atualizarPedido(nome: String, campo: String, idConta: String) {
    this.opcoesContas.forEach(conta => {
      if(conta.value == idConta){
        this.contaSelecionada = conta.label;
      }
    });

    const listasEnviar = {
      pedido: this.pedido.id,
      valor: campo,
      campo: nome,
      usuario: this.sessionService.loggedUser.id
    };
    this.httpService.wait();
    this.httpService.post('/custom/pedido/atualizar-campos-pedido', listasEnviar)
    .subscribe(result => this.carregarPedido(),
        error => this.httpService.handleError(error, () => this.atualizarPedido(nome, campo, idConta)),
        () => this.httpService.done());

  }*/

  editarVencimento(event: any) {
    this.vencimentoParcela = new Date();
    this.parcelaId = event.id;
    this.editarVencimentoParcela = true;
    this.vencimentoParcela = this.utilService.utcDate(event.dtVencimento);

  }

  cancelarEdicaoVencimento() {
    this.editarVencimentoParcela = false;

  }

  comboModBcIcms() {
    this.opcoesModBcIcms = [];
    this.httpService.wait();
    this.httpService.get('/modbcicms?$select=id,codigo,descricao&$orderby=codigo')
      .subscribe(codE => {
        codE.value.forEach(icms => {
          this.opcoesModBcIcms.push({ label: icms.codigo + ' - ' + icms.descricao, value: icms.codigo });
        });
      },
        error => this.httpService.handleError(error, () => this.comboModBcIcms()),
        () => this.httpService.done());
  }

  inicializar() {

    if (this.dblinkedSessionService) {
      if (this.dblinkedSessionService.hasEmpresaSelected &&
        this.dblinkedSessionService.hasPeriodoSelected && this.dblinkedSessionService.periodo.month
        && this.dblinkedSessionService.periodo.year) {
        this.carregarInfos();
        this.xmlSelecionados = new Array()
        this.permiteAlterarRateio = true;
        this.exibirNf = false;
        this.nfSemPedido = false;
        this.todasSelecionadas = false;
        this.statusPendente = true;
        this.statusEscriturada = false;
        this.statusCancelada = false;
        this.aba1 = true;
        this.aba2 = false;
        this.aba3 = false;
        this.aba4 = false;
        this.aba5 = false;
        this.botaoEditar = false;
        this.exibirItemPedido = false;
        this.escritNf = false;
        this.escriturarPedidoSalvo = false;

        this.comboTipoPagamento(true);
        this.alterarPeriodo()
        this.empresaChanged();
        this.inicializarVariaveis();
        this.httpOptions = { headers: new HttpHeaders({ Authorization: this.configService.bearerApi }) }
        this.listarItensParaXml()
        this.getOperacoes(true)
        this.relacaoItemOperNfe = {}
        this.informacoesPagamento = {}
        this.informacoesRateio = new Array()
        this.informacoesAdicionaisXml = {}
        this.getCompanyParameter()

      }
    }
  }

  inicializarVariaveis() {
    this.idPedidosView = new Array();
    this.idPedidosServico = new Array();
    this.idNotasEscrituradas = new Array();

    this.contadorPendente = 0;
    this.contadorEscriturado = 0;
    this.contadorCancelado = 0;

    this.listaPedidosPendentes = new Array();
    this.exibirPedidos = false;
    this.abaSelecionada = 0;
    this.todasSelecionadas = false;
    this.pedido = new Pedido();
    this.pedido.operacaoRegra = new OperacaoRegra();
    this.pedido.pedidoAprovacao = new Array();
    this.pedido.pedidoTipo = new PedidoTipo();
    this.pedido.pedidoTipo.id = 4;
    this.pedido.pedidoItem = new Array();
    this.pedido.modNf = new ModNf();
    this.pedido.tipoIncoterm = new TipoIncoterm();
    this.pedido.moeda = new Moeda();
    this.pedido.pedidoStatus = new PedidoStatus();
    this.pedido.pedidoItem = new Array<PedidoItem>();
    this.pedidoItemEditar.item = new Item();
    this.pedidoItemEditar.cstIpi = new CstIpi();
    this.pedido.pedidoParcela = new Array<PedidoParcela>();
    this.existeLog = false;
    this.modalLog = false;

    this.pedidoValidado = false;
    this.nfSemPedido = false;
    this.nf = '';
    this.nfEscriturada = '';
    this.nfGradeContabil = '';
    this.idNfesBaixa = new Array();
    this.visualizarEscrituracao = false;
    this.dtEntrada = new Date();
    this.pedidoItemEditar = new PedidoItem();
    this.exibirLinha = false;
    this.exibirDetalhes = false;
    this.editarLctoContabil = false;
    this.nfeFinList = new Array();

    this.escrituracaoFiscal = new Array();
    this.escrituracaoContabil = new Array();
    this.infosGerais = new Array();

    this.editarEscrituracao = false;
    this.editarEscrituracaoStatus = false;
    this.linhaEditada = '';
    this.linhaEditadaContabil = '';
    this.editarEscrituracaoContabil = false;

    this.valorTotalPendete = 0.00;
    this.valorTotalEscriturado = 0.00;
    this.valorAtual = 0.00;
    this.valorFiltro = 0.00;

    this.vlBcIcms = 0.00;
    this.vlIcms = 0.00;
    this.vlBcIcmsSt = 0.00;
    this.vlIcmsSt = 0.00;
    this.vlImpostoImportacao = 0.00;
    this.vlIcmsUfRemetente = 0.00;
    this.vlFcp = 0.00;
    this.vlPis = 0.00;
    this.vlProd = 0.00;
    this.vlFrete = 0.00;
    this.vlSeguro = 0.00;
    this.vlDesc = 0.00;
    this.vlOutrasDesp = 0.00;
    this.vlIpi = 0.00;
    this.vlIcmsUfDestino = 0.00;
    this.vlTributos = 0.00;
    this.vlCofins = 0.00;
    this.vlTotal = 0.00;
    this.itemEscrituracaoContabil = new EscrituracaoEntrada();
    this.itemEscrituracaoContabil.cadCcus = new CadCcus();
    this.itemEscrituracaoContabil.planoContas = new PlanoContas();
    this.itemEscrituracaoContabil.planoContas.codNaturezaConta = new CodNaturezaConta();
    this.itemEscrituracaoContabil.cadProj = new CadProj();

    this.partidaLancamentoEditar = new LancamentoPartida();
    this.partidaLancamentoEditar.cadCcus = new CadCcus();
    this.partidaLancamentoEditar.cadProj = new CadProj();
    this.partidaLancamentoEditar.planoContas = new PlanoContas();
    this.editarVencimentoParcela = false;
    this.tab = '';
    this.telaNotaEscriturada = false;
  }

  showTelaNotasFiscais(tipo) {
    this.statusPendente = false;
    this.statusEscriturada = false;
    this.statusCancelada = false;
    this.todasSelecionadas = false;

    this.idNfesBaixa = new Array();
    this.idPedidosView = new Array();
    this.idPedidosServico = new Array();
    this.idNotasEscrituradas = new Array();

    if (tipo === 'TELA_1') {
      this.statusPendente = true;
      this.xml = this.xmlPendente;
    } else if (tipo === 'TELA_2') {
      this.statusEscriturada = true;
      this.xml = this.xmlEscriturado;
    } else if (tipo === 'TELA_3') {
      this.statusCancelada = true;
      this.xml = this.xmlCancelado;
    }

    this.xml.forEach(form => {
      form.selected = false;
    });

    this.toolbarRefreshSelectedRows();
    this.changeToolbar()

  }

  /*Show Hide Card Notas Fiscais*/
  isCardNotasFiscais: boolean = true;
  ShowHIdeNotasFiscais() {
    this.isCardNotasFiscais = !this.isCardNotasFiscais;
  }

  /*Show Hide Card Info Fiscais*/
  isCardInfoFiscais: boolean = true;
  ShowHIdeInfoFiscais() {
    this.isCardInfoFiscais = !this.isCardInfoFiscais;
  }


  realizarImpressao(idPedido: number) {
    this.httpService.wait();
    this.httpService.get('/custom/pedido/impressao-pedido(' + idPedido + ',' +
      false + ',' + true + ')')
      .subscribe(result => this.exportedPedido(result),
        error => this.httpService.handleError(error, () => this.imprimirPedido()),
        () => this.httpService.done());
  }



  exportedPedido(result?: any) {
    if (!result.error) {
      window.open(this.httpService.url('/custom/file/' + result.file + '/pedido'), '_blank');
    } else {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
    }
  }

  showTela(tipo: string) {
    if (tipo === '1') {
      this.aba1 = true;
      this.aba2 = false;
      this.aba3 = false;
      this.aba4 = false;
      this.aba5 = false;
    } else if (tipo === '2') {
      this.aba1 = false;
      this.aba2 = true;
      this.aba3 = false;
      this.aba4 = false;
      this.aba5 = false;
    } else if (tipo === '3') {
      this.aba1 = false;
      this.aba2 = false;
      this.aba3 = true;
      this.aba4 = false;
      this.aba5 = false;
    } else if (tipo === '4') {
      this.aba1 = false;
      this.aba2 = false;
      this.aba3 = false;
      this.aba4 = true;
      this.aba5 = false;
    } else if (tipo === '5') {
      this.aba1 = false;
      this.aba2 = false;
      this.aba3 = false;
      this.aba4 = false;
      this.aba5 = true;
    }

  }

  displayParticipanteDialog(pedidoTipoId: number = null) {

    let participanteTipoId;
    switch (pedidoTipoId) {
      case 1:
      case 2:
        participanteTipoId = 1;
        break;
      case 3:
      case 4:
        participanteTipoId = 2;
        break;
    }
    MAT_DIALOG_CONFIG.data = {
      participanteTipoId: participanteTipoId,
      action: 'CRIAR PEDIDO'
    };
    const matDialogRef = this.matDialog.open(ParticipanteDialogComponent, MAT_DIALOG_CONFIG);
    matDialogRef.afterClosed().subscribe(participante => {
      if (participante) {
        this.pedido = new Pedido();
        this.pedido.id = 0;
        this.pedido.tipoPagamento = 1;
        this.pedido.empresa = new Empresa();
        this.pedido.empresa.id = this.dblinkedSessionService.empresa.id;
        this.pedido.participante = participante;
        this.pedido.participanteEmpresaId = participante.participanteEmpresaId;
        this.pedido.pedidoStatus = new PedidoStatus();
        this.pedido.pedidoStatus.id = 12;
        this.pedido.modNf = new ModNf();
        this.pedido.moeda = new Moeda();
        this.pedido.moeda.id = 1;
        this.pedido.txMoeda = 1.00;
        this.pedido.tipoIncoterm = new TipoIncoterm();
        this.pedido.pedidoItem = new Array();
        this.pedido.pedidoTipo = new PedidoTipo();
        this.pedido.pedidoTipo.id = pedidoTipoId;
        this.pedido.formaPagamento = new FormaPagamento();
        this.pedido.empresaConta = new EmpresaConta();
        this.pedido.converterTaxa = false;
        this.permiteAlterarRateio = true;
        this.pedido.formaPagamento.id = null;
        this.pedido.empresaConta.id = null;
        this.maxParcelas = null;
        this.pedidoVinculado = false;
        this.pedidoSalvo = false;
        this.exibirRateio = false;


        //this.escritNf = true;
        this.novoPedido();
      }
    });
  }

  novoPedido() {
    //this.contador = 1;

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-participante(' + this.dblinkedSessionService.empresa.id + ',' +
      this.pedido.participante.id + ',' +
      (4) + ')')
      .subscribe(info => {
        this.toolbarEscriturarNfs();


        this.infoParticipante = info;
        this.escritNf = true;
        this.permiteAlterarRateio = true;

      },
        error => this.httpService.handleError(error, () => this.novoPedido()),
        () => this.httpService.done());
  }

  carregarInfos() {
    this.opcoesTermo = [];
    this.opcoesTermo.push({ label: 'Chave Eletrônica', value: 'chave' });
    this.opcoesTermo.push({ label: 'Núm Documento', value: 'numDoc' });
    this.opcoesTermo.push({ label: 'Participante', value: 'part' });
    this.opcoesModelo = [];
    this.pedidoService.getModNfs(4, 's', this.opcoesModelo);
    this.opcoesOperacao = [];
    this.pedidoService.getOperacao(4, 's', this.opcoesOperacao, false);
    //this.opcoesItens = [];
    //this.pedidoService.carregarItensCombo(4, 's', this.opcoesItens, 0, false);
    this.opcoesCentroCusto = [];
    this.pedidoService.carregarCentroCusto(this.opcoesCentroCusto);
    this.opcoesProjeto = [];
    this.pedidoService.carregarProjetos(this.opcoesProjeto, 0);
    this.opcoesContas = [];
    this.pedidoService.carregarContas(this.opcoesContas);
    this.opcoesFormaPagamento = [];
    this.pedidoService.carregarFormaPagto(this.opcoesFormaPagamento, 0);
    this.tiposBaixas = [];
    this.pedidoService.carregarTiposBaixa(this.tiposBaixas);
  }

  buscarParticipante() {
    this.valorTotalPedido = 0.00;
    this.displayParticipanteDialog(4);
  }

  vincularPedidoServico() {
    this.exibirPedidos = true;

    this.listaPedidosPendentes = new Array();

    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-pedido-pendente-nfs(' + this.infoParticipante[0].participanteId + ','
      + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(result => {
        result.forEach(element => {
          this.listaPedidosPendentes.push(element);

        });

      }, error => this.httpService.handleError(error, () => this.visualizarPedidos(event)),
        () => this.httpService.done());
  }

  loadItemPedidoEscrit(idPedido: number) {
    this.httpService.wait();
    this.httpService.get('/custom/pedido/buscar-infos-pedido(' + (idPedido) + ')')
      .subscribe(res => {
        this.exibirPedidos = false;

        this.pedido.itemFaturamento = new Item();
        this.pedido.codigo = res.codigo;

        if (this.pedidoVinculado) {
          this.pedido.pedidoVinculado = res.id;
        }

        this.permiteAlterarRateio = res.permiteAlterarRateio;
        this.valorSaldo = this.utilService.parseDecimal(res.valorSaldo);
        if (this.escriturarPedidoSalvo) {
          this.pedido.id = res.id;
          this.pedido.numNf = res.numNf;
          this.pedido.serie = res.serie;
          this.pedido.dtEntrega = res.dtEntrega;
          this.pedido.dtEmissao = res.dtEmissao;

          this.pedido.dtFaturamentoAutomatico = res.dtFaturamentoAutomatico;

          if (!isNullOrUndefined(res.modNf.id)) {

            if (res.modNf.id === 24 || res.modNf.id === 25) {
              this.pedidoService.getModNfs(4, 's', this.opcoesModelo);
              setTimeout(() => {
                this.pedido.modNf = new ModNf();
                this.pedido.modNf.id = res.modNf.id;
              }, 2000);

            } else {
              this.pedidoService.getModNfs(4, 'p', this.opcoesModelo);
              setTimeout(() => {
                this.pedido.modNf = new ModNf();
                this.pedido.modNf.id = res.modNf.id;
              }, 2000);
            }


          } else {
            this.pedido.modNf = new ModNf();
          }

        }

        this.pedido.dtEmissao = res.dtEmissao;
        this.pedido.enderecoCobranca = res.enderecoCobranca;
        this.pedido.enderecoEntrega = res.enderecoEntrega;
        this.pedido.dadosAdicionais = res.dadosAdicionais;
        this.pedido.codigoOrcamento = res.codigoOrcamento;
        this.pedido.codigoControle = res.codigoControle;
        this.pedido.valorInss = this.utilService.parseDecimal(res.valorInss);
        this.pedido.valorIr = this.utilService.parseDecimal(res.valorIr);
        this.pedido.valorCsll = this.utilService.parseDecimal(res.valorCsll);
        this.pedido.valorPis = this.utilService.parseDecimal(res.valorPis);
        this.pedido.valorCofins = this.utilService.parseDecimal(res.valorCofins);
        this.pedido.valorPisFaturamento = this.utilService.parseDecimal(res.valorPisFaturamento);
        this.pedido.valorCofinsFaturamento = this.utilService.parseDecimal(res.valorCofinsFaturamento);
        this.pedido.valorIssRetido = this.utilService.parseDecimal(res.valorIssRetido);
        this.pedido.valorIss = this.utilService.parseDecimal(res.valorIss);
        this.pedido.valorOutrasRetencoes = this.utilService.parseDecimal(res.valorOutrasRetencoes);
        this.pedido.txMoeda = this.utilService.parseDecimal6(res.txMoeda);
        this.pedido.numeroInvoice = res.numeroInvoice;
        this.pedido.dataInvoice = res.dataInvoice;
        this.pedido.dataInicial = res.dataInicial;
        this.pedido.dataFinal = res.dataFinal;
        //this.codigoMoeda = res.moeda.codigo;
        this.pedido.converterTaxa = res.converterTaxa;
        this.pedido.itemFaturamento.id = res.itemFaturamento;
        this.pedido.dataUltimaAtualizacao = res.dataAtualizacao;


        this.pedido.participante = new Participante();
        this.pedido.participante = res.participante;

        this.pedido.pedidoTipo = new PedidoTipo();
        this.pedido.pedidoTipo.id = 4;



        this.pedido.pedidoStatus = new PedidoStatus();
        this.pedido.pedidoStatus.id = 12;

        if (!isNullOrUndefined(res.moeda)) {
          this.pedido.moeda = new Moeda();
          this.pedido.moeda = res.moeda;
        } else {
          this.pedido.moeda = new Moeda();
        }

        if (!isNullOrUndefined(res.tipoIncoterm)) {
          this.pedido.tipoIncoterm = new TipoIncoterm();
          this.pedido.tipoIncoterm = res.tipoIncoterm;
        } else {
          this.pedido.tipoIncoterm = new TipoIncoterm();
        }

        if (!isNullOrUndefined(res.formaPagamento)) {
          this.pedido.formaPagamento = new FormaPagamento();
          this.pedido.formaPagamento = res.formaPagamento;
        } else {
          this.pedido.formaPagamento = new FormaPagamento();
        }

        if (!isNullOrUndefined(res.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta = res.empresaConta;
        } else {
          this.pedido.empresaConta = new EmpresaConta();
        }

        if (!isNullOrUndefined(res.empresa)) {
          this.pedido.empresa = new Empresa();
          this.pedido.empresa = res.empresa;
        } else {
          this.pedido.empresa = new Empresa();
        }

        if (isNullOrUndefined(res.tipoPagamento)) {
          this.pedido.tipoPagamento = 1;
        } else {
          this.pedido.tipoPagamento = res.tipoPagamento;
        }

        /*this.pedido.pedidoParcela = new Array();
        res.pedidoParcela.forEach(parcela => {
          let pedParcela = new PedidoParcela();
          if(this.pedidoSalvo){
            pedParcela.valor = this.utilService.parseDecimal(parcela.valor);
          }else{
            pedParcela.valor = this.utilService.parseDecimal(0.00);
          }
          pedParcela.dtVencimento = this.utilService.dateWithoutTimeZone(parcela.dtVencimento);
          pedParcela.parcela = parcela.parcela;

          pedParcela.historico = parcela.historico;

          if(!isNullOrUndefined(parcela.tipoBaixa)){
            pedParcela.tipoBaixa = parcela.tipoBaixa;
          }

          this.pedido.pedidoParcela.push(pedParcela);
        });*/



        this.pedido.pedidoItem = new Array();


        res.pedidoItens.forEach(pedItem => {

          let pedidoItem = new PedidoItem();

          pedidoItem.id = pedItem.id;

          if (!isNullOrUndefined(pedItem.tipoDesconto)) {
            pedidoItem.tipoDesconto = new TipoDesconto();
            pedidoItem.tipoDesconto = pedItem.tipoDesconto;
          } else {
            pedidoItem.tipoDesconto = new TipoDesconto();
          }

          if (!isNullOrUndefined(pedItem.item)) {
            pedidoItem.item = new Item();
            pedidoItem.item = pedItem.item;
          } else {
            pedidoItem.item = new Item();
          }

          if (!isNullOrUndefined(pedItem.operacaoRegra)) {
            pedidoItem.operacaoRegra = new OperacaoRegra();
            pedidoItem.operacaoRegra = pedItem.operacaoRegra;
          } else {
            pedidoItem.operacaoRegra = new OperacaoRegra();
          }

          pedidoItem.quantidade = pedItem.quantidade;
          pedidoItem.valorUnit = pedItem.valorUnit;
          pedidoItem.valorDesconto = pedItem.valorDesconto;
          pedidoItem.valorTotal = pedItem.valorTotal;
          pedidoItem.valorIss = pedItem.valorIss;
          pedidoItem.valorIssRet = pedItem.valorIssRet;

          pedidoItem.pedidoItensRateio = new Array();

          this.somaRateio = 0.00;
          pedItem.pedidoItensRateio.forEach(rat => {

            this.somaRateio = this.somaRateio + rat.porcentagem;
            let itemRateio = new PedidoItemRateio();


            itemRateio.porcentagem = rat.porcentagem;

            if (!isNullOrUndefined(rat.cadCcus)) {
              itemRateio.cadCcus = new CadCcus();
              itemRateio.cadCcus = rat.cadCcus;
            } else {
              itemRateio.cadCcus = new CadCcus();
            }

            if (!isNullOrUndefined(rat.cadProj)) {
              itemRateio.cadProj = new CadProj();
              itemRateio.cadProj = rat.cadProj;
            } else {
              itemRateio.cadProj = new CadProj();
            }
            itemRateio.id = rat.id;
            pedidoItem.pedidoItensRateio.push(itemRateio);

          });

          if (this.somaRateio < 100) {
            let itemRateio = new PedidoItemRateio();
            itemRateio.cadCcus = new CadCcus();
            itemRateio.cadProj = new CadProj();
            pedidoItem.pedidoItensRateio.push(itemRateio);
          }
          this.pedido.pedidoItem.push(pedidoItem);
        });

        this.escrituracaoContabil = new Array();
        this.visualizacaoFiscal = new Array();

        const listasEnviar = {
          pedido: JSON.stringify(this.pedido, null, ''),
          empresa: this.dblinkedSessionService.empresa.id,
        };

        this.pedido.valorInss = 0.00;
        this.pedido.valorIr = 0.00;
        this.pedido.valorCsll = 0.00;
        this.pedido.valorPis = 0.00;
        this.pedido.valorCofins = 0.00;
        this.pedido.valorIssRetido = 0.00;
        this.pedido.valorIss = 0.00;
        this.pedido.valorOutrasRetencoes = 0.00;
        this.pedido.valorPisFaturamento = 0.00;
        this.pedido.valorCofinsFaturamento = 0.00;

        this.httpService.wait();
        this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
          .subscribe(res => {
            res.value.forEach(element => {

              this.pedido.pedidoItem.forEach(pedidoItem => {
                if (element.pedidoItemid === pedidoItem.id) {
                  pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
                  pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
                  pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
                  pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                  pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                  pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
                  pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
                  pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
                  pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
                }
              });


              this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));
              this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
              this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
              this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
              this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
              this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
              this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
              this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
              this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));
              this.pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
              this.visualizacaoFiscal.push(element);


            });

            setTimeout(() => {
              this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);
              this.atualizarSubtotal();
              this.calculaValorParcelas();
              this.rateioItem(this.pedido.pedidoItem[0]);
            }, 500);
          }, error => this.httpService.handleError(error, () => this.loadItemPedidoEscrit(idPedido),
            () => this.httpService.done()));
      },
        error => this.httpService.handleError(error, () => this.loadItemPedidoEscrit(idPedido)),
        () => this.httpService.done());
  }

  atualizarSubtotal() {

    if (this.pedido.modNf.id === 25 || this.pedido.modNf.id === 24) {

      this.subTotalPedido = this.utilService.parseMoneyString(0.00);
      this.vlDesc = this.utilService.parseMoneyString(0.00);
      this.valorImpostosRetidos = this.utilService.parseMoneyString(0.00);

      this.pedido.pedidoItem.forEach(element => {

        this.subTotalPedido =
          this.utilService.parseDecimalString(
            this.utilService.parseDecimal6(this.subTotalPedido) +
            (this.utilService.parseDecimal6(element.valorUnit) * this.utilService.parseDecimal6(element.quantidade))
          );

        this.vlDesc = this.utilService.parseMoneyString(
          this.utilService.parseDecimal(this.vlDesc) + this.utilService.parseDecimal(element.valorDesconto)
        );

      });

      this.valorImpostosRetidos =
        this.utilService.parseDecimalString(
          this.utilService.parseDecimal(this.valorImpostosRetidos) + this.utilService.parseDecimal(this.pedido.valorInss) +
          this.utilService.parseDecimal(this.pedido.valorIr) + this.utilService.parseDecimal(this.pedido.valorCsll) +
          this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(this.pedido.valorCofins) +
          this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes)
        );


      this.valorTotalPedido = this.utilService.parseDecimal(this.subTotalPedido)
        - this.utilService.parseDecimal(this.vlDesc)
        - this.utilService.parseDecimal(this.valorImpostosRetidos);

      this.pagamentoForma(true);

    } else {

      this.subTotalPedido = 0.00;
      this.vlBcIcms = 0.00;
      this.vlIcms = 0.00;
      this.vlBcIcmsSt = 0.00;
      this.vlIcmsSt = 0.00;
      this.vlImpostoImportacao = 0.00;
      this.vlIcmsUfRemetente = 0.00;
      this.vlIcmsUfDestino = 0.00;
      this.vlTributos = 0.00;
      this.vlFcp = 0.00;
      this.vlProd = 0.00;
      this.vlFrete = 0.00;
      this.vlSeguro = 0.00;
      this.vlDesc = 0.00;
      this.vlOutrasDesp = 0.00;
      this.vlIpi = 0.00;
      this.vlPis = 0.00;
      this.vlCofins = 0.00;
      this.vlTotal = 0.00;

      this.infosGerais.forEach(element => {

        this.vlBcIcms = this.utilService.parseDecimal(this.vlBcIcms) + this.utilService.parseDecimal(element.vlBcIcms);
        this.vlIcms = this.utilService.parseDecimal(this.vlIcms) + this.utilService.parseDecimal(element.vlIcms);
        this.vlBcIcmsSt = this.utilService.parseDecimal(this.vlBcIcmsSt) + this.utilService.parseDecimal(element.vlBcIcmsSt);
        this.vlIcmsSt = this.utilService.parseDecimal(this.vlIcmsSt) + this.utilService.parseDecimal(element.vlIcmsSt);
        this.vlImpostoImportacao = this.utilService.parseDecimal(this.vlImpostoImportacao)
          + this.utilService.parseDecimal(element.vlIi);
        this.vlIcmsUfRemetente = this.utilService.parseDecimal(this.vlIcmsUfRemetente) + this.utilService.parseDecimal(0.00);
        this.vlIcmsUfDestino = this.utilService.parseDecimal(this.vlIcmsUfDestino) + this.utilService.parseDecimal(0.00);
        this.vlTributos = this.utilService.parseDecimal(this.vlTributos) + this.utilService.parseDecimal(0.00);
        this.vlFcp = this.utilService.parseDecimal(this.vlFcp) + this.utilService.parseDecimal(element.vlFcp);
        this.vlProd = this.utilService.parseDecimal(this.vlProd) + this.utilService.parseDecimal(element.vlProd);
        this.vlFrete = this.utilService.parseDecimal(this.vlFrete) + this.utilService.parseDecimal(element.vlFrete);
        this.vlSeguro = this.utilService.parseDecimal(this.vlSeguro) + this.utilService.parseDecimal(element.vlSeguro);
        this.vlDesc = this.utilService.parseDecimal(this.vlDesc) + this.utilService.parseDecimal(element.vlDesc);
        this.vlOutrasDesp = this.utilService.parseDecimal(this.vlOutrasDesp) + this.utilService.parseDecimal(element.vlOutro);
        this.vlIpi = this.utilService.parseDecimal(this.vlIpi) + this.utilService.parseDecimal(element.vlIpi);
        this.vlPis = this.utilService.parseDecimal(this.vlPis) + this.utilService.parseDecimal(element.vlPis);
        this.vlCofins = this.utilService.parseDecimal(this.vlCofins) + this.utilService.parseDecimal(element.vlCofins);
        this.vlTotal = this.utilService.parseDecimal(this.vlTotal) + this.utilService.parseDecimal(element.vlTot);
      });

      this.vlTotal = (this.utilService.parseDecimal6(this.vlProd) + this.utilService.parseDecimal6(this.subTotalPedido) +
        this.utilService.parseDecimal(this.vlIcmsSt) +
        this.utilService.parseDecimal(this.vlFrete) + this.utilService.parseDecimal(this.vlSeguro) +
        this.utilService.parseDecimal(this.vlOutrasDesp) + this.utilService.parseDecimal(this.vlIpi))
        - this.vlDesc;

      //this.vlTotal = this.utilService.parseDecimal(this.vlTotal);

      this.pagamentoForma(true);
    }



  }

  comboTipoPagamento(geraFinanceiro) {
    this.opcoesTipoPagamento = [];
    if (geraFinanceiro) {
      this.opcoesTipoPagamento.push({ label: 'SELECIONE O TIPO DE PAGAMENTO', value: 0 });
      this.opcoesTipoPagamento.push({ label: 'BANCO', value: 1 });
    } else {
      this.opcoesTipoPagamento.push({ label: 'SELECIONE O TIPO DE PAGAMENTO', value: 0 });
      this.opcoesTipoPagamento.push({ label: 'BANCO', value: 1 });
      this.opcoesTipoPagamento.push({ label: 'COMPENSAÇÃO ADIANTAMENTO', value: 2 });
    }
  }

  excluirItemLista(pedidoItemId: any) {
    if (confirm('Deseja realmente excluir este Item?')) {
      //this.exibirItemPedido = false;

      const index = this.pedido.pedidoItem.findIndex(c => c.id === pedidoItemId);
      this.pedido.pedidoItem.splice(index, 1);


      this.pedido.valorInss = this.utilService.parseDecimalString(0.00);
      this.pedido.valorIr = this.utilService.parseDecimalString(0.00);
      this.pedido.valorCsll = this.utilService.parseDecimalString(0.00);
      this.pedido.valorPis = this.utilService.parseDecimalString(0.00);
      this.pedido.valorCofins = this.utilService.parseDecimalString(0.00);
      this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(0.00);
      this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(0.00);
      this.pedido.valorIssRetido = this.utilService.parseDecimalString(0.00);
      this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(0.00);
      this.pedido.valorIss = this.utilService.parseDecimalString(0.00);


      this.visualizacaoFiscal = new Array();
      this.escrituracaoContabil = new Array();

      const listasEnviar = {
        pedido: JSON.stringify(this.pedido, null, ''),
        empresa: this.dblinkedSessionService.empresa.id,
      };

      this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
        .subscribe(res => {

          res.value.forEach(element => {
            this.pedido.valorInss = this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss);
            this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
            this.pedido.valorCsll = this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll);
            this.pedido.valorPisFaturamento = this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis);
            this.pedido.valorCofinsFaturamento = this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins);
            this.pedido.valorPis = this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet);
            this.pedido.valorCofins = this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet);
            this.pedido.valorIssRetido = this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet);
            this.pedido.valorOutrasRetencoes = this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes);
            this.pedido.valorIss = this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss);
            this.visualizacaoFiscal.push(element);

            this.pedido.pedidoItem.forEach(pedidoItem => {
              if (element.pedidoItemid === pedidoItem.id) {
                pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
                pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
                pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
                pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
                pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
                pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
                pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
                pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
              }
            });
          });

          //this.formaPgtoCalcular(true);
          this.pagamentoForma(true);


        }, error => this.httpService.handleError(error, () => this.excluirItemLista(pedidoItemId)),
          () => this.httpService.done());
    }
  }

  formaPgtoCalcular(calcular: boolean) {

    this.pedidoExterior = false;

    // botão calcular é acionado === true
    if (calcular) {
      if (this.primeroVencimento === null || this.primeroVencimento === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione a data do primeiro vencimento para prosseguir!'
        });
        return;
      }
      if (this.qtdParcelas === null || (this.qtdParcelas < 0)) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Informe a quantidade de parcelas para prosseguir!'
        });
        return;
      } else {
        if (!isNullOrUndefined(this.maxParcelas) && this.qtdParcelas > this.maxParcelas) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Quantidade de parcelas ultrapassa o número máximo da forma de pagamento!'
          });
          return;
        }
      }

      this.calculaParcelasPgto = false;
      this.pedido.pedidoParcela = new Array();
      this.valorTotalPedido = this.utilService.parseDecimal(this.vlTotal);
      this.valorTotalFormasPagtoTela = this.vlTotal;

      this.pedidoParcela = new PedidoParcela();
      this.pedidoParcela.tipoBaixa = new TipoBaixa();

      let vencimento = new Date(this.primeroVencimento);
      const dia = vencimento.getDate();
      if (this.qtdParcelas === 0) {
        // pegando próximo vencimento - considera mais 1 dia
        // vencimento = new Date(vencimento.getTime() + 1 * 24 * 60 * 60 * 1000);

        this.pedidoParcela.id = 1;
        this.pedidoParcela.parcela = 1;
        this.pedidoParcela.dtVencimento = vencimento;
        this.pedidoParcela.valor = this.valorTotalFormasPagtoTela;
        this.pedidoParcela.historico = '';
        this.pedidoParcela.tipoBaixa = new TipoBaixa();
        this.pedidoParcela.tipoBaixa.id = 0;
        this.pedidoParcela.tipoBaixa.descricao = '';
        //this.pedido.pedidoParcela.push(this.pedidoParcela);

      } else {

        let vlrParcela = Number((this.valorTotalFormasPagtoTela / this.qtdParcelas).toFixed(2));
        let countVlrParcelas = 0.00;
        this.valorTotalParcelas = this.valorTotalFormasPagtoTela;

        for (let parcela = 1; parcela <= this.qtdParcelas; parcela++) {
          this.pedidoParcela = new PedidoParcela();
          this.pedidoParcela.tipoBaixa = new TipoBaixa();

          let mes = null;
          // pegando próximo vencimento - considera 30 dias
          if (vencimento.getMonth() === 0 && dia > 28 && parcela > 1) {
            // proximo é fevereiro
            vencimento = (new Date(vencimento.getTime() + 28 * 24 * 60 * 60 * 1000));
            const lastDayFev = new Date(vencimento.getFullYear(), vencimento.getMonth() + 1, 0); // ultimo dia do mês
            vencimento = lastDayFev;
            mes = vencimento.getMonth(); // mes da parcela
          } else {

            mes = vencimento.getMonth(); // mes da parcela anterior
            vencimento = ((parcela === 1) ? vencimento : new Date(vencimento.getTime() + 30 * 24 * 60 * 60 * 1000));
            // se não trocou de mes
            if (dia === 1 && mes === vencimento.getMonth() && parcela > 1) {
              // acrescentar mais um dia se não trocou de mÊs
              vencimento = new Date(vencimento.getTime() + (1 * 24 * 60 * 60 * 1000));
            }
            mes = vencimento.getMonth(); // mes da parcela
            vencimento.setDate(dia); // dia de vencimento
          }

          let lastDay = vencimento;
          // validando se é no mes corrente ou o set dia trocou de mes
          if (mes !== vencimento.getMonth()) {
            lastDay = new Date(vencimento.getTime() - (24 * 60 * 60 * 1000));
          }
          // total das parcelas
          countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

          // validando qtd parcelas -> pegar ultima
          if (parcela === this.qtdParcelas) {
            // calculando diferença
            const diference = Number((this.valorTotalFormasPagtoTela - countVlrParcelas).toFixed(2));
            // total das parcelas
            countVlrParcelas += this.utilService.parseDecimal(vlrParcela);

            // descontando ou somando a diferenca.
            vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
          }

          this.pedidoParcela.id = parcela;
          this.pedidoParcela.parcela = parcela;
          this.pedidoParcela.dtVencimento = lastDay;
          this.pedidoParcela.valor = this.utilService.parseDecimal(vlrParcela);
          this.pedidoParcela.historico = '';
          this.pedidoParcela.tipoBaixa = new TipoBaixa();
          this.pedidoParcela.tipoBaixa.id = 0;
          this.pedidoParcela.tipoBaixa.descricao = '';

          this.pedido.pedidoParcela.push(this.pedidoParcela);

        }
      }
    } else if (this.pedido.pedidoParcela) { // novo item é adicionado ao pedido.
      this.qtdParcelas = this.pedido.pedidoParcela.length;
      let parcela = 0;
      let vlrParcela = Number((this.utilService.parseDecimal(this.valorTotalFormasPagtoTela) / this.qtdParcelas).toFixed(2));
      this.valorTotalParcelas = 0.00;
      this.pedido.pedidoParcela.forEach(parc => {
        // total das parcelas
        this.valorTotalParcelas += this.utilService.parseDecimal(vlrParcela);
        // validando qtd parcelas -> pegar ultima e calcular diferenças
        if (parcela === this.qtdParcelas) {
          // calculando diferença
          const diference = Number((this.valorTotalPedido - this.valorTotalParcelas).toFixed(2));
          // descontando ou somando a diferenca.
          vlrParcela = (diference > 0 ? (vlrParcela + diference) : (diference < 0 ? (vlrParcela = vlrParcela + diference) : vlrParcela));
        }
        // atualizando o valor das parcelas - 21.03
        if (this.utilService.parseDecimal(parc.valor) > 0.00 && this.calculaParcelasPgto === false) {
          parc.valor = this.utilService.parseDecimal(parc.valor);
        } else {
          parc.valor = this.utilService.parseDecimal(vlrParcela);
        }
        parcela++;
      });
      this.calculaParcelasPgto = false;
      this.qtdParcelas = null;
    }
  }


  rateioItem(value: any) {
    this.pedidoItem = value;
    this.exibirRateio = true;


    if (this.pedidoItem.pedidoItensRateio.length === 0) {
      let itemRateio = new PedidoItemRateio();
      itemRateio.cadCcus = new CadCcus();
      itemRateio.cadProj = new CadProj();

      itemRateio.id = this.pedidoItem.pedidoItensRateio.length;
      this.pedidoItem.pedidoItensRateio.push(itemRateio);
    }

    this.escrituracaoContabil = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);

  }

  adicionarRateio() {
    let itemRateio = new PedidoItemRateio();
    itemRateio.cadCcus = new CadCcus();
    itemRateio.cadProj = new CadProj();

    itemRateio.id = this.pedidoItem.pedidoItensRateio.length;
    this.pedidoItem.pedidoItensRateio.push(itemRateio);

    this.escrituracaoContabil = new Array();
    this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);

  }

  replicarRateio() {
    if (confirm('Este Rateio será replicado para todos os outros itens, deseja continuar?')) {
      this.pedido.pedidoItem.forEach(itens => {

        if (itens.id !== this.pedidoItem.id) {

          itens.pedidoItensRateio = new Array();
          this.pedidoItem.pedidoItensRateio.forEach(element => {

            let req = new PedidoItemRateio();
            req.cadCcus = new CadCcus();
            req.cadCcus.descrCcus = element.cadCcus.descrCcus;
            req.cadCcus.codCcus = element.cadCcus.codCcus;
            req.cadCcus.id = element.cadCcus.id;
            req.cadProj = new CadProj();

            if (element.cadProj) {
              req.cadProj.id = element.cadProj.id;
              req.cadProj.codProj = element.cadProj.codProj;
              req.cadProj.descrProj = element.cadProj.descrProj;
            }

            this.modalCentroCusto = false;
            req.porcentagem = element.porcentagem;
            itens.pedidoItensRateio.push(req);
          });

          this.escrituracaoContabil = new Array();
          this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);

        }
      });
    }
  }

  removerRateioCentroCusto(value: any) {
    if (this.permiteAlterarRateio) {
      if (confirm('Deseja excluir este rateio?')) {
        const index = this.pedidoItem.pedidoItensRateio.findIndex(it => it.id === value);
        this.pedidoItem.pedidoItensRateio.splice(index, 1);
        this.calculaRateioDisponivelCentroCusto();
      }

      this.escrituracaoContabil = new Array();
      this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);
    }

  }

  calculaRateioDisponivelCentroCusto() {
    if (this.permiteAlterarRateio) {


      let rateio: number;
      rateio = 0.00;
      this.rateioCentroCusto = 100;
      if (this.pedidoItem) {

        this.pedidoItem.pedidoItensRateio.forEach(element => {
          element.porcentagem = this.utilService.parseMoney(element.porcentagem);
          rateio = this.utilService.parseMoney(rateio) + this.utilService.parseMoney(element.porcentagem);
          this.rateioCentroCusto = 100 - this.utilService.parseMoney(rateio);
        });
      }
    }
  }

  retornaIdCentroDeCusto(cadCustoId, rateio) {
    if (this.permiteAlterarRateio) {

      if (!isNullOrUndefined(cadCustoId)) {

        if (this.centroCustoId !== cadCustoId) {
          this.opcoesProjeto = [];
          this.centroCustoId = cadCustoId;
          this.pedidoService.carregarProjetos(this.opcoesProjeto, this.centroCustoId);
        }
      } else {
        this.centroCustoId = 0;
      }

      if (!isNullOrUndefined(rateio)) {
        if (!isNullOrUndefined(rateio.cadProj.id)) {
          rateio.cadProj.id = null;
          rateio.cadProj.codProj = null;
          rateio.cadProj.descrProj = null;
        }
      }
    }


  }

  itemOnRowSelect(event: any) {

    this.pedidoItensRateioEdicao = event.pedidoItensRateio;

    this.exibirItemPedido = true;
    this.botaoEditar = true;
    this.calculaParcelasPgto = true;
    this.pedidoItem = new PedidoItem(event.data);
    this.pedidoItem.id = event.id;
    this.pedidoItem.tipoDesconto = new TipoDesconto();
    this.pedidoItem.operacaoRegra = new OperacaoRegra();
    this.pedidoItem.operacaoRegra.id = event.operacaoRegra.id;
    this.pedidoItem.operacaoRegra.cod = event.operacaoRegra.cod;
    this.pedidoItem.operacaoRegra.descricao = event.operacaoRegra.descricao;
    this.pedidoItem.item = new Item();
    this.pedidoItem.item.id = event.item.id;
    this.pedidoItem.item.codigo = event.item.codigo;
    this.pedidoItem.item.descricao = event.item.descricao;
    this.pedidoItem.quantidade = event.quantidade;
    this.pedidoItem.valorUnit = this.utilService.parseDecimalString6(this.utilService.parseDecimal6(event.valorUnit));
    this.pedidoItem.valorDesconto = this.utilService.parseDecimalString(event.valorDesconto);
    this.calcularValorTotal();
  }

  alterarItemLista(pedidoItemId: any) {

    if (this.utilService.parseDecimal(this.pedidoItem.valorTotal) > this.valorSaldo) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor Total do item ultrapassa o Valor do Saldo de ' + this.utilService.parseDecimalString(this.valorSaldo) + ', por favor verifique!'
      });
      return;
    }

    // alterando o item selecionado
    const index = this.pedido.pedidoItem.findIndex(c => c.id === pedidoItemId);
    this.pedido.pedidoItem.splice(index, 1);

    this.botaoEditar = false;
    this.salvarItemLista(pedidoItemId);
  }

  salvarItemLista(pedidoItemId) {
    if (this.pedidoItem.item.id === null || this.pedidoItem.item.id === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Selecione um item para prosseguir!'
      });
      return;
    } else if (this.pedidoItem.quantidade === null) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe a quantidade do Item!'
      });
      return;
    } else if (this.pedidoItem.valorUnit === null) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe o valor unitário do Item!'
      });
      return;
    } else if (this.pedidoItem.operacaoRegra.id === null || this.pedidoItem.operacaoRegra.id === undefined) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Informe a Operação!'
      });
      return;
    }

    this.escrituracaoContabil = new Array();
    this.visualizacaoFiscal = new Array();

    this.pedido.pedidoItem.push(this.pedidoItem);
    this.exibirItemPedido = false;
    this.pedidoItem.numSequence = this.contador;

    if (isNullOrUndefined(pedidoItemId)) {
      this.pedidoItem.id = this.pedido.pedidoItem.length;
    } else {
      this.pedidoItem.id = pedidoItemId;
    }

    this.pedidoItem.pedidoItensRateio = new Array();

    this.pedidoItensRateioEdicao.forEach(element => {

      let pedidoItemRateio = new PedidoItemRateio();
      pedidoItemRateio.cadCcus = new CadCcus();
      pedidoItemRateio.cadCcus.id = element.cadCcus.id;
      pedidoItemRateio.cadCcus.codCcus = element.cadCcus.codCcus;
      pedidoItemRateio.cadCcus.descrCcus = element.cadCcus.descrCcus;
      if (!isNullOrUndefined(element.cadProj)) {

        pedidoItemRateio.cadProj = new CadProj();
        pedidoItemRateio.cadProj.id = element.cadProj.id;
        pedidoItemRateio.cadProj.codProj = element.cadProj.codProj;
        pedidoItemRateio.cadProj.descrProj = element.cadProj.descrProj;
      }

      pedidoItemRateio.porcentagem = element.porcentagem;
      this.pedidoItem.pedidoItensRateio.push(pedidoItemRateio);
    });



    //this.pedidoService.buscarPreviaFiscal(this.pedido, this.visualizacaoFiscal);

    const listasEnviar = {
      pedido: JSON.stringify(this.pedido, null, ''),
      empresa: this.dblinkedSessionService.empresa.id,
    };

    this.pedido.valorInss = 0.00;
    this.pedido.valorIr = 0.00;
    this.pedido.valorCsll = 0.00;
    this.pedido.valorPis = 0.00;
    this.pedido.valorCofins = 0.00;
    this.pedido.valorIssRetido = 0.00;
    this.pedido.valorIss = 0.00;
    this.pedido.valorIssRetido = 0.00;
    this.pedido.valorOutrasRetencoes = 0.00;
    this.pedido.valorPisFaturamento = 0.00;
    this.pedido.valorCofinsFaturamento = 0.00;

    this.httpService.post('/custom/pedido/buscar-impostos-pedido', listasEnviar)
      .subscribe(res => {
        res.value.forEach(element => {

          this.pedido.pedidoItem.forEach(pedidoItem => {
            if (element.pedidoItemid === pedidoItem.id) {
              pedidoItem.valorCide = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCide));
              pedidoItem.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofins));
              pedidoItem.valorCofinsRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCofinsRet));
              pedidoItem.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
              pedidoItem.valorSimples = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorCsll));
              pedidoItem.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPis));
              pedidoItem.valorPisRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorPisRet));
              pedidoItem.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIss));
              pedidoItem.valorIssRet = this.utilService.parseDecimalString(this.utilService.parseDecimal(element.valorIssRet));
            }
          });


          this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss) + this.utilService.parseDecimal(element.valorInss));

          this.pedido.valorIr = this.utilService.parseDecimal(this.pedido.valorIr) + (element.temIrrf ? this.utilService.parseDecimal(element.valorIrrf) : 0);
          this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll) + this.utilService.parseDecimal(element.valorCsll));
          this.pedido.valorPisFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPisFaturamento) + this.utilService.parseDecimal(element.valorPis));
          this.pedido.valorCofinsFaturamento = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofinsFaturamento) + this.utilService.parseDecimal(element.valorCofins));
          this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis) + this.utilService.parseDecimal(element.valorPisRet));
          this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins) + this.utilService.parseDecimal(element.valorCofinsRet));
          this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido) + this.utilService.parseDecimal(element.valorIssRet));
          this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes) + this.utilService.parseDecimal(element.valorOutrasRetencoes));
          this.pedido.valorIss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIss) + this.utilService.parseDecimal(element.valorIss));
          this.visualizacaoFiscal.push(element);

          setTimeout(() => {
            this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);
          }, 500);

        });

        setTimeout(() => {
          this.pedidoService.buscarPreviaContabil(this.pedido, this.escrituracaoContabil);
        }, 500);
      }, error => this.httpService.handleError(error, () => this.salvarItemLista(pedidoItemId)),
        () => this.httpService.done());

    setTimeout(() => {
      this.atualizarSubtotal();
    }, 1000);

    this.pagamentoForma(true);


    this.contador++;
    this.botaoEditar = false;
  }

  calcularValorTotal() {
    this.pedidoItem.valorTotal = this.utilService.parseDecimal(this.utilService.parseDecimal6(this.pedidoItem.valorUnit) * this.utilService.parseDecimal6(this.pedidoItem.quantidade)) - this.utilService.parseDecimal6(this.pedidoItem.valorDesconto);
    this.pedidoItem.valorTotal = this.utilService.parseDecimalString(this.pedidoItem.valorTotal);
  }

  escriturarNfPedido() {
    if (confirm('Deseja realmente escriturar essa NF?')) {

      let permite = true;


      this.pedido.pedidoItem.forEach(item => {

        let porcentagem = 0;
        item.pedidoItensRateio.forEach(rat => {

          porcentagem = this.utilService.parseDecimal(porcentagem) + this.utilService.parseDecimal(rat.porcentagem);
          if (rat.cadProj.id && !rat.cadCcus.id) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O Rateio do Item: ' + item.item.codigo + ' está com projeto sem centro de custo relacionado, verifique!'
            });
            permite = false;
            return;
          }

          if (rat.cadCcus.id && (porcentagem === 0 || isNullOrUndefined(porcentagem))) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O Rateio do Item: ' + item.item.codigo + ' está com centro de custo sem porcentagem informada!'
            });
            permite = false;
            return;
          }

          if (rat.cadCcus.projetosRelacionados && isNullOrUndefined(rat.cadProj.id)) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: 'O Rateio ' + (item.pedidoItensRateio.indexOf(rat) + 1) + ' do Item: ' + item.item.codigo + ' está sem projeto relacionado, verifique!'
            });
            permite = false;
            return;
          }

        });



        if ((porcentagem < 100.00 || porcentagem > 100.00) && porcentagem > 0.00) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O Rateio do Item: ' + item.item.codigo + ' está com ' + porcentagem + '%, deve ser 100%, verifique!'
          });
          permite = false;
          return;
        }
      });


      if ((this.pedido.numNf === null || this.pedido.numNf === undefined || this.pedido.numNf.trim() === '')) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Informe o número do documento para prosseguir!'
        });
        return;

      }

      if (isNullOrUndefined(this.pedido.modNf.id)) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Selecione o Modelo para Prosseguir!'
        });
        return;
      }
      if (this.pedido.dtEmissao === null || this.pedido.dtEmissao === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione a data da emissão para prosseguir!'
        });
        return;
      }
      if (this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Selecione a data da Entrada para prosseguir!'
        });
        return;
      }

      if (isNullOrUndefined(this.pedido.pedidoItem) || this.pedido.pedidoItem.length === 0) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Para prosseguir, Insira um Item no Pedido!'
        });
        return;
      }

      if (this.utilService.parseDecimal(this.valorTotalPedido) <= 0.00) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Valor Total do pedido não pode ser menor ou igual a Zero!'
        });
        return;
      }

      const listasEnviar = {
        pedido: JSON.stringify(this.pedido, null, ''),
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        escriturar: true,
        status: 'escriturar',
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/escriturar-nf-pedido', listasEnviar)
        .subscribe(result => {



          if (result.error) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: result.message
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: result.message
            });
            this.empresaChanged();

          }
        },
          error => this.httpService.handleError(error, () => this.escriturarNfPedido()),
          () => this.httpService.done());

    }
  }

  adicionarItem() {
    this.botaoEditar = false;
    this.pedidoItem = new PedidoItem();
    this.pedidoItem.item = new Item();
    this.pedidoItem.tipoDesconto = new TipoDesconto();
    this.pedidoItem.operacaoRegra = new OperacaoRegra();
    this.pedidoItem.operacaoRegra.operacaoRegraContabil = new OperacaoRegraContabil();
    this.pedidoItem.operacaoRegra.operacaoRegraFiscal = new OperacaoRegraFiscal();
    this.pedidoItem.operacaoRegra.operacaoRegraContabil.tipoRegraContabil = new TipoRegraContabil();

    this.pedidoItem.pedidoItensRateio = new Array();
    let itemRateio = new PedidoItemRateio();
    itemRateio.porcentagem = 0;
    itemRateio.cadCcus = new CadCcus();
    itemRateio.cadProj = new CadProj();
    this.pedidoItem.pedidoItensRateio.push(itemRateio);

    this.pedidoItem.itemFornecedor = null;
    this.pedidoItem.quantidade = null;
    this.pedidoItem.valorUnit = null;
    this.pedidoItem.valorDesconto = null;
    this.exibirItemPedido = true;
  }

  /**
 *  povoa as informações da operacaoregra selecionado
 * @param args
 */
  dadosOperacao(args) {


    this.httpService.wait();
    this.httpService.get('custom/cadastro/get-operacao-pedido', [!isNullOrUndefined(args.value) ? args.value : args])
      .subscribe(retorno => {

        this.retornaIdOperacaoRegra(retorno.id, retorno.tipoRegraContabil);

        this.pedidoItem.operacaoRegra = new OperacaoRegra();
        this.pedidoItem.operacaoRegra.id = retorno.id;
        this.pedidoItem.operacaoRegra.cod = retorno.codigo;
        this.pedidoItem.operacaoRegra.descricao = retorno.descricao;
        this.pedidoItem.operacaoRegra.permiteAlteracaoAlmoxarifado = retorno.permiteAlteracaoAlmoxarifado;
        this.pedidoItem.isServico = retorno.tipoRegraContabil == 2 ? true : false;

        this.pedido.dadosAdicionais = !isNullOrUndefined(this.pedido.dadosAdicionais) ? (this.pedido.dadosAdicionais.toString().replace((!isNullOrUndefined(this.pedido.dadosAdicionais) ? '' : retorno.dadosAdicionais), '')) : '';
        this.pedido.dadosAdicionais = isNullOrUndefined(this.pedido.dadosAdicionais) ? ('' + retorno.dadosAdicionais) : (this.pedido.dadosAdicionais + ' ' + (!isNullOrUndefined(this.pedido.dadosAdicionais) ? '' : retorno.dadosAdicionais));

        if (retorno.almoxarifadoOrigemId !== 0) {
          this.pedidoItem.operacaoRegra.almoxarifadoOrigem = new EmpresaAlmoxarifado();
          this.pedidoItem.operacaoRegra.almoxarifadoOrigem.id = retorno.almoxarifadoOrigemId;
          this.pedidoItem.empresaAlmoxarifado = new EmpresaAlmoxarifado();
          this.pedidoItem.empresaAlmoxarifado.id = retorno.almoxarifadoOrigemId;
        }

        if (retorno.almoxarifadoDestinoId) {
          this.pedidoItem.operacaoRegra.almoxarifadoDestino = new EmpresaAlmoxarifado();
          this.pedidoItem.operacaoRegra.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
          this.pedidoItem.almoxarifadoDestino = new EmpresaAlmoxarifado();
          this.pedidoItem.almoxarifadoDestino.id = retorno.almoxarifadoDestinoId;
        }

        this.pedidoItem.operacaoRegra.transfEstoque = retorno.transfEstoque;
        this.pedidoItem.operacaoRegra.controlaEstoque = retorno.controlaEstoque;


        retorno.operacaoRegraFiscalDetalheList.forEach(element => {
          if (!isNullOrUndefined(element.cstIpi.id)) {
            this.pedidoItem.cstIpi = new CstIpi();
            this.pedidoItem.cstIpi.id = element.cstIpi.id;
          }

          if (!isNullOrUndefined(element.cstPisCofins.id)) {

            this.pedidoItem.cstPis = new CstPiscofins();
            this.pedidoItem.cstPis.id = element.cstPisCofins.id;

            this.pedidoItem.cstCofins = new CstPiscofins();
            this.pedidoItem.cstCofins.id = element.cstPisCofins.id;
          }
        });





      }, error => this.httpService.handleError(error, () => this.dadosOperacao(args)),
        () => this.httpService.done());


  }

  retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil, skeletonLoad: boolean = false) {


    if (!isNullOrUndefined(operacaoRegraId)) {
      this.operacaoRegraId = operacaoRegraId;
    } else {
      this.operacaoRegraId = 0;
    }

    let isServico = tipoRegraContabil == 2 ? true : false;

    this.opcoesItens = [];
    if (this.pedido.pedidoTipo.id === 2 || this.pedido.pedidoTipo.id === 1) {
      if (skeletonLoad) this.skeletonConfigItens.startLoad()
      else this.httpService.wait()
      this.httpService.get('/custom/pedido/buscar-item-venda('
        + this.dblinkedSessionService.empresa.id + ',' + this.pedido.pedidoTipo.id + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          ref.forEach(element => {

            if ((!isServico) && element.itemTipo !== 10) {
              console.log(1);
              this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else if ((isServico) && element.itemTipo === 10) {
              this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
              console.log(2);
            }
          });
        },
          error => this.httpService.handleError(error, () => this.retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil)),
          () => {
            if (skeletonLoad) this.skeletonConfigItens.endLoad()
            else this.httpService.done()
          })
    } else {
      if (skeletonLoad) this.skeletonConfigItens.startLoad()
      else this.httpService.wait()
      this.httpService.get('/custom/pedido/buscar-item-compra('
        + this.dblinkedSessionService.empresa.id + ',' + this.pedido.pedidoTipo.id + ',' + operacaoRegraId + ')')
        .subscribe(ref => {
          ref.forEach(element => {
            if ((!isServico) && element.itemTipo !== 10) {
              this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            } else if ((isServico) && (element.itemTipo === 10 || element.itemTipo === 12)) {
              this.opcoesItens.push({ label: element.codigo + ' - ' + element.descricao, value: element.id });
            }
          });
        },
          error => this.httpService.handleError(error, () => this.retornaIdOperacaoRegra(operacaoRegraId, tipoRegraContabil)),
          () => {
            if (skeletonLoad) this.skeletonConfigItens.endLoad()
            else this.httpService.done()
          })

    }
  }

  /**
  *  povoa as informações do item selecionado
  * @param args
  */
  dadosItem(args) {

    this.httpService.get('/item?$select=id,codigo,descricao&$filter=id eq ' + args.value + '&$orderby=id')
      .subscribe(ref => {
        ref.value.forEach(element => {
          this.pedidoItem.item.codigo = element.codigo;
          this.pedidoItem.item.descricao = element.descricao;
        });
      },
        error => this.httpService.handleError(error, () => this.dadosItem(args)),
        () => this.httpService.done());
  }

  salvarPedido(salvarNovo: boolean) {
    let permite = true;


    this.pedido.pedidoItem.forEach(item => {

      let porcentagem = 0;
      item.pedidoItensRateio.forEach(rat => {

        porcentagem = this.utilService.parseDecimal(porcentagem) + this.utilService.parseDecimal(rat.porcentagem);
        if (rat.cadProj.id && !rat.cadCcus.id) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O Rateio do Item: ' + item.item.codigo + ' está com projeto sem centro de custo relacionado, verifique!'
          });
          permite = false;
          return;
        }

        if (rat.cadCcus.id && (porcentagem === 0 || isNullOrUndefined(porcentagem))) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O Rateio do Item: ' + item.item.codigo + ' está com centro de custo sem porcentagem informada!'
          });
          permite = false;
          return;
        }

        if (rat.cadCcus.projetosRelacionados && isNullOrUndefined(rat.cadProj.id)) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'O Rateio ' + (item.pedidoItensRateio.indexOf(rat) + 1) + ' do Item: ' + item.item.codigo + ' está sem projeto relacionado, verifique!'
          });
          permite = false;
          return;
        }

      });



      if ((porcentagem < 100.00 || porcentagem > 100.00) && porcentagem > 0.00) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'O Rateio do Item: ' + item.item.codigo + ' está com ' + porcentagem + '%, deve ser 100%, verifique!'
        });
        permite = false;
        return;
      }
    });

    if (permite) {
      // INFORMAÇÕES INICIAIS
      if (this.pedido.pedidoStatus.id === null || this.pedido.pedidoStatus.id === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione status do pedido para prosseguir!'
        });
        return;
      }

      if (this.pedido.modNf.id !== null || this.pedido.modNf.id !== undefined) {
        if (this.pedido.modNf.id === 24 &&
          (this.pedido.numNf === null || this.pedido.numNf === undefined || this.pedido.numNf.trim() === '')) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Informe o número do documento para prosseguir!'
          });
          return;
        }
      }

      if (isNullOrUndefined(this.pedido.modNf.id)) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Selecione o Modelo para Prosseguir!'
        });
        return;
      }
      if (this.pedido.dtEmissao === null || this.pedido.dtEmissao === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG: ',
          detail: 'Selecione a data da emissão para prosseguir!'
        });
        return;
      }
      if (this.pedido.dtFaturamentoAutomatico === null || this.pedido.dtFaturamentoAutomatico === undefined) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Selecione a data da Entrada para prosseguir!'
        });
        return;
      }
      if ((!this.pedido.moeda.id || this.pedido.moeda === undefined) && this.pedidoExterior) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Selecione a Moeda da Operação para Prosseguir!'
        });
        return;
      }

      if (isNullOrUndefined(this.pedido.pedidoItem) || this.pedido.pedidoItem.length === 0) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Para prosseguir, Insira um Item no Pedido!'
        });
        return;
      }
      // FIM


      // INFORMAÇÕES DE PAGAMENTO
      if ((isNullOrUndefined(this.pedido.tipoPagamento) || this.pedido.tipoPagamento === 0)) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Selecione o Tipo de Pagamento para Prosseguir!'
        });
        return;
      }

      if (this.pedido.tipoPagamento === 1) {
        if ((!this.pedido.formaPagamento.id) || this.pedido.formaPagamento.id === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG: ',
            detail: 'Selecione a forma de pagamento para prosseguir!'
          });
          return;
        }

        if (this.pedido.empresaConta.id === null || this.pedido.empresaConta.id === undefined) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG',
            detail: 'Selecione o tipo de Conta para Prosseguir!'
          });
          return;
        }
      }

      if (!(isNullOrUndefined(this.pedido.numeroInvoice) || this.pedido.numeroInvoice === '') && this.pedidoExterior) {
        if (!(/^[A-Za-z0-9]*\d+[A-Za-z0-9]*$/.test(this.pedido.numeroInvoice))) {
          this.messageService.add({
            severity: 'warn', key: 'messageLinnks',
            summary: 'MSG',
            detail: 'Número Invoice não pode ter caracteres especiais!'
          });
          return;
        }
      }

      if (this.utilService.parseDecimal(this.valorTotalPedido) <= 0.00) {
        this.messageService.add({
          severity: 'warn', key: 'messageLinnks',
          summary: 'MSG',
          detail: 'Valor Total do pedido não pode ser menor ou igual a Zero!'
        });
        return;
      }

     //VALIDAÇÃO DA DATA DE ENTRADA E EMISSÃO
        if ((this.pedido.dtFaturamentoAutomatico != null && this.pedido.dtEmissao != null) && (this.pedido.dtFaturamentoAutomatico < this.pedido.dtEmissao)){
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'A data de entrada não pode ser inferior à data de emissão.'})
          return
        }
    // FIM DA VALIDAÇÃO DE DATA DE ENTREGA E EMISSÃO


      const listasEnviar = {
        pedido: JSON.stringify(this.pedido, null, ''),
        empresa: this.dblinkedSessionService.empresa.id,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        info: this.adtosCompensar,
        escriturar: false,
        status: this.pedido.id === 0 ? 'novo' : 'edit',
        idItemFaturamento: this.idItemFaturamento,
        pedidoVinculado: this.pedidoVinculado
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/salvar-pedido', listasEnviar)
        .subscribe(result => {



          if (result.error) {
            this.messageService.add({
              severity: 'warn', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: result.message
            });
          } else {
            this.messageService.add({
              severity: 'success', key: 'messageLinnks',
              summary: 'MSG: ',
              detail: result.message
            });

            this.empresaChanged();
          }
        },
          error => this.httpService.handleError(error, () => this.salvarPedido(salvarNovo)),
          () => this.httpService.done());
    }
  }

  alterarPeriodo() {
    this.validacaoError = false;

    const mes = this.dblinkedSessionService.periodo.month.value > 8 ?
      this.dblinkedSessionService.periodo.month.value : '0' + this.dblinkedSessionService.periodo.month.value;
    const dt = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01 12:00:00';
    this.defaultDate = new Date(dt);

    let lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0);
    // validando data inicial e final ao abrir o relatorio e tiver um mes selecionado
    lastDay = new Date(this.defaultDate.getFullYear(), this.defaultDate.getMonth() + 1, 0); // ultimo dia do mês
    this.dataIniSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-01';
    this.dataFinSearch = this.dblinkedSessionService.periodo.year.value + '-' + mes + '-' + lastDay.getDate();

    if (this.dataIni !== null && this.dataFin !== null) {
      const mesIni = this.dataIni.getMonth() >= 9 ?
        (+this.dataIni.getMonth() + 1) : '0' + (+this.dataIni.getMonth() + 1);
      const mesFin = this.dataFin.getMonth() >= 9 ?
        (+this.dataFin.getMonth() + 1) : '0' + (+this.dataFin.getMonth() + 1);
      this.dataIniSearch = this.dataIni.getFullYear() + '-' + mesIni + '-' + ((this.dataIni.getDate() > 8) ?
        this.dataIni.getDate() : '0' + this.dataIni.getDate());
      this.dataFinSearch = this.dataFin.getFullYear() + '-' + mesFin + '-' + ((this.dataFin.getDate() > 9) ?
        this.dataFin.getDate() : '0' + this.dataFin.getDate());
    }
    const d1 = new Date(this.dataIniSearch);
    const d2 = new Date(this.dataFinSearch);
    if (d1 > d2) {
      this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'Aviso:', detail: 'Data incial maior que a final. Verifique!' });
      this.validacaoError = true;
      return;
    }
  }

  calculaValorParcelas() {
    this.valorTotalParcelas = 0.00;
    if (!isNullOrUndefined(this.pedido.pedidoParcela)) {
      this.pedido.pedidoParcela.forEach(el => {
        this.valorTotalParcelas += this.utilService.parseDecimal(el.valor);
      });
    }


  }

  formaPgtoSalvar() {
    this.somaParcelas = this.utilService.parseDecimal(0);
    this.pedido.pedidoParcela.forEach(element => {
      this.somaParcelas = this.somaParcelas + this.utilService.parseDecimal(element.valor);
    });
    this.somaParcelas = this.utilService.parseDecimal(this.somaParcelas.toFixed(2));
    this.valorTotalFormasPagtoTela = this.utilService.parseDecimal(this.valorTotalFormasPagtoTela);

    if (this.somaParcelas !== this.valorTotalFormasPagtoTela) {
      this.messageService.add({
        severity: 'warn', key: 'messageLinnks',
        summary: 'MSG: ',
        detail: 'Valor das parcelas é diferente do Valor Total do Pedido! <br><b> Parcelas: </b>'
          + this.utilService.parseDecimalString(this.somaParcelas) + ' <br><b> Pedido:</b> '
          + this.utilService.parseDecimalString(this.valorTotalFormasPagtoTela)
      });
    } else {
      this.modalFormaPgto = false;
      this.primeroVencimento = null;
      this.qtdParcelas = null;
    }
  }

  verificaModelo() {
    this.nf.modelo.startsWith('NFS') ? this.isModeloNFS = true : this.isModeloNFS = false;
  }

  excluirPedido() {
    if (confirm('Deseja realmente excluir este(s) Pedido(s)?')) {

      const listasEnviar = {
        pedidos: this.idPedidosServico,
        todos: false
      };

      this.httpService.wait();
      this.httpService.post('/custom/pedido/excluir-pedido-id', listasEnviar)
        .subscribe(result => {

          if (result[0].error === true) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: 'MSG: ', detail: result[0].message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'MSG: ', detail: result[0].message });
          }
          this.empresaChanged();
        },
          error => this.httpService.handleError(error, () => this.excluirPedido()),
          () => this.httpService.done());
    }
  }

  setTipoBaixa(event: any, dados: any) {
    this.pedidoParcela = new PedidoParcela();
    this.pedidoParcela.tipoBaixa = new TipoBaixa();
    this.pedidoParcela.id = dados.id;
    this.pedidoParcela.parcela = dados.parcela;
    this.pedidoParcela.dtVencimento = dados.dtVencimento;
    this.pedidoParcela.valor = this.utilService.parseDecimal(dados.valor);
    this.pedidoParcela.historico = dados.historico;
    this.pedidoParcela.tipoBaixa.id = dados.tipoBaixa.id;
    this.pedidoParcela.tipoBaixa.descricao = this.tiposBaixas.find(f => f.value === dados.tipoBaixa.id).label;

    const index = this.pedido.pedidoParcela.findIndex(i => i.id === this.pedidoParcela.id);
    this.pedido.pedidoParcela.splice(index, 1, this.pedidoParcela);
    this.pedido.pedidoParcela.forEach(el => {

      if (this.pedidoParcela.id === 1 && el.tipoBaixa.id === 0) {
        el.tipoBaixa = new TipoBaixa();
        el.tipoBaixa.id = this.pedidoParcela.tipoBaixa.id;
        el.tipoBaixa.descricao = this.pedidoParcela.tipoBaixa.descricao;
      }
    });

    this.pedidoParcela = new PedidoParcela();
    this.pedidoParcela.tipoBaixa = new TipoBaixa();
  }

  atualizarVencimentoParcela() {

  }

  excluirXml() {
    if (confirm('Deseja realmente excluir este xml?')) {
      const listasEnviar = {
        ids: this.idNfesBaixa,
        usuario: this.sessionService.loggedUser.id,
      };

      this.httpService.wait();
      this.httpService.post('/custom/notaFiscal/excluir-xml', listasEnviar)
        .subscribe(result => {



          if (result.error) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência', detail: result.message });
          } else {
            this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: 'Sucesso', detail: result.message });
            this.empresaChanged();
          }

        },
          error => this.httpService.handleError(error, () => this.escriturarCancelada()),
          () => this.httpService.done());
    }
  }

  modalCancelarNfeOpen() {
    console.log(this.nf);
    this.msgsModal = [];
    this.msgCancelamentoNfe = '';
    this.modalCancelarNfe = true;
  }

  modalCartaCorrecaoNfe() {
    this.msgsModal = [];
    this.msgCartaCorrecaoNfe = '';
    this.modalCartaCorrecao = true;
  }


  cancelarNfe() {
    if ((this.msgCancelamentoNfe === undefined || this.msgCancelamentoNfe.length < 5)) {
      this.msgsModal.push({
        severity: 'warn', summary: 'Advertência:',
        detail: 'O motivo de cancelamento deverá ser informado.'
      });
      return;
    }
    if (confirm('Deseja realmente cancelar esta Nota?')) {
      this.modalCancelarNfe = false;

      this.httpService.wait();

      this.httpService.get('/custom/nfe/cancelar-nfe', [this.nf.nfeId, this.msgCancelamentoNfe.replace(",", ".")])
        .subscribe(result => {
          this.msgs = [];
          if (result.error === 'true') {
            this.msgs = [];
            this.msgs.push({ severity: 'error', summary: 'Impossibilidade de Cancelamento: ', detail: result.message });
          } else {
            this.msgs.push({ severity: 'success', summary: 'Cancelamento registrado: ', detail: result.message });
            this.empresaChanged();
          }
        },
          error => this.httpService.handleError(error, () => this.cancelarNfe()),
          () => this.httpService.done());
    }
  }

  enviarCartaCorrecao() {
    if ((this.msgCartaCorrecaoNfe === undefined || this.msgCartaCorrecaoNfe.length < 5)) {
      this.msgsModal.push({
        severity: 'warn', summary: 'Advertência:',
        detail: 'O motivo da carta de correção deverá ser informado.'
      });
      return;
    }
    if (confirm('Deseja realmente enviar esta Carta de Correção?')) {
      this.modalCancelarNfe = false;

      this.httpService.wait();
      this.httpService.get('/custom/nfe/enviar-carta-correcao-nfe', [this.nf.nfeId, this.msgCartaCorrecaoNfe])
        .subscribe(result => {
          this.msgs = [];
          if (result.error) {
            this.msgs = [];
            this.msgs.push({ severity: 'error', summary: 'MSG: ', detail: result.message });
          } else {
            this.msgs.push({ severity: 'success', summary: 'MSG: ', detail: result.message });
            this.empresaChanged();
          }
        },
          error => this.httpService.handleError(error, () => this.enviarCartaCorrecao()),
          () => this.httpService.done());
    }
  }

  onTableRowSelect(event) {
    this.selectCheckbox(event.data.nfeId, { ...event.data, selected: true })
  }
  onTableRowUnselect(event) {
    this.selectCheckbox(event.data.nfeId, { ...event.data, selected: false })
  }
  onTableselectAll(event) {
    this.selectTodos(event.checked)
  }


  loadAcoes() {

    const childActions: Array<ToolbarButton> = new Array();

    childActions.push({
      icon: '',
      label: 'Reprocessar Contabilidade NF',
      callback: () => this.reprocessarNf('contabilidade')
    });

    childActions.push({
      icon: '',
      label: 'Reprocessar Estoque NF',
      callback: () => this.reprocessarEstoque()
    });

    childActions.push({
      icon: '',
      label: 'Reprocessar Escrituração NF',
      callback: () => this.reprocessarNf('escrituracao')
    });

    childActions.push({
      icon: '',
      label: 'Reprocessar Financeiro NF',
      callback: () => this.reprocessarNf('financeiro')
    });

    childActions.push({
      icon: '',
      label: 'Buscar XML',
      callback: () => this.exibirModalXml()
    });


    this.toolbarService.get('more_vert').child = childActions;
  }

  reprocessarNf(param: String) {


    if (confirm('Deseja realmente reprocessar o ' + param + ' destas Notas?')) {


      const listasEnviar = {
        empresaId: this.dblinkedSessionService.empresa.id,
        list1: this.idNfesBaixa,
        list2: this.idNotasEscrituradas,
        usuario: this.sessionService.loggedUser.id,
        mes: this.dblinkedSessionService.periodo.month.value,
        ano: this.dblinkedSessionService.periodo.year.value,
        param: param,
        tipoEntradaSaidaId: 1
      };


      this.httpService.wait();
      this.httpService.post('/custom/nfe/reprocessar-nf', listasEnviar)
        .subscribe(res => {

          this.msgs = [];

          res.forEach(element => {
            if (element.error) {
              this.messageService.add({
                severity: 'warn', key: 'messageLinnks',
                detail: element.message
              });
            } else {
              this.messageService.add({
                severity: 'success', key: 'messageLinnks',
                detail: element.message
              });
            }

          });

        },
          error => this.httpService.handleError(error),
          () => this.httpService.done());

    }

  }

  exportExcel(table: Array<any>, title: string, cols: Array<any>) {
    exportExcel(table, title, cols)
  }

  changeToolbar() {
    this.toolbarMain.setVisible('vincular', this.xmlSelecionados.length === 1)
    this.toolbarMain.setVisible('criar', this.xmlSelecionados.length > 0)
    this.toolbarMain.setVisible('detalhes', this.xmlSelecionados.length === 1)
  }

  vincularItensXml() {
    if (this.xmlSelecionados[0].modelo.split(" - ")[0] != '55') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Somente é possível vincular notas modelo 55.' })
      return
    }

    //Verificar se já noi foi vinculado nesta sessão
    let vinculado = false
    if (this.relacaoItemOperNfe.hasOwnProperty(this.xmlSelecionados[0].nfeId.toString())) {
      vinculado = true
      this.itensNfsParticipantes = deepCopy(this.relacaoItemOperNfe[this.xmlSelecionados[0].nfeId.toString()])
    }

    this.vincularItens = true

    if (!vinculado) {
      this.skeletonConfigItens.startLoad()
      this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/itens-xml?nfeId=' + this.xmlSelecionados[0].nfeId, this.httpOptions)
        .subscribe(res => {
          this.itensNfsParticipantes = new Array()
          res["data"].forEach(row => this.itensNfsParticipantes.push(row))
          this.skeletonConfigItens.endLoad()
        },
          error => {
            this.itensNfsParticipantes = new Array()
            if (error.error.message) this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: error.error.message })
            else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a relação dos itens, contate o suporte: ' + error.message })
            this.skeletonConfigItens.endLoad()
          }
        )
    }
  }

  exportarListagem() {
    let table = new Array()
    let title = null
    let colunas = new Array()
    if (this.statusPendente) {
      table = this.xmlPendente
      title = 'Notas pendentes'
      colunas = this.colPendentes

    } else if (this.statusEscriturada) {
      table = this.xmlEscriturado
      title = 'Notas escrituradas'
      colunas = this.colEscrituradas

    } else if (this.statusCancelada) {
      table = this.xmlCancelado
      title = 'Notas canceladas'
      colunas = this.colCanceladas
    }

    exportExcel(table, title, colunas)
  }

  checkVinculacaoXml() {
    this.itensNfsParticipantes.forEach(row => {
      if (!row.item_id || !row.operacao_regra_id || !row.fator) row.relacionado = false
      else if (row.item_id && row.operacao_regra_id && row.fator) row.relacionado = true
    })
  }

  replicarItensXml(row: any) {
    this.opcoesItensXml.forEach(element => {
      if (element.value === row.item_id) {
        row.item_relac = element.label
        return
      }
    })

    if (this.itensXmlSelecionados.length > 1) {
      if (confirm("Deseja replicar esse item para a seleção?")) {
        //Trocar o id para todos os mesmos itens da nota
        this.itensXmlSelecionados.forEach(element => {
          if (element.item_id_f === row.item_id_f) {
            element.item_id = row.item_id
            element.item_relac = row.item_relac
            if (row.operacao_regra_id) {
              element.operacao_regra_id = row.operacao_regra_id
              element.operacao = row.operacao
            }
          }
        })

        this.itensXmlSelecionados.forEach(element => {
          element.item_id = row.item_id
          element.item_relac = row.item_relac
          if (row.operacao_regra_id) {
            element.operacao_regra_id = row.operacao_regra_id
            element.operacao = row.operacao
          }
        })
      }
    }

    this.checkVinculacaoXml()
  }

  replicarOperacaoXml(row: any) {
    this.opcoesOperacaoXml.forEach(element => {
      if (element.value === row.operacao_regra_id) {
        row.operacao = element.label
        return
      }
    })

    //Trocar a operacao para todos os mesmos itens da nota
    this.itensNfsParticipantes.forEach(element => {
      if (element.item_id === row.item_id) {
        element.operacao_regra_id = row.operacao_regra_id
        element.operacao = row.operacao
      }
    })

    if (this.itensXmlSelecionados.length > 1) {
      if (confirm("Deseja replicar essa operação para a seleção?")) {
        this.itensXmlSelecionados.forEach(element => {
          element.operacao_regra_id = row.operacao_regra_id
          element.operacao = row.operacao
        })
      }
    }

    this.checkVinculacaoXml()
  }

  getOperacoes(skeletonLoad: boolean = false) {
    if (skeletonLoad) this.skeletonConfigItens.startLoad()
    else this.httpService.wait()
    this.httpService.get('/operacaoregra?$select=id,cod,descricao,operacaoRegraContabil/id,operacaoRegraFiscal/id'
      + '&$filter=tipoEntradaSaida/id eq 1 and empresa/id eq '
      + (this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId))
      .subscribe(ref => {
        this.opcoesOperacaoXml = []
        ref.value.forEach(element => this.opcoesOperacaoXml.push({ label: element.cod + ' - ' + element.descricao, value: element.id }))
      },
        error => this.httpService.handleError(error, () => this.getOperacoes()),
        () => {
          if (skeletonLoad) this.skeletonConfigItens.endLoad()
          else this.httpService.wait()
        })
  }

  salvarVinculacaoXml() {
    let vinculados = true
    this.itensNfsParticipantes.forEach(row => {
      if (!row.item_id || !row.operacao_regra_id || !row.fator) vinculados = false
    })

    if (!vinculados) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Todos os itens devem ser vinculados para salvar.' })
      return
    }

    const infos = { lista: this.itensNfsParticipantes }

    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/itens-xml?nfeId=' + this.xmlSelecionados[0].nfeId, infos, this.httpOptions)
      .subscribe(res => {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res["message"] })
        this.httpService.done()
        this.vincularItens = false
        this.relacaoItemOperNfe[this.xmlSelecionados[0].nfeId] = deepCopy(this.itensNfsParticipantes)
        this.empresaChanged()
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a relação dos itens, contate o suporte: ' + error.message })
          this.httpService.done()
        }
      )
  }

  buscarMelhorOperacao(row: any) {
    if (this.itensXmlSelecionados.length === 0) {
      //Trocar o id para todos os mesmos itens da nota
      this.itensNfsParticipantes.forEach(element => {
        if (element.item_id_f === row.item_id_f) {
          element.item_id = row.item_id
          element.item_relac = row.item_relac
          if (row.operacao_regra_id) {
            element.operacao_regra_id = row.operacao_regra_id
            element.operacao = row.operacao
          }
        }
      })
    }

    let cnpj = this.xmlSelecionados[0].cnpj.replaceAll(" ", "").replaceAll("-", "").replaceAll("/", "").replaceAll(".", "")
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/best-operacao-item?itemId=' + row.item_id + "&cnpj=" + cnpj, this.httpOptions)
      .subscribe(res => {
        try {
          row.operacao_regra_id = res["data"]["item_part"][cnpj][row.item_id.toString()]["operacao_id"]
          row.operacao = res["data"]["dicoper"][row.operacao_regra_id.toString()]
        } catch {
          row.operacao_regra_id = null
          row.operacao = null
        }
        this.replicarItensXml(row)
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a operação, contate o suporte: ' + error.message })
        }
      )
  }

  listarItensParaXml() {
    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.matrizId === 0 ? this.dblinkedSessionService.empresa.id : this.dblinkedSessionService.empresa.matrizId,
      tipo_item: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11]
    }

    this.httpClient.post(this.configService.defaultUrlApiUpload + 'cadastro/itens-filtrados', infos, this.httpOptions)
      .subscribe(res => {
        this.opcoesItensXml = []
        res["data"].forEach(row => this.opcoesItensXml.push({ label: row.codigo + " - " + row.descricao, value: row.id }))
      },
        error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar os itens para os XML, contate o suporte: ' + error.message })
      )
  }

  verifCriacaoPedidoLote() {
    if (this.xmlSelecionados[0].modelo.split(" - ")[0] != '55') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Somente é possível vincular notas modelo 55.' })
      return
    }

    this.informacoesPagamento.formaPagamentoId = this.xmlSelecionados[0].informacoesAdicionais.forma_pagamento_id
    this.informacoesPagamento.empresaContaId = this.xmlSelecionados[0].informacoesAdicionais.empresa_conta_id
    this.informacoesPagamento.dtEntrada = this.xmlSelecionados[0].informacoesAdicionais.dtEntrada ? this.xmlSelecionados[0].informacoesAdicionais.dtEntrada : new Date()
    this.aplicarRateioTodosItens = this.xmlSelecionados[0].informacoesAdicionais.aplicarRateioTodosItens
    this.informacoesRateio = this.xmlSelecionados[0].informacoesAdicionais.rateioPadrao ? this.xmlSelecionados[0].informacoesAdicionais.rateioPadrao : new Array()
    this.modalPagRateio = true
    this.buscarMelhorFormaPagRateio()

  }

  criarPedidoLote() {
    //Fazer as verificações para cada XML
    let podeCriar = true
    this.xmlSelecionados.forEach(xml => {
      if (!xml.pronto || !xml.vinculado) {
        podeCriar = false
      }
    })

    if (!podeCriar) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Todos os XML selecionados devem possuir vinculação completa e estar com forma de pagamento e rateio para todos itens.' })
      return
    }

    //Criar pedido e escriturar
    this.loadingProgress = 0
    this.isLoadingInProgress = true
    this.tokenProgressoSocket = idv4()
    this.modalPagRateio = false
    const infos = {
      empresa_id: this.dblinkedSessionService.empresa.id,
      xml: this.xmlSelecionados,
      itens: this.relacaoItemOperNfe,
      user_id: this.sessionService.loggedUser.id,
      token: this.tokenProgressoSocket,
      rateioObrigatorio: this.rateioObrigatorio
    }

    this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/criacao-pedido-xml', infos, this.httpOptions)
      .subscribe(res => {
        this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: res["message"] })
        this.empresaChanged()
      },
        error => {
          if (error.error.data) {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: error.error.message })
            const httpOptions2 = {
              headers: new HttpHeaders({
                'Authorization': this.configService.bearerApi,
                'Cache-Control': 'no-store, max-age=0',
                'userId': this.sessionService.loggedUser.id.toString()
              }),
              responseType: 'blob' as 'json'
            };

            this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/criacao-pedido-xml', httpOptions2)
              .subscribe(
                (res: any) => {
                  let link = document.createElement('a')
                  let url = window.URL.createObjectURL(res)
                  let fileName = 'Log pedido.txt'
                  link.href = url
                  link.download = fileName
                  link.click()
                  window.URL.revokeObjectURL(url)
                  this.empresaChanged()
                },
                err => {
                  this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao baixar o log, contate o suporte: ' + err.message })
                },
                async () => {
                  await new Promise(f => setTimeout(f, 1500))
                  this.isLoadingInProgress = false
                }
              )
          }
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao escriturar as notas, contate o suporte: ' + error.message })
        },
        async () => {
          await new Promise(f => setTimeout(f, 1500))
          this.isLoadingInProgress = false
        }
      )

  }

  buscarContaFormaPag() {
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'cadastro/buscar-conta-formapag?formaId=' + this.informacoesPagamento.formaPagamentoId, this.httpOptions)
      .subscribe(res => {
        try { this.informacoesPagamento.empresaContaId = res["data"]["id"] }
        catch { this.informacoesPagamento.empresaContaId = null }
      },
        error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a conta bancária dessa forma de pagamento, contate o suporte: ' + error.message })
      )
  }

  getCompanyParameter() {
    this.httpService.get('/custom/pedido/centro-de-custo-obrigatorio(' + this.dblinkedSessionService.empresa.id + ')')
      .subscribe(element => this.rateioObrigatorio = element.cadCcusObrigatorio,
        error => this.httpService.handleError(error, () => this.getCompanyParameter()));
  }

  adicionarRateioXml() {
    let rateio = {
      cadCcus: new CadCcus(),
      cadProj: new CadProj(),
      porcentagem: 0,
      id: idv4()
    }

    this.informacoesRateio.push(rateio)
  }

  removerRateioXml(row: any) {
    const index = this.informacoesRateio.findIndex(element => element.id === row.id)
    this.informacoesRateio.splice(index, 1)
  }

  buscarProjetoRelacionado(row: any) {
    row.cadProj = new CadProj()
    this.opcoesProjeto = []
    this.pedidoService.carregarProjetos(this.opcoesProjeto, row.cadCcus.id)
  }

  loadingManagement(data: any) {
    if (data.content.tokenProgressoSocket == this.tokenProgressoSocket) this.loadingProgress = Math.round(data.content.progress)
  }

  buscarMelhorFormaPagRateio() {
    let cnpj = this.xmlSelecionados[0].cnpj.replaceAll(" ", "").replaceAll(".", "").replaceAll("-", "").replaceAll("/", "")
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/buscar-melhor-forma-pag-rateio?empresaId=' + this.dblinkedSessionService.empresa.id + '&cnpj=' + cnpj, this.httpOptions)
      .subscribe(res => {
        //Forma pagamento
        try {
          this.informacoesPagamento.formaPagamentoId = res["data"]["formapag"]
          if (this.informacoesPagamento.formaPagamentoId) this.buscarContaFormaPag()

        } catch {
          this.informacoesPagamento.formaPagamentoId = null
          this.informacoesPagamento.empresaContaId = null
        }

        //Rateio
        try {
          let rateio = {
            cadCcus: new CadCcus(),
            cadProj: new CadProj(),
            porcentagem: 0,
            id: idv4()
          }

          rateio.cadCcus = res["data"]["rateio"]["cadCcus"]
          if (res["data"]["rateio"]["cadProj"]["id"]) rateio.cadProj = res["data"]["rateio"]["cadProj"]
          rateio.porcentagem = 100

          if (this.informacoesRateio.length === 0) this.informacoesRateio.push(rateio)

        } catch {
          //Deixar como está em caso de problema
        }
      },
        error => this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a forma de pagamento e rateio, contate o suporte: ' + error.message })
      )
  }

  verificarXmlPronto() {
    const infos = {
      liXml: this.xmlPendente,
      usaRateio: this.rateioObrigatorio,
      empresaId: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    this.httpClient.post(this.configService.defaultUrlApiUpload + 'pedido/verif-xml-pronto', infos, this.httpOptions)
      .subscribe(res => {
        this.xmlPendente.forEach(row => {
          if (!row.pronto) {
            row.pronto = res["data"][row.nfeId] ? res["data"][row.nfeId]["pronto"] : false
            row.informacoesAdicionais = res["data"][row.nfeId] ? res["data"][row.nfeId] : {}
          }
        })
        this.httpService.done()
      },
        error => {
          this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao verificar se as formas de pagamento dos XML, contate o suporte: ' + error.message })
          this.httpService.done()
        }
      )
  }

  salvarInfoPedido() {
    //Verificar info pag
    if (!this.informacoesPagamento.formaPagamentoId) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'A forma de pagamento é obrigatória.' })
      return
    }

    if (!this.informacoesPagamento.empresaContaId) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'A conta bancária é obrigatória.' })
      return
    }

    if (!this.informacoesPagamento.dtEntrada) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'A data de entrada é obrigatória.' })
      return
    }

    //Verificar o rateio
    if (this.rateioObrigatorio && this.informacoesRateio.length <= 0) {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'É obrigatório informar um rateio.' })
      return
    }

    if (this.informacoesRateio.length > 0) {
      let totalRateio = 0
      let projRelacionados = true
      this.informacoesRateio.forEach(row => {
        totalRateio += row.porcentagem
        if (row.cadCcus.projetosRelacionados && !row.cadProj.id) projRelacionados = false
      })

      if (!projRelacionados) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Existe um centro de custo sem projeto, verificar.' })
        return
      }

      if (Math.round(totalRateio * 100) / 100 != 100) {
        this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'O rateio deve dar um total de 100%, deu ' + (Math.round(totalRateio * 100) / 100) + '%.' })
        return
      }
    }

    //Colocar as formas de pagamento
    this.xmlSelecionados[0].pronto = true
    this.xmlSelecionados[0].informacoesAdicionais.pronto = true
    this.xmlSelecionados[0].informacoesAdicionais.forma_pagamento_id = this.informacoesPagamento.formaPagamentoId
    this.xmlSelecionados[0].informacoesAdicionais.empresa_conta_id = this.informacoesPagamento.empresaContaId
    this.xmlSelecionados[0].informacoesAdicionais.dtEntrada = this.informacoesPagamento.dtEntrada

    //Aplicar o rateio para os itens
    if (this.aplicarRateioTodosItens) this.xmlSelecionados[0].informacoesAdicionais.aplicarRateioTodosItens = true
    this.xmlSelecionados[0].informacoesAdicionais.rateioPadrao = this.informacoesRateio

    this.modalPagRateio = false

    //Substituir as informações para não perder
    this.informacoesAdicionaisXml[this.xmlSelecionados[0].nfeId.toString()] = deepCopy(this.xmlSelecionados[0].informacoesAdicionais)
  }

  pesquisarNfe() {



    if (this.buscaNfe === '') {
      this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: 'Por Favor, digite um termo para pesquisa' })
      return
    }


    const listasEnviar = {
      buscaNfe: this.buscaNfe,
      termoPesquisa: this.termoPesquisa,
      empresaId: this.dblinkedSessionService.empresa.id,
      filiais: this.dblinkedSessionService.filiaisSelecionadas
    };


    this.httpService.wait();
    this.httpService.post('/custom/dbl/pesquisar-nfe', listasEnviar)
      .subscribe(ref => { this.buscaNfe = ''; this.termoPesquisa = 'chave'; this.loadXml(ref) },
        error => this.httpService.handleError(error, () => this.pesquisarNfe()),
        () => this.httpService.done());

  }


  pagamentoTipo() {
    if (this.pedido.tipoPagamento === 2) {
      this.adicionarCompensacao();
      this.pedido.pedidoParcela = new Array();
      this.pedidoParcela = new PedidoParcela();
    } else {
      this.adtosRelacionadosPedido = new Array();
    }
  }

  infosFormaPagto(id: number) {

    if (!id) {
      this.pedido.empresaConta = new EmpresaConta()
      this.pedido.pedidoParcela = new Array()
      return
    }

    this.permiteAlterarConta = true;

    this.httpService.wait();
    this.httpService.get('/formapagamento?$select=id,descricao,empresaConta/id,empresaConta/nome,permiteAlterarBanco'
      + '&$filter=id eq ' + id)
      .subscribe(ref => {

        let forma = new FormaPagamento();
        forma = ref.value[0];

        if (!isNullOrUndefined(forma.empresaConta)) {
          this.pedido.empresaConta = new EmpresaConta();
          this.pedido.empresaConta.id = forma.empresaConta.id;
          this.pedido.empresaConta.nome = forma.empresaConta.nome;
          this.permiteAlterarConta = forma.permiteAlterarBanco;
          this.pagamentoForma(true);
        }


      },
        error => this.httpService.handleError(error, () => this.infosFormaPagto(id)),
        () => this.httpService.done());
  }

  pagamentoForma(atualizar = false) {
    if (!this.visualizarEscrituracao) {
      if ((atualizar)) {
        // formas pagamentos
        if (this.pedido.formaPagamento.id === null || this.pedido.empresaConta.id === null) {
          return;
        }

        if (this.pedido.formaPagamento.id === null) {
          this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Advertência: ', detail: 'Selecione uma forma de pagamento para prosseguir!' })
          return;
        }

        this.valorTotalFormasPagto = 0;
        this.valorTotalFormasPagtoTela = 0;

        this.adtosRelacionadosPedido = new Array();

        this.pedido.valorInss = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorInss));
        this.pedido.valorIr = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIr));
        this.pedido.valorCsll = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCsll));
        this.pedido.valorPis = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorPis));
        this.pedido.valorCofins = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorCofins));
        this.pedido.valorIssRetido = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIssRetido));
        this.pedido.valorOutrasRetencoes = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorOutrasRetencoes));
        this.pedido.valorIcms = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIcms));
        this.pedido.valorIpi = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorIpi));
        this.pedido.valorImpostoImportacao = this.utilService.parseDecimalString(this.utilService.parseDecimal(this.pedido.valorImpostoImportacao));

        const isPedidoCompraOuVendaProduto: boolean = true;


        this.pedido.dtEmissao = new Date(this.pedido.dtEmissao);
        console.log(this.pedido.dtEmissao);


        const listasEnviar = {
          pedido: this.pedido,
          empresa: this.dblinkedSessionService.empresa.id,
          usuario: this.sessionService.loggedUser.id,
          valorTotal: this.vlTotal,
          moeda: this.pedido.moeda.id,
          txMoeda: this.pedido.txMoeda,
          isPedidoExterior: this.pedidoExterior,
          isPedidoCompraOuVendaProduto: isPedidoCompraOuVendaProduto,
          converterTaxa: this.pedido.converterTaxa,
          valorComissao: this.valorComissao,
          pagamentoComissao: this.pagamentoComissao,
          escritXmlParc: this.vlTotal < this.pedido.valorTotal

        };

        this.httpService.wait();
        this.httpService.post('/custom/financeiro/buscar-parcela', listasEnviar)
          .subscribe(rst => {
            if (rst["error"]) {
              this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: rst.message })
              this.httpService.done()
            } else {

              this.pedido.pedidoParcela = new Array();
              this.valorTotalParcelas = 0.00;
              this.valorTotalFormasPagtoTela = 0.00;
              this.maxParcelas = rst.maxParcelas;
              this.parcelasExatas = rst.parcelasExatas
              this.permiteAlterarConta = rst.permiteAlterarBanco
              if (!isNullOrUndefined(rst.parcelas)) {
                rst.parcelas.forEach(el => {
                  if (!el.error) {
                    // this.pedidoParcela = el;
                    this.pedidoParcela = new PedidoParcela()
                    if (rst.boolDataEmissaoPedido) {
                      this.pedidoParcela.dtVencimento = this.addDias(this.pedido.dtEmissao, el.acrescentaMes, el.dias);

                    } else {
                      const d = new Date(el.dtVencimento);
                      this.pedidoParcela.dtVencimento = this.utilService.dateWithoutTimeZone(d.toDateString());
                    }

                    this.pedidoParcela.valor = el.valor
                    this.pedidoParcela.parcela = el.parcela
                    this.pedidoParcela.id = el.id

                    // total das parcelas
                    this.valorTotalParcelas += this.utilService.parseDecimal(el.valor);
                    this.valorTotalFormasPagtoTela += this.utilService.parseDecimal(el.valor);

                    this.pedidoParcela.tipoBaixa = new TipoBaixa()
                    if (el.tipoBaixa) {
                      this.pedidoParcela.tipoBaixa.id = el.tipoBaixa.id
                      this.pedidoParcela.tipoBaixa.descricao = el.tipoBaixa.descricao
                    }

                    this.pedidoParcela.tipoPagamentoId = el.tipoPagamentoId
                    this.pedidoParcela.tipoPagamentoDesc = el.tipoPagamentoDesc

                    this.pedidoParcela.empresaConta = new EmpresaConta()
                    this.pedidoParcela.empresaConta.id = el.empresaConta.id
                    this.pedidoParcela.empresaConta.nome = el.empresaConta.nome
                    this.pedido.pedidoParcela.push(this.pedidoParcela);
                    this.includeCalendarState()
                  } else {
                    this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: 'Erro: ', detail: el.message })
                    return;
                  }
                });
              }
            }
          },
            error => this.httpService.handleError(error, () => this.pagamentoForma(atualizar)),
            () => this.httpService.done());

      }
    }




  }

  includeCalendarState(): void {
    this.pedido.pedidoParcela.forEach(item => {
      item.calendarState = false
    })
  }

  addDias(d, mes, dias) {
    var date;
    if ((typeof d === 'string') && (d.includes('/'))) {
      const str = d.split('/');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const day = Number(str[0]);

      date = new Date(year, month, day);
    } else {
      date = new Date(d);
    }

    // retorna 0 para janeiro e 11 para dezembro
    var n_date = new Date(date.getFullYear(), date.getMonth(), eval(dias + date.getDate()));
    return n_date
  }

  adicionarCompensacao() {
  }

  calcularQtdeFinal(rowData: any){
    rowData.qtde_final = null
    if (rowData.fator) rowData.qtde_final = rowData.fator * rowData.qtde
    this.checkVinculacaoXml()
  }

  buscarInfoUnidadeMedida(rowData: any) {
    this.httpClient.get(this.configService.defaultUrlApiUpload + 'pedido/buscar-info-unidade-medida?itemId=' + rowData.item_id + "&unidadeNf=" + rowData.unidade_nf, this.httpOptions)
        .subscribe(res => {
          rowData.unidade_cod_item = res["data"]["unidade_cod"]
          rowData.fator = res["data"]["fator"]
          this.calcularQtdeFinal(rowData)
        },
          error => {
            this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: 'Erro no API ao recuperar a operação, contate o suporte: ' + error.message })
          }
        )
  }

  reprocessarEstoque() {
    const infos = {
      liNfeId: this.idNfesBaixa,
      userId: this.sessionService.loggedUser.id,
      empresaId: this.dblinkedSessionService.empresa.id
    }

    this.httpService.wait()
    let url = '/custom/estoque/v2/reprocessar-estoque'
    this.httpService.post(url, infos)
      .subscribe(
        result => {
          this.messageService.add({ severity: 'success', key: 'messageLinnks', summary: '', detail: result.message })
          this.httpService.done()
          //Gravando no log
          this.idNotasEscrituradas.forEach(nfeId => {
            let xml = this.xmlEscriturado.find(row => row.nfeId === nfeId)

            this.logManagement.registerLog(
              LogModule.PURCHASES,
              LogScreen.INVOICES,
              url,
              LogAction.EDIT,
              nfeId,
              xml.numNf,
              "Reprocessou o estoque da NF " + xml.numNf,
              infos
            )
          })

          this.empresaChanged()

        },
        (error: HttpErrorResponse) => {
          if (error.status === 400) {
            this.messageService.add({ severity: 'warn', key: 'messageLinnks', summary: '', detail: error.error.message })
            this.download(error.error.file)
          }
          else this.messageService.add({ severity: 'error', key: 'messageLinnks', summary: '', detail: "Erro no API ao reprocessar o estoque, contate o suporte: " + error.message })
          this.httpService.done()
          //Gravando no log
          this.idNotasEscrituradas.forEach(nfeId => {
            let xml = this.xmlEscriturado.find(row => row.nfeId === nfeId)

            this.logManagement.registerLog(
              LogModule.PURCHASES,
              LogScreen.INVOICES,
              url,
              LogAction.EDIT,
              nfeId,
              xml.numNf,
              "Reprocessou o estoque da NF " + xml.numNf,
              infos
            )
          })

          this.empresaChanged()
        })
  }

  download(file: string) {
      window.open(this.httpService.url("/custom/file/" + file),"_blank")
  }

  exibirModalXml(){
    this.modalXml = true;
    this.chavesBuscar = '';
  }

  buscarXmlsRfb(){
    let payload = {
      empresaId: this.dblinkedSessionService.empresa.id,
      chavesBuscar: this.chavesBuscar,
      usuario: this.sessionService.loggedUser.id

    }
    this.httpService.wait()
    this.httpService.post('/custom/nfe/pesquisar-nfe', payload)
      .subscribe(ref => this.retornoBusca(ref),
        error => this.httpService.handleError(error, () => this.buscarXmlsRfb()),
        () => this.httpService.done())
  }

  retornoBusca(value: any) {

    this.messageService.add({
      severity: 'success', key: 'messageLinnks', summary: 'Sucesso:', detail: value.message
    });

    this.modalXml = false;
    this.chavesBuscar = '';
  }

}
